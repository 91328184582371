import * as React from "react";
import { UserContext } from "../ui/UserContext";

export interface IFile {
  fileName: string;
  fileType: string;
  publicOrPrivate: string;
}

interface IFileUpload {
  id: string;
  publicOrPrivate: string;
  fileFolder: string;
  includeHeader?: boolean;
  header?: string;
  fileSelectorText?: string;
  filesToAccept?: string;
  disabled?: boolean;
  fileName?: string;
  updateFile: (file: File | undefined) => void;
}

function FileUpload(props: IFileUpload) {
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
  const { dispatch } = React.useContext(UserContext);

  return (
    <div className="files">
      {props.includeHeader && (
        <h3 className="filesheader">{props.header ? props.header : "Files"}</h3>
      )}
      {!props.disabled && (
        <div className="form-field-block">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                htmlFor={props.id}
                style={{
                  fontSize: "0.85rem",
                  padding: "10px",
                  border: "2px dotted",
                  borderRadius: "2px",
                }}
              >
                <p
                  style={{
                    fontSize: "0.85rem",
                  }}
                >
                  {props.fileSelectorText
                    ? props.fileSelectorText
                    : "Click Here to Select File"}
                </p>
              </label>
              <input
                id={props.id}
                type="file"
                name="file"
                accept={
                  props.filesToAccept
                    ? props.filesToAccept
                    : "image/x-png,image/gif,image/jpeg,image/pdf,image/doc,image/docx,image/xls,image/xlsx"
                }
                onChange={(event) => {
                  if (
                    event &&
                    event.target &&
                    event.target.files &&
                    event.target.files.length > 0
                  ) {
                    let file = event.target.files[0];

                    if (file.size <= 5000000) {
                      setSelectedFile(event.target.files[0]);
                      props.updateFile(event.target.files[0]);
                    } else {
                      dispatch({
                        type: "SET-ERROR",
                        value: "File Size Cannot be More than 5 MB",
                      });
                    }
                  }
                }}
              />
              {props.fileName ? (
                <p
                  style={{
                    fontStyle: "italic",
                    margin: "0",
                    marginLeft: "5px",
                  }}
                >
                  {props.fileName}
                </p>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileUpload;
