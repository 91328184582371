import * as React from "react";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";

import { getStudy } from "../api";
import { formatDate, escapeRegExp } from "../utilities";
import { UserContext } from "../ui/UserContext";
import { getSignedUrl, postAction } from "../api";
import { hasEntityRole } from "../utilities";

import reportsStyles from "./Reports.module.css";

interface IReport {
  id: string;
  patName: string;
  entityName: string;
  entityId: string;
  pk: string;
  type: string;
  assignedToEntityName: string;
  assignedToUserName: string;
  reportedDate: Date;
  details: any;
}

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function getIsMatch(filter: string) {
  const isMatch = (report: IReport) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return (
      re.test(report.patName) ||
      re.test(report.entityName) ||
      re.test(report.assignedToEntityName) ||
      re.test(report.assignedToUserName) ||
      re.test(format(new Date(report.reportedDate), "eee, yyyy-MM-dd HH:mm"))
    );
  };
  return isMatch;
}

function Reports() {
  let [fromDate, setFromDate] = React.useState<Date | null | undefined>(
    new Date()
  );
  let [toDate, setToDate] = React.useState<Date | null | undefined>(new Date());
  let [filter, setFilter] = React.useState<string>("");

  let [allReports, setAllReports] = React.useState<IReport[]>([]);
  let [filteredReports, setFilteredReports] = React.useState<IReport[]>([]);
  const debouncedFilter = useDebounce<string>(filter, 500);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
        type: "ALL_REPORTS",
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let reports = response.data.values || [];

        let filtered = reports.filter(getIsMatch(filter));
        setAllReports(reports);
        setFilteredReports(filtered || []);
      }
    };

    getData();
  }, [fromDate, toDate]);

  React.useEffect(() => {
    let filtered = allReports.filter(getIsMatch(debouncedFilter));
    setFilteredReports(filtered || []);
  }, [debouncedFilter]);

  const { dispatch } = React.useContext(UserContext);

  return (
    <div className={reportsStyles.main}>
      <div className={reportsStyles.filter}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={2} md={3}>
              <DatePicker
                label="From"
                value={fromDate}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={2} md={3}>
              <DatePicker
                label="To"
                value={toDate}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4} sm={2} md={3}>
              <TextField
                label="Filter"
                margin="dense"
                size="small"
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </div>

      <div className={reportsStyles.reports}>
        {filteredReports.map((report) => {
          return (
            <div className={reportsStyles.report} key={report.id}>
              <div className={reportsStyles.info}>
                <div
                  className={reportsStyles.header}
                >{`${report.patName} [ ${report.entityName} ]`}</div>
                <div className={reportsStyles.additional}>
                  {`${
                    report.type === "AUDIO"
                      ? "Audio"
                      : report.type === "FINAL"
                      ? "Final"
                      : "Doc"
                  } [ ${format(
                    new Date(report.reportedDate),
                    "eee, yyyy-MM-dd HH:mm"
                  )} ]`}
                </div>
                <div
                  className={reportsStyles.detail}
                >{`${report.assignedToEntityName} / ${report.assignedToUserName}`}</div>
              </div>
              <div className={reportsStyles.actions}>
                <IconButton
                  aria-label="Download File"
                  onClick={async (e) => {
                    e.preventDefault();

                    let fileToDownload = {
                      publicOrPrivate:
                        report.details.publicOrPrivate || "private",
                      fileName: report.details.fileName,
                      fileType: report.details.fileType,
                      type: "GET",
                    };
                    try {
                      const response = await getSignedUrl(fileToDownload);
                      if (response.status === 200) {
                        if (
                          response.data &&
                          response.data.values &&
                          response.data.values.url
                        ) {
                          window.open(response.data.values.url, "_blank");
                        } else {
                          dispatch({
                            type: "SET-ERROR",
                            value: "File Download Error",
                          });
                        }
                      } else {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Download Error",
                        });
                      }
                    } catch (error) {
                      dispatch({
                        type: "SET-ERROR",
                        value: "File Download Error",
                      });
                    }

                    if (
                      hasEntityRole(report.entityId, "ADMIN") ||
                      hasEntityRole(report.entityId, "OPERATOR")
                    ) {
                      try {
                        let payload = {
                          action: "DOWNLOAD",
                          studyId: report.pk,
                          reportId: report.id,
                        };
                        let response = await postAction(payload);
                      } catch (error) {
                        //No need to raise error
                      }
                    }
                  }}
                  style={{ paddingLeft: "7px", cursor: "pointer" }}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            </div>
          );
        })}

        <>
          {filteredReports.length === 0 && (
            <div className={reportsStyles.noreports}>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                No Reports
              </Typography>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Reports;
