import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Button from "@mui/material/Button";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import CTextField from "../Components/CTextField";
import CSelectField from "../Components/CSelectField";
import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import { UserContext } from "../ui/UserContext";

import { getEntity, postEntity } from "../api";

import entityStyles from "./EntityCVM.module.css";

type AE = {
  ae: string;
};

type USER = {
  userId: string;
  roles: string[];
};

type RELATIONSHIP = {
  relatedEntityId: string;
  type: string;
};

export type IEntity = {
  id?: string;
  name: string;
  description: string;
  type: string;
  email: string;
  mobile: string;
  details?: string;
  status: string;
  charge: string;
  aes: AE[];
  users: USER[];
  relationships: RELATIONSHIP[];
};

function EntityCVM() {
  const { dispatch } = React.useContext(UserContext);
  let { id } = useParams();
  let navigate = useNavigate();
  let [reFetch, setReFetch] = React.useState<boolean>(false);
  let [toEdit, setToEdit] = React.useState<boolean>(false);
  let [allUsers, setUsers] = React.useState([]);
  let [allEntities, setEntities] = React.useState([]);

  const { handleSubmit, control, reset } = useForm<IEntity>({
    defaultValues: {
      name: "",
      description: "",
      type: "",
      email: "",
      mobile: "",
      details: "",
      status: "",
      charge: "",
      aes: [],
      users: [],
      relationships: [],
    },
  });

  const aesFieldArray = useFieldArray({
    control,
    name: "aes",
  });

  const usersFieldArray = useFieldArray({
    control,
    name: "users",
  });

  const relationshipsFieldArray = useFieldArray({
    control,
    name: "relationships",
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
      };
      let response = await postEntity(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Entity Saved Successfully",
        });
        navigate("/entitys");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save Entity",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let users = { type: "USERS_LOV" };
      let response = await getEntity(users);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setUsers(values);
        }
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let query = { type: "RELATED_ENTITIES_LOV", id: id };
      let response = await getEntity(query);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setEntities(values);
        }
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ENTITY_ALL", id: id };
      let response = await getEntity(study);
      if (response.status === 200) {
        let entity = response.data.values;
        if (entity) {
          setToEdit(true);
          reset(entity);
        }
        setReFetch(true);
      }
    };
    if (id) {
      getData();
    }
  }, [id, reFetch, reset]);

  return (
    <div className={entityStyles.main}>
      <div style={{ display: "flex" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={entityStyles.entity}
          noValidate
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="name"
                label="Name"
                required
                disabled={toEdit}
                control={control}
                autoFocus
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="description"
                label="Description"
                required
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="details"
                label="Details"
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="email"
                label="Email"
                required
                disabled={toEdit}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="mobile"
                label="Mobile"
                required
                disabled={toEdit}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="type"
                label="Type"
                required
                disabled={toEdit}
                control={control}
                options={[
                  { id: "HOSPITAL", label: "Hospital" },
                  { id: "REPORTING", label: "Reporting" },
                  { id: "PROVIDER", label: "Provider" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="charge"
                label="Charge"
                required
                disabled={toEdit}
                control={control}
                options={[
                  { id: "ALL", label: "All" },
                  { id: "REPORTED", label: "Reported" },
                ]}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="status"
                label="Status"
                required
                disabled={toEdit}
                control={control}
                options={[
                  { id: "ACTIVE", label: "Active" },
                  { id: "CLOSED", label: "Closed" },
                ]}
              />
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={4} sm={8} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">AE</Typography>
                <IconButton
                  aria-label="delete"
                  disabled={toEdit}
                  onClick={() => {
                    aesFieldArray.append({ ae: "" });
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>

            {aesFieldArray.fields.map((field, index) => (
              <Grid
                key={field.id}
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                spacing={1}
              >
                <Grid item xs={4} sm={4} md={4}>
                  <CTextField
                    name={`aes.${index}.ae`}
                    label="AE"
                    required
                    disabled={toEdit}
                    control={control}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    aria-label="delete"
                    disabled={toEdit}
                    onClick={() => {
                      aesFieldArray.remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={4} sm={8} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Users</Typography>
                <IconButton
                  aria-label="delete"
                  disabled={toEdit}
                  onClick={() => {
                    usersFieldArray.append({ userId: "", roles: [] });
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>

            {usersFieldArray.fields.map((field, index) => (
              <Grid
                key={field.id}
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                spacing={1}
              >
                <Grid item xs={4} sm={4} md={4}>
                  <CSingleAutoCompleteField
                    name={`users.${index}.userId`}
                    required
                    disabled={toEdit}
                    control={control}
                    label="User"
                    options={allUsers || []}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <CSelectField
                    name={`users.${index}.roles`}
                    label="Roles"
                    required
                    disabled={toEdit}
                    control={control}
                    multiple
                    options={[
                      { id: "ADMIN", label: "Admin" },
                      { id: "OPERATOR", label: "Operator" },
                      { id: "RADIOLOGIST", label: "Radiologist" },
                      { id: "REFERRAL", label: "Referral Entity" },
                      { id: "SUPERADMIN", label: "Superadmin" },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    aria-label="delete"
                    disabled={toEdit}
                    onClick={() => {
                      usersFieldArray.remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={4} sm={8} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Related Entities</Typography>
                <IconButton
                  aria-label="delete"
                  disabled={toEdit}
                  onClick={() => {
                    relationshipsFieldArray.append({
                      relatedEntityId: "",
                      type: "DIRECT",
                    });
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Grid>

            {relationshipsFieldArray.fields.map((field, index) => (
              <Grid
                key={field.id}
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                spacing={1}
              >
                <Grid item xs={4} sm={4} md={4}>
                  <CSingleAutoCompleteField
                    name={`relationships.${index}.relatedEntityId`}
                    required
                    disabled={toEdit}
                    control={control}
                    label="Entity"
                    options={allEntities || []}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <CSelectField
                    name={`relationships.${index}.type`}
                    label="Type"
                    required
                    disabled={toEdit}
                    control={control}
                    options={[
                      { id: "DIRECT", label: "Direct" },
                      { id: "INDIRECT", label: "Indirect" },
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    aria-label="delete"
                    disabled={toEdit}
                    onClick={() => {
                      relationshipsFieldArray.remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={2} sm={2} md={2}>
              {toEdit ? (
                <Button
                  color="primary"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    setToEdit(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  color="success"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                color="warning"
                onClick={() => {
                  navigate("/entitys");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default EntityCVM;
