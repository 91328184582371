import * as React from "react";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import Actions from "./Actions";
import studyDetailStyles from "./StudyInfo.module.css";
import { IStudy } from "./Study";
import {
  getStudyStatusText,
  getStudyActions,
  getStudyColor,
} from "../utilities";

interface IStudyInfo {
  study: IStudy | undefined;
}

function StudyInfo({ study }: IStudyInfo) {
  if (study) {
    return (
      <>
        <div className={studyDetailStyles.header}>
          <Typography variant="h6">{study.patName}</Typography>

          {study.name && (
            <>
              <div className={studyDetailStyles.center}>{study.name}</div>
            </>
          )}
        </div>
        <div className={studyDetailStyles.details}>
          <div className={`${studyDetailStyles.patient} `}>
            <div>
              <Tooltip title="ID" placement="right">
                <span>{study.patId}</span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Age / Sex" placement="right">
                <span>
                  {`${study.patBirthDate ? study.patBirthDate : "-"}`}/
                  {study.patSex}
                </span>
              </Tooltip>
            </div>
          </div>

          <div className={studyDetailStyles.study}>
            <div>
              <Tooltip title="StudyInfo Status" placement="right">
                <span
                  style={{
                    backgroundColor: getStudyColor(
                      study.studyStatus,
                      study.orderStatus
                    ),
                    // padding: "0px 5px",
                    fontWeight: "bold",
                  }}
                >
                  {getStudyStatusText(study.studyStatus)}
                  {study.orderStatus ? ` / ${study.orderStatus}` : ""}
                </span>
              </Tooltip>
            </div>

            <div style={{ marginBottom: "5px" }}>
              <Tooltip title="Modality" placement="right">
                <span>{study.modsInStudy}</span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Series / Instances" placement="right">
                <span>
                  {study.numSeries} / {study.numInstances}
                </span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Study Date" placement="right">
                <span>
                  {format(
                    new Date(study.studyDateTime),
                    "eee, yyyy-MM-dd HH:mm"
                  )}
                </span>
              </Tooltip>
            </div>
          </div>

          <div className={studyDetailStyles.order}>
            <div>
              <Tooltip title="Priority" placement="right">
                {(study.priority || "NORMAL").toUpperCase() === "EMERGENCY" ? (
                  <span style={{ color: "#F94892", fontWeight: "bold" }}>
                    Emergency
                  </span>
                ) : (
                  <span>Normal</span>
                )}
              </Tooltip>
            </div>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              <Tooltip title="StudyInfo" placement="right">
                <span>{study.studyStatus !== 0 ? study.studyText : ""}</span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Order Date" placement="right">
                <span>
                  {format(new Date(study.orderDate), "eee, yyyy-MM-dd HH:mm")}
                </span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Needed Before" placement="right">
                <span>
                  {format(
                    new Date(study.neededBefore),
                    "eee, yyyy-MM-dd HH:mm"
                  )}
                </span>
              </Tooltip>
            </div>
          </div>

          <div className={studyDetailStyles.assigned}>
            <div>
              <Tooltip title="Reporting Entity" placement="right">
                <span>{study.assignedToEntityName}</span>
              </Tooltip>
            </div>
            <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
              <Tooltip title="Reporting Doctor" placement="right">
                <span>
                  {study.studyStatus === 0
                    ? "Not Applicable"
                    : study.studyStatus === 5
                    ? "Not Applicable"
                    : study.studyStatus === 10
                    ? "Not Assigned"
                    : study.assignedToUserName}
                </span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Expected By" placement="right">
                <span>
                  {study.expectedBy
                    ? format(
                        new Date(study.expectedBy),
                        "eee, yyyy-MM-dd HH:mm"
                      )
                    : ""}
                </span>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Reported Date" placement="right">
                <span>
                  {study.reportedDate
                    ? format(
                        new Date(study.reportedDate),
                        "eee, yyyy-MM-dd HH:mm"
                      )
                    : ""}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={studyDetailStyles.actions}>
          <Actions
            actions={getStudyActions(study) || []}
            study={study}
            from="study"
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }
}

export default StudyInfo;
