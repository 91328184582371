import React from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Tooltip from "@mui/material/Tooltip";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";

import { hasRole } from "../utilities";

import bottombarStyles from "./Bottombar.module.css";

function Bottombar() {
  let users = useMatch({ path: "/users", end: true });
  let entitys = useMatch({ path: "/entitys", end: true });
  let refEntitys = useMatch({ path: "/refentitys", end: true });
  let transactions = useMatch({ path: "/transactions", end: true });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUsers = () => {
    setAnchorEl(null);
    navigate("/users");
  };

  const handleEntitys = () => {
    setAnchorEl(null);
    navigate("/entitys");
  };

  return (
    <div className={bottombarStyles.bottombar}>
      <Tooltip title="Dashboard" placement="right">
        <Link to="/dashboard" className={bottombarStyles.action}>
          <DashboardIcon
            sx={{
              color: useMatch({ path: "/dashboard", end: true })
                ? "#00EAD3"
                : "#fff",
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip title="Reports" placement="right">
        <Link to="/reports" className={bottombarStyles.action}>
          <AssignmentTurnedInIcon
            sx={{
              color: useMatch({ path: "/reports", end: true })
                ? "#00EAD3"
                : "#fff",
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip title="Availability" placement="right">
        <Link to="/availability" className={bottombarStyles.action}>
          <AccessTimeIcon
            sx={{
              color: useMatch({ path: "/availability", end: true })
                ? "#00EAD3"
                : "#fff",
            }}
          />
        </Link>
      </Tooltip>

      {(hasRole("ADMIN") || hasRole("OPERATOR") || hasRole("SUPERADMIN")) && (
        <>
          <Tooltip title="Referring Entities" placement="right">
            <Link to="/refentitys" className={bottombarStyles.action}>
              <PersonAddIcon
                sx={{
                  color: refEntitys ? "#00EAD3" : "#fff",
                }}
              />
            </Link>
          </Tooltip>
        </>
      )}

      {(hasRole("ADMIN") ||
        hasRole("OPERATOR") ||
        hasRole("RADIOLOGIST") ||
        hasRole("SUPERADMIN")) && (
        <>
          <Tooltip title="Transactions" placement="right">
            <Link to="/transactions" className={bottombarStyles.action}>
              <CurrencyRupeeIcon
                sx={{
                  color: transactions ? "#00EAD3" : "#fff",
                }}
              />
            </Link>
          </Tooltip>
        </>
      )}
      {/* 
{hasRole("SUPERADMIN") && (
        <>
          <Tooltip title="Entities" placement="right">
            <Link to="/entitys" className={bottombarStyles.action}>
              <LocalHospitalIcon
                sx={{
                  color: entitys ? "#00EAD3" : "#fff",
                }}
              />
            </Link>
          </Tooltip>

          <Tooltip title="Users" placement="right">
            <Link to="/users" className={bottombarStyles.action}>
              <PersonIcon
                sx={{
                  color: users ? "#00EAD3" : "#fff",
                }}
              />
            </Link>
          </Tooltip>
        </>
      )} */}

      {hasRole("SUPERADMIN") && (
        <>
          <IconButton
            onClick={handleClick}
            size="small"
            id="menu-button"
            // className={bottombarStyles.action}
            style={{ color: "#fff" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreHorizIcon />
          </IconButton>

          <Menu
            id="menu"
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuList dense sx={{ cursor: "" }}>
              <MenuItem
                sx={{ width: 200, fontSize: "0.85 rem" }}
                onClick={handleUsers}
              >
                <Tooltip title="Users" placement="right">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      color: "#151b26",
                    }}
                  >
                    <PersonIcon
                      sx={{
                        color: users ? "#00EAD3" : "#151b26",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        color: users ? "#00EAD3" : "#151b26",
                      }}
                    >
                      Users
                    </span>
                  </div>
                </Tooltip>
              </MenuItem>

              <MenuItem sx={{ width: 200 }} onClick={handleEntitys}>
                <Tooltip title="Entities" placement="right">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      color: "#151b26",
                    }}
                  >
                    <LocalHospitalIcon
                      sx={{
                        color: entitys ? "#00EAD3" : "#151b26",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        color: entitys ? "#00EAD3" : "#151b26",
                      }}
                    >
                      Entities
                    </span>
                  </div>
                </Tooltip>
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </div>
  );
}

export default Bottombar;
