import * as React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PreviewIcon from "@mui/icons-material/Preview";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Files from "../Components/Files";
import Logo from "../ui/Logo";
import Actions from "../Study/Actions";

import {
  OVIYAM_BASE_URL,
  IOVIYAM_BASE_URL,
  WEASIS_OLD_BASE_URL,
  sanitizePatientName,
} from "../utilities";
import { IStudy } from "../Study/Study";
import { IOrder } from "../Study/Order";
import {
  getStudyByUID,
  getOrderByStudyUID,
  getRelatedStudiesByStudyUID,
  getReportsByStudyUID,
} from "../api";

import shareStyles from "./Share.module.css";

function Share() {
  const { search } = useLocation();

  let params = new URLSearchParams(search);
  let studyUID = params.has("studyUID") ? params.get("studyUID") : "";

  let [study, setStudy] = React.useState<IStudy | undefined>();
  let [order, setOrder] = React.useState<IOrder | undefined>();
  let [relatedStudies, setRelatedStudies] = React.useState([]);

  let [reports, setReports] = React.useState<
    {
      id: string;
      reportedDate: Date;
      type: string;
      status: string;
      details: any;
    }[]
  >([]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getStudyByUID(studyUID as string);
      if (response.status === 200) {
        setStudy(response.data.values);
      }
    };
    if (studyUID) {
      getData();
    }
  }, [studyUID]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getOrderByStudyUID(studyUID as string);
      if (response.status === 200) {
        setOrder(response.data.values);
      }
    };
    if (studyUID) {
      getData();
    }
  }, [studyUID]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getRelatedStudiesByStudyUID(studyUID as string);
      if (response.status === 200) {
        setRelatedStudies(response.data.values);
      }
    };
    if (studyUID) {
      getData();
    }
  }, [studyUID]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getReportsByStudyUID(studyUID as string);
      if (response.status === 200) {
        setReports(response.data.values);
      }
    };
    if (studyUID) {
      getData();
    }
  }, [studyUID]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className={shareStyles.navbar}>
        <Link className={shareStyles.logo} to="/">
          <Logo />
        </Link>
        <div style={{ display: "flex" }} className={shareStyles.navheader}>
          <Typography variant="h4">TeleRAD</Typography>
        </div>
      </div>
      <div className={shareStyles.main}>
        <div className={shareStyles.study}>
          {study ? (
            <>
              <div className={shareStyles.header}>
                <Typography variant="h6">{study.patName}</Typography>

                {study.name && (
                  <>
                    <div className={shareStyles.center}>{study.name}</div>
                  </>
                )}
              </div>
              <div className={shareStyles.details}>
                <div className={`${shareStyles.patient} `}>
                  <div>
                    <Tooltip title="ID" placement="right">
                      <span>{study.patId}</span>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Age / Sex" placement="right">
                      <span>
                        {`${study.patBirthDate ? study.patBirthDate : "-"}`}/
                        {study.patSex}
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className={shareStyles.studydetail}>
                  <div style={{ marginBottom: "5px" }}>
                    <Tooltip title="Modality" placement="right">
                      <span>{study.modsInStudy}</span>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Series / Instances" placement="right">
                      <span>
                        {study.numSeries} / {study.numInstances}
                      </span>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Study Date" placement="right">
                      <span>
                        {format(
                          new Date(study.studyDateTime),
                          "eee, yyyy-MM-dd HH:mm"
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className={shareStyles.order}>
                  <div>
                    <Tooltip title="Priority" placement="right">
                      {(study.priority || "NORMAL").toUpperCase() === "EMERGENCY" ? (
                        <span style={{ color: "#F94892", fontWeight: "bold" }}>
                          Emergency
                        </span>
                      ) : (
                        <span>Normal</span>
                      )}
                    </Tooltip>
                  </div>
                  <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    <Tooltip title="StudyInfo" placement="right">
                      <span>
                        {study.studyStatus !== 0 ? study.studyText : ""}
                      </span>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Order Date" placement="right">
                      <span>
                        {format(
                          new Date(study.orderDate),
                          "eee, yyyy-MM-dd HH:mm"
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className={shareStyles.actions}>
                <Actions
                  actions={[
                    {
                      name: "oviyam",
                      title: "Oviyam",
                      link: `${OVIYAM_BASE_URL}studyUID=${study.studyIUID}`,
                    },
                    {
                      name: "ioviyam",
                      title: "IOviyam",
                      link: `${IOVIYAM_BASE_URL}patientID=&studyUID=${
                        study.studyIUID
                      }&patName=${sanitizePatientName(
                        study.patName
                      )}&patientGender=${study.patSex}&studyDesc=${
                        study.studyDesc
                      }&studyDate=${format(
                        new Date(study.studyDateTime),
                        "yyyy-MM-dd"
                      )}`,
                    },
                    {
                      name: "weasisold",
                      title: "Weasis",
                      link: `${WEASIS_OLD_BASE_URL}studyUID=${study.studyIUID}`,
                    },
                  ]}
                  study={study}
                  from="study"
                />
              </div>
            </>
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>

        <section id="order" className={shareStyles.orderinfo}>
          <Typography variant="h6" className={shareStyles.contentheader}>
            Order
          </Typography>
          <div className={shareStyles.contentdetails}>
            {order ? (
              <>
                <div className={shareStyles.contentmain}>
                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    spacing={1}
                    sx={{ marginBottom: "15px" }}
                  >
                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Clinical Information
                        </span>
                        <span>{order.clinicalInformation}</span>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Previous Injury
                        </span>
                        <span>{order.previousInjury}</span>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Previous Surgery
                        </span>
                        <span>{order.previousSurgery}</span>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    spacing={1}
                    sx={{ marginBottom: "15px" }}
                  >
                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Modality
                        </span>
                        <span>{order.modality}</span>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Priority
                        </span>
                        <span>
                          {(order.priority || "NORMAL").toUpperCase() === "EMERGENCY" ? (
                            <span
                              style={{ color: "#F94892", fontWeight: "bold" }}
                            >
                              Emergency
                            </span>
                          ) : (
                            <span>Normal</span>
                          )}
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Study
                        </span>
                        <span>{order.studyText}</span>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    spacing={1}
                    sx={{ marginBottom: "15px" }}
                  >
                    <Grid item xs={4} sm={4} md={4}>
                      <div className={shareStyles.contentdetail}>
                        <span className={shareStyles.contentdetailheader}>
                          Referring Doctor
                        </span>
                        <span>{order.referringDoctor}</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className={shareStyles.files}>
                  <span className={shareStyles.contentdetailheader}>Files</span>
                  {order.files && <Files files={order.files} header="Files" />}
                </div>
              </>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </div>
        </section>

        <section id="related" className={shareStyles.related}>
          <Typography variant="h6" className={shareStyles.contentheader}>
            Related Studies
          </Typography>
          <div className={shareStyles.contentdetails}>
            {relatedStudies ? (
              relatedStudies.length > 0 ? (
                <>
                  {relatedStudies.map((relatedStudy: IStudy) => {
                    return (
                      <div
                        key={relatedStudy.pk}
                        className={shareStyles.report}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          {`${relatedStudy.patName} - ${relatedStudy.modsInStudy} - ${relatedStudy.numSeries}/${relatedStudy.numInstances} `}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${OVIYAM_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Oviyam"} placement="top">
                                <IconButton aria-label="Oviyam">
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${IOVIYAM_BASE_URL}patientID=&studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"IOviyam"} placement="top">
                                <IconButton aria-label="IOviyam">
                                  <MobileFriendlyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${WEASIS_OLD_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Weasis"} placement="top">
                                <IconButton aria-label="Weasis">
                                  <ViewCozyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className={shareStyles.nostudies}>No Related Studies</div>
              )
            ) : (
              <div style={{ margin: "10px 0px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
          </div>
        </section>

        <section id="finalreports" className={shareStyles.reports}>
          <Typography variant="h6" className={shareStyles.contentheader}>
            Final Reports
          </Typography>

          <div className={shareStyles.contentdetails}>
            {reports ? (
              reports.length > 0 ? (
                <>
                  <div style={{ marginTop: "10px" }}>
                    {reports
                      .filter((report) => report.type === "FINAL")
                      .map((report, index) => {
                        return (
                          <div
                            key={report.id}
                            className={shareStyles.report}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {`Final Report ${index + 1} [
                              ${format(
                                new Date(report.reportedDate),
                                "eee, yyyy-MM-dd HH:mm"
                              )} ]`}
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div className={shareStyles.nostudies}>No Reports</div>
              )
            ) : (
              <div style={{ margin: "10px 0px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
          </div>
        </section>
      </div>

      <section id="footer" className={shareStyles.footer}>
        <div className={shareStyles.email}>
          <EmailIcon />
          <span style={{ paddingLeft: "5px" }}>info@teleradgroup.com</span>
        </div>
        <div className={shareStyles.phone}>
          <WhatsAppIcon />
          <span style={{ paddingLeft: "5px" }}>
            <a
              href="tel:+917708629235"
              style={{
                textDecoration: "none",
              }}
              className={shareStyles.phonedetail}
            >
              +91 77086 29235
            </a>
          </span>
        </div>
      </section>
    </div>
  );
}

export default Share;
