import * as React from "react";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import { UserContext } from "./UserContext";

import { getStudy } from "../api";
import Study, { IStudy } from "../Study/Study";
import dashboardStyles from "./Dashboard.module.css";
import AllStudies from "./AllStudies";
import { hasOnlyRole, formatDate } from "../utilities";

function Dashboard() {
  const { state } = React.useContext(UserContext);
  /*const debouncedFilter = useDebounce<string>(state.filter, 500);
   */
  let [pendingStudies, setPendingStudies] = React.useState<IStudy[]>();
  let [completedStudies, setCompletedStudies] = React.useState<IStudy[]>();

  //  let [escalatedStudies, setEscalatedStudies] = React.useState<IStudy[]>([]);

  // let [reworkStudies, setReworkStudies] = React.useState<IStudy[]>([]);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        type: "REPORTED",
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date()),
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let studies = response.data.values || [];
        setCompletedStudies(studies);
      }
    };
    const completedStudiesTimer = setInterval(() => getData(), 180000);
    return () => clearInterval(completedStudiesTimer);
  }, [state.pendingRefetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        type: "REPORTED",
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date()),
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let studies = response.data.values || [];
        setCompletedStudies(studies);
      }
    };
    getData();
  }, [state.pendingRefetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        type: "PENDING",
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let studies = response.data.values || [];
        setPendingStudies(studies);
      }
    };
    const pendingStudiesTimer = setInterval(() => getData(), 180000);
    return () => clearInterval(pendingStudiesTimer);
  }, [state.pendingRefetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        type: "PENDING",
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let studies = response.data.values || [];
        setPendingStudies(studies);
      }
    };
    getData();
  }, [state.pendingRefetch]);

  let newReworkStudies = (pendingStudies || []).filter((study: IStudy) => {
    return (study.studyStatus || 0) === 25;
  });

  let newEscalatedStudies = (pendingStudies || []).filter((study: IStudy) => {
    return (
      (study.studyStatus || 0) !== 25 &&
      (study.orderStatus || "@@").toUpperCase() === "ESCALATED"
    );
  });

  return (
    <div className={dashboardStyles.main}>
      {hasOnlyRole("REFERRAL") && (
        <>
          <div style={{ marginBottom: "15px", marginTop: "15px" }}>
            <Typography variant="h5">Completed</Typography>

            {completedStudies ? (
              completedStudies.length > 0 ? (
                <>
                  {completedStudies.map((study: IStudy) => {
                    return <Study key={study.pk} study={study} />;
                  })}
                </>
              ) : (
                <div className={dashboardStyles.nostudies}>
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                    No Studies
                  </Typography>
                </div>
              )
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </div>
        </>
      )}

      {newEscalatedStudies && newEscalatedStudies.length > 0 ? (
        <div style={{ marginBottom: "15px", marginTop: "15px" }}>
          <Typography variant="h5">Escalated</Typography>

          {newEscalatedStudies.map((study: IStudy) => {
            return <Study key={study.pk} study={study} />;
          })}
          {/* 
          {escalatedStudies.map((study: IStudy) => {
            return <Study key={study.pk} study={study} />;
          })} */}
        </div>
      ) : (
        <></>
      )}

      {newReworkStudies && newReworkStudies.length > 0 ? (
        <div style={{ marginBottom: "15px", marginTop: "15px" }}>
          <Typography variant="h5">Rework</Typography>
          {newReworkStudies.map((study: IStudy) => {
            return <Study key={study.pk} study={study} />;
          })}
          {/* 
          {reworkStudies.map((study: IStudy) => {
            return <Study key={study.pk} study={study} />;
          })} */}
        </div>
      ) : (
        <></>
      )}

      <div style={{ marginBottom: "15px", marginTop: "15px" }}>
        <Typography variant="h5">Pending</Typography>

        {pendingStudies ? (
          pendingStudies.length > 0 ? (
            <>
              {pendingStudies.map((study: IStudy) => {
                return <Study key={study.pk} study={study} />;
              })}
            </>
          ) : (
            <div className={dashboardStyles.nostudies}>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                No Studies
              </Typography>
            </div>
          )
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </div>

      <AllStudies />
    </div>
  );
}

export default Dashboard;
