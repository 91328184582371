import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { authUser, setLocalStorage } from "../api";

type LoginDialogType = {
  showLogIn: boolean;
  handleClose: () => void;
};

function LoginDialog(props: LoginDialogType) {
  let navigate = useNavigate();
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleSnackBarClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setError("");
    setOpen(false);
  };

  const handleDialogClose = (event: any) => {
    setError("");
    setOpen(false);
    props.handleClose();
  };

  const { handleSubmit, control } = useForm({
    defaultValues: {
      userName: "",
      password: "",
    },
  });

  const onSubmit = async (data: any) => {
    setError("");

    try {
      let payload = {
        userName: data.userName,
        password: data.password,
      };
      let response = await authUser(payload);
      if (response.status === 200) {
        setLocalStorage("authToken", response.data.token);
        setLocalStorage("roles", response.data.roles);
        setLocalStorage("subscriptionId", response.data.subscriptionId);
        navigate("/dashboard");
      } else {
        setOpen(true);
        setError("Invalid Credentials");
      }
    } catch (error) {
      setOpen(true);
      setError("Invalid Credentials");
    }
  };

  return (
    <Dialog
      open={props.showLogIn}
      sx={{ minWidth: "300px" }}
      onClose={handleDialogClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <Controller
            name="userName"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="dense"
                id="password"
                label="Password"
                type="password"
                required
                fullWidth
              />
            )}
          />
        </DialogContent>

        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleSnackBarClose}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            sx={{ minWidth: "100px" }}
            variant="contained"
            type="submit"
          >
            Login
          </Button>
          <Button sx={{ minWidth: "100px" }} onClick={handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LoginDialog;
