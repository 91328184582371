import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import Actions from "./Actions";
import studyStyles from "./Study.module.css";
import {
  getStudyActions,
  getStudyStatusText,
  getStudyColor,
} from "../utilities";

export interface IStudy {
  pk: string;
  patName: string;
  institution: string;
  studyIUID: string;
  patId: string;
  patSex: string;
  patBirthDate: string;
  modsInStudy: string;
  numSeries: string;
  numInstances: string;
  studyDateTime: Date;
  orderDate: Date;
  neededBefore: Date;
  expectedBy: Date;
  reportedDate: Date;
  priority: string;
  studyStatus: number;
  orderStatus: string;
  name: string;
  assignedToEntity: string;
  assignedToUser: string;
  assignedToEntityName: string;
  assignedToUserName: string;
  study: string[];
  entityId: string;
  newMessageCount: number;
  studyDesc: string;
  studyText?: string;
  referringDoctor?: string;
}

function Study(props: { study: IStudy }) {
  return (
    <div
      className={studyStyles.main}
      style={{
        borderLeft: `4px solid ${getStudyColor(
          props.study.studyStatus,
          props.study.orderStatus
        )}`,
      }}
    >
      <Link to={`/study/${props.study.pk}`} className={studyStyles.header}>
        <span style={{ margin: "0px" }}>{props.study.patName}</span>
        {props.study.name && (
          <span className={studyStyles.center}>{` - ${props.study.name}`}</span>
        )}

        <Tooltip title="Study Date" placement="right">
          <span className={studyStyles.center}>
            {` - [
            ${format(
              new Date(props.study.studyDateTime),
              "eee, yyyy-MM-dd HH:mm"
            )} ]`}
          </span>
        </Tooltip>

        {props.study.name ? (
          <>
            <span className={studyStyles.centermobile}>
              {`${props.study.name} - [ ${format(
                new Date(props.study.studyDateTime),
                "eee, yyyy-MM-dd HH:mm"
              )} ]`}
            </span>
          </>
        ) : (
          <span className={studyStyles.centermobile}>
            {`[ ${format(
              new Date(props.study.studyDateTime),
              "eee, yyyy-MM-dd HH:mm"
            )} ]`}
          </span>
        )}
      </Link>
      <div className={studyStyles.details}>
        <Link
          to={`/study/${props.study.pk}`}
          className={`${studyStyles.patient} ${studyStyles.desktop}`}
        >
          <div>
            <Tooltip title="ID" placement="right">
              <span>{props.study.patId}</span>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Age / Sex" placement="right">
              <span>
                {`${props.study.patBirthDate ? props.study.patBirthDate : "-"}`}
                /{props.study.patSex}
              </span>
            </Tooltip>
          </div>
        </Link>

        <Link to={`/study/${props.study.pk}`} className={studyStyles.study}>
          <div>
            <Tooltip title="Study Status" placement="right">
              <span
                style={{
                  fontWeight: "bold",
                  backgroundColor: getStudyColor(
                    props.study.studyStatus,
                    props.study.orderStatus
                  ),
                  //padding: "0px 5px",
                }}
              >
                {getStudyStatusText(props.study.studyStatus)}
                {props.study.orderStatus ? ` / ${props.study.orderStatus}` : ""}
              </span>
            </Tooltip>
          </div>

          <div style={{ marginBottom: "5px" }}>
            <Tooltip title="Modality" placement="right">
              <span>
                {props.study.modsInStudy} - {props.study.numSeries} /{" "}
                {props.study.numInstances}
              </span>
            </Tooltip>
          </div>
          {/* 
          <div>
            <Tooltip title="StudyInfo Date" placement="right">
              <span>
                {format(
                  new Date(props.study.studyDateTime),
                  "eee, yyyy-MM-dd HH:mm"
                )}
              </span>
            </Tooltip>
          </div> */}
        </Link>

        <Link to={`/study/${props.study.pk}`} className={studyStyles.order}>
          <div>
            <Tooltip title="Priority" placement="right">
              {(props.study.priority || "NORMAL").toUpperCase() ===
              "EMERGENCY" ? (
                <span style={{ color: "#F94892", fontWeight: "bold" }}>
                  Emergency
                </span>
              ) : (
                <span>Normal</span>
              )}
            </Tooltip>
          </div>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            <Tooltip title="Study" placement="right">
              <span>
                {props.study.studyStatus !== 0 ? props.study.studyText : ""}
              </span>
            </Tooltip>
          </div>
        </Link>

        <Link to={`/study/${props.study.pk}`} className={studyStyles.assigned}>
          <div>
            <Tooltip title="Reporting Entity" placement="right">
              <span>{props.study.assignedToEntityName}</span>
            </Tooltip>
          </div>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            <Tooltip title="Reporting Doctor" placement="right">
              <span>
                {props.study.studyStatus === 0
                  ? "Not Applicable"
                  : props.study.studyStatus === 5
                  ? "Not Applicable"
                  : props.study.studyStatus === 10
                  ? "Not Assigned"
                  : props.study.assignedToUserName}
              </span>
            </Tooltip>
          </div>
        </Link>

        <div className={studyStyles.actions}>
          <Actions
            actions={getStudyActions(props.study) || []}
            study={props.study}
            from="dashboard"
          />
        </div>
      </div>
      <div className={studyStyles.actionsmobile}>
        <Actions
          actions={getStudyActions(props.study) || []}
          study={props.study}
          from="dashboard"
        />
      </div>
    </div>
  );
}

export default Study;
