import * as React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { UserContext } from "./UserContext";

import appContainerStyles from "./AppContainer.module.css";
import Bottombar from "./Bottombar";

interface IAppContainerProps {
  component: React.ComponentType;
  header?: string;
}

const AppContainer = ({
  component: Component,
  header,
  ...rest
}: IAppContainerProps) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Navbar header={header} />
            <div className={appContainerStyles.component}>
              <Component {...rest} />
            </div>
          </div>
        </div>
      </div>
      <Bottombar />
      <ErrorMessage />
      <SuccessMessage />
    </div>
  );
};

function ErrorMessage() {
  const { state, dispatch } = React.useContext(UserContext);

  const handleSnackBarClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: "SET-ERROR",
      value: "",
    });
  };

  if (state.errorMessage) {
    return (
      <Snackbar
        open={!!state.errorMessage}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleSnackBarClose}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {state.errorMessage}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
}

function SuccessMessage() {
  const { state, dispatch } = React.useContext(UserContext);

  const handleSnackBarClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: "SET-SUCCESS",
      value: "",
    });
  };

  return (
    <Snackbar
      open={!!state.successMessage}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleSnackBarClose}
    >
      <Alert
        onClose={handleSnackBarClose}
        severity="success"
        sx={{ width: "100%" }}
      >
        {state.successMessage}
      </Alert>
    </Snackbar>
  );
}

export default AppContainer;
