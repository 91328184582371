import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { escapeRegExp } from "../utilities";
import { getUser, deleteReferral } from "../api";
import { UserContext } from "../ui/UserContext";
import refEntitysStyles from "./RefEntitys.module.css";

interface IRefEntity {
  id: string;
  entityId: string;
  userName: string;
  email: string;
  ae?: string;
}

function getIsMatch(filter: string) {
  const isMatch = (refEntity: IRefEntity) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return re.test(refEntity.userName) || re.test(refEntity.email);
  };
  return isMatch;
}

function Users() {
  let navigate = useNavigate();
  let [filter, setFilter] = React.useState("");
  let [refEntitys, setUsers] = React.useState<IRefEntity[]>([]);
  let [filteredUsers, setFilteredUsers] = React.useState<IRefEntity[]>([]);
  const { dispatch } = React.useContext(UserContext);
  let [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REFENTITYS" };
      let response = await getUser(study);
      if (response.status === 200) {
        let values = response.data.values;
        setUsers(values);
        setFilteredUsers(values);
      }
    };
    getData();
  }, [refetch]);

  return (
    <div className={refEntitysStyles.main}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter"
          margin="dense"
          size="small"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            let filteredValues = refEntitys.filter(
              getIsMatch(event.target.value)
            );
            setFilteredUsers(filteredValues);
          }}
          sx={{ width: 200, marginRight: 1 }}
        />

        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          onClick={() => {
            navigate(`/refentity/new`);
          }}
        >
          New
        </Button>
      </div>

      <div className={refEntitysStyles.refentitys}>
        {filteredUsers.map((refEntity) => {
          return (
            <div className={refEntitysStyles.refentity} key={refEntity.id}>
              <div className={refEntitysStyles.info}>
                <div className={refEntitysStyles.header}>
                  {refEntity.userName}
                </div>
                <div className={refEntitysStyles.additional}>
                  {`${refEntity.email} / ${refEntity.ae}`}
                </div>
              </div>

              <div className={refEntitysStyles.actions}>
                <IconButton
                  aria-label="Download File"
                  style={{ paddingLeft: "7px", cursor: "pointer" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    let entity = {
                      id: refEntity.id,
                      entityId: refEntity.entityId,
                    };

                    try {
                      const response = await deleteReferral(entity);
                      if (response.status === 200) {
                        dispatch({
                          type: "SET-SUCCESS",
                          value: "Referral Entity Deleted",
                        });
                        setRefetch(!refetch);
                      } else {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Download Error",
                        });
                      }
                    } catch (error) {
                      dispatch({
                        type: "SET-ERROR",
                        value: "File Download Error",
                      });
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Users;
