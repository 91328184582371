import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import { UserContext } from "../ui/UserContext";

import { getSignedUrl, putFileSignedUrl } from "../api";
import { formImagePath } from "../utilities";

export interface IFile {
  fileName: string;
  fileType: string;
  publicOrPrivate: string;
}

interface IFilesUpload {
  id: string;
  publicOrPrivate: string;
  fileFolder: string;
  files: IFile[];
  includeHeader: boolean;
  header?: string;
  fileSelectorText?: string;
  filesToAccept?: string;
  disabled?: boolean;
  updateFiles: (files: IFile[]) => void;
}

function FilesUpload(props: IFilesUpload) {
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
  const { dispatch } = React.useContext(UserContext);

  return (
    <div className="files">
      {props.includeHeader && (
        <h3 className="filesheader">{props.header ? props.header : "Files"}</h3>
      )}
      {!props.disabled && (
        <div className="form-field-block">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label htmlFor={props.id}>
                <p>
                  {props.fileSelectorText
                    ? props.fileSelectorText
                    : "Click Here to Select File and Press"}
                </p>
              </label>
              <input
                id={props.id}
                type="file"
                name="file"
                accept={
                  props.filesToAccept
                    ? props.filesToAccept
                    : "image/x-png,image/gif,image/jpeg,image/pdf,image/doc,image/docx,image/xls,image/xlsx"
                }
                onChange={(event) => {
                  if (
                    event &&
                    event.target &&
                    event.target.files &&
                    event.target.files.length > 0
                  ) {
                    let file = event.target.files[0];

                    if (file.size <= 5000000) {
                      setSelectedFile(event.target.files[0]);
                    } else {
                      dispatch({
                        type: "SET-ERROR",
                        value: "File Size Cannot be More than 5 MB",
                      });
                    }
                  }
                }}
              />
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <IconButton
                  // iconProps={addIcon}
                  //style={{ width: "100%" }}
                  disabled={!selectedFile}
                  onClick={async (event) => {
                    event.preventDefault();
                    if (selectedFile) {
                      let file = {
                        publicOrPrivate: props.publicOrPrivate || "private",
                        fileFolder: props.fileFolder,
                        fileName: selectedFile.name,
                        fileType: selectedFile.type,
                      };
                      try {
                        const response = await getSignedUrl(file);

                        if (response.status === 200) {
                          if (response.data && response.data.values) {
                            const uploadRespone = await putFileSignedUrl(
                              response.data.values.url,
                              selectedFile,
                              selectedFile.type
                            );

                            if (uploadRespone.status === 200) {
                              props.updateFiles([
                                ...props.files,
                                {
                                  fileName: response.data.values.fileName,
                                  fileType: selectedFile.type,
                                  publicOrPrivate:
                                    props.publicOrPrivate || "private",
                                },
                              ]);
                              setSelectedFile(undefined);
                              dispatch({
                                type: "SET-SUCCESS",
                                message: "File Uploaded",
                              });
                            }
                          }
                        }
                      } catch (error) {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Upload Error",
                        });
                      }
                    }
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
              </div>
              {selectedFile ? (
                <p
                  style={{
                    fontStyle: "italic",
                    margin: "0",
                    marginLeft: "5px",
                  }}
                >
                  {selectedFile.name}
                </p>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="form-field-block" style={{ flexDirection: "column" }}>
        {props.files.map((file, index) => {
          return (
            <div style={{ marginTop: "5px" }} key={file.fileName}>
              {file.publicOrPrivate.toUpperCase() === "PUBLIC" ? (
                <a
                  href={formImagePath(file.fileName)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`Attachment ${index + 1} [ ${file.fileType} ]`}
                </a>
              ) : (
                <>
                  <span
                    style={{
                      marginRight: "10px",
                      borderLeft: "2px solid #151b26",
                      paddingLeft: "5px",
                      marginLeft: "10px",
                      fontSize: "0.85rem",
                    }}
                  >{`Attachment ${index + 1} [ ${file.fileType} ]`}</span>

                  <IconButton
                    aria-label="Download File"
                    onClick={async (e) => {
                      e.preventDefault();

                      let fileToDownload = {
                        publicOrPrivate: props.publicOrPrivate || "private",
                        fileFolder: props.fileFolder,
                        fileName: file.fileName,
                        fileType: file.fileType,
                        type: "GET",
                      };
                      try {
                        const response = await getSignedUrl(fileToDownload);
                        if (response.status === 200) {
                          if (
                            response.data &&
                            response.data.values &&
                            response.data.values.url
                          ) {
                            window.open(response.data.values.url, "_blank");
                          } else {
                            dispatch({
                              type: "SET-ERROR",
                              value: "File Download Error",
                            });
                          }
                        } else {
                          dispatch({
                            type: "SET-ERROR",
                            value: "File Download Error",
                          });
                        }
                      } catch (error) {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Download Error",
                        });
                      }
                    }}
                    style={{ paddingLeft: "7px", cursor: "pointer" }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </>
              )}
              {!props.disabled && (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    let newFiles = props.files.filter(
                      (newFile: any) => newFile.fileName !== file.fileName
                    );
                    props.updateFiles(newFiles);
                  }}
                  style={{ paddingLeft: "7px", cursor: "pointer" }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FilesUpload;
