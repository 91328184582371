import * as React from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import CSelectField from "../Components/CSelectField";
import CTextField from "../Components/CTextField";
import { postAction } from "../api";
import { UserContext } from "../ui/UserContext";

interface IMisc {
  studyId: string;
  handleDialogClose: () => void;
  showDialog: boolean;
}

function Misc(props: IMisc) {
  const { dispatch } = React.useContext(UserContext);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      miscAction: "",
      detail: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        action: "MISC",
        studyId: props.studyId,
      };
      let response = await postAction(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Update Requested Successfully",
        });
        dispatch({
          type: "TOGGLE-PENDING-REFETCH",
        });
        props.handleDialogClose();
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error) {
      dispatch({ type: "SET-ERROR", value: "Failed to update" });
    }
  };

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={props.handleDialogClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Miscellaneous</DialogTitle>
        <DialogContent>
          <CSelectField
            name="miscAction"
            required
            control={control}
            label="Action"
            fullWidth
            autoFocus={false}
            options={[{ id: "NOT_NEEDED", label: "Not Needed" }]}
          />
          <CTextField
            name="detail"
            control={control}
            fullWidth
            label="Detail"
            autoFocus={false}
          />
        </DialogContent>

        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            sx={{ minWidth: "100px" }}
            variant="contained"
            type="submit"
          >
            Save
          </Button>

          <Button sx={{ minWidth: "100px" }} onClick={props.handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Misc;
