import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";

import { escapeRegExp } from "../utilities";
import { getEntity } from "../api";
import entitysStyles from "./Entitys.module.css";

interface ITemplate {
  id: number;
  name: string;
}

function getIsMatch(filter: string) {
  const isMatch = (entity: ITemplate) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return re.test(entity.name);
  };
  return isMatch;
}

function Entitys() {
  let navigate = useNavigate();
  let [filter, setFilter] = React.useState("");
  let [entitys, setEntitys] = React.useState<ITemplate[]>([]);
  let [filteredEntitys, setFilteredEntitys] = React.useState<ITemplate[]>([]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ENTITYS" };
      let response = await getEntity(study);
      if (response.status === 200) {
        let values = response.data.values;
        setEntitys(values);
        let filteredValues = values.filter(getIsMatch(filter));
        setFilteredEntitys(filteredValues);
      }
    };
    getData();
  }, []);

  return (
    <div className={entitysStyles.main}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter"
          margin="dense"
          size="small"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            let filteredValues = entitys.filter(getIsMatch(event.target.value));
            setFilteredEntitys(filteredValues);
          }}
          sx={{ width: 200, marginRight: 1 }}
        />

        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          onClick={() => {
            navigate(`/entity/new`);
          }}
        >
          New
        </Button>
      </div>

      <div className={entitysStyles.entitys}>
        {filteredEntitys.map((entity) => {
          return (
            <Link
              to={`/entity/${entity.id}/edit`}
              className={entitysStyles.entity}
              key={entity.id}
            >
              {entity.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Entitys;
