import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format, addHours } from "date-fns";
import Typography from "@mui/material/Typography";

import CTextField from "../Components/CTextField";
import CSelectField from "../Components/CSelectField";
import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import CDateTimeField from "../Components/CDateTimeField";

import { updateUser, getStudy } from "../api";
import { UserContext } from "../ui/UserContext";
import availabilityStyles from "./Password.module.css";

function Password() {
  const { dispatch } = React.useContext(UserContext);
  let navigate = useNavigate();
  const matches = useMediaQuery("(min-width:641px)");

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        action: "PASSWORD",
      };
      let response = await updateUser(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Password Updated Successfully",
        });
        navigate("/dashboard");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Update Password",
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={availabilityStyles.availability}
      noValidate
    >
      <Box
        sx={
          matches
            ? {
                marginTop: 1,
                marginBottom: 1,
                display: "flex",
                "& .MuiFormControl-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "25%",
                },
                "& .MuiAutocomplete-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "25%",
                },
                "& .MuiAutocomplete-root .MuiFormControl-root": {
                  marginRight: 0,
                  marginTop: 0,
                  width: "100%",
                },
              }
            : {
                "& .MuiFormControl-root": { width: "100%", marginTop: 2 },
              }
        }
      >
        <CTextField
          name="password"
          label="New Password"
          required
          control={control}
        />
      </Box>
      <Box
        sx={{
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          type="submit"
        >
          Update Password
        </Button>
      </Box>
    </form>
  );
}

export default Password;
