import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import Switch from "@mui/material/Switch";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { hasRole } from "../utilities";

import {
  userSignOut,
  userSignOutAll,
  deleteSubscription,
  getLocalStorage,
  setLocalStorage,
} from "../api";
import { getUserName } from "../utilities";
import {
  onClickSubscribeToNotification,
  isPermissionGranted,
} from "../pushNotification";

import navbarStyles from "./Navbar.module.css";

function Navbar(props: { header: string | undefined }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePreferences = () => {
    setAnchorEl(null);
    navigate("/preferences");
  };

  const handleTemplates = () => {
    setAnchorEl(null);
    navigate("/templates");
  };

  const handlePricing = () => {
    setAnchorEl(null);
    navigate("/pricing");
  };

  const handlePayment = () => {
    setAnchorEl(null);
    navigate("/payment");
  };

  const handleAssignList = () => {
    setAnchorEl(null);
    navigate("/assignlist");
  };

  const handleSignOut = () => {
    setAnchorEl(null);
    userSignOut();
  };

  const handleSignOutAll = () => {
    setAnchorEl(null);
    userSignOutAll();
  };

  const [notificationsEnabled, setNotificationsEnabled] = React.useState(
    isPermissionGranted()
  );

  return (
    <div className={navbarStyles.navbar}>
      <Link className={navbarStyles.logo} to="/dashboard">
        <Logo />
      </Link>
      <div style={{ display: "flex" }} className={navbarStyles.header}>
        <Typography variant="h5">
          {props.header ? props.header : "Dashboard"}
        </Typography>
      </div>
      <div
        style={{
          flexGrow: 2,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NotificationsIcon color="primary" />
          <Switch
            checked={notificationsEnabled}
            onChange={async (event) => {
              if (event.target.checked) {
                const response: any = await onClickSubscribeToNotification();
                if (response.status === 200) {
                  setNotificationsEnabled(true);
                }
              } else {
                const notification = {
                  subscriptionId: getLocalStorage("subscriptionId"),
                };
                const response: any = await deleteSubscription(notification);

                if (response.status === 200) {
                  setNotificationsEnabled(false);
                  setLocalStorage("subscriptionId", "");
                }
              }
            }}
            inputProps={{ "aria-label": "notification" }}
          />
        </div>
        <div className={navbarStyles.username}>{getUserName()}</div>
        <IconButton
          onClick={handleClick}
          size="small"
          id="menu-button"
          sx={{ ml: 2, mr: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircle />
        </IconButton>

        <Menu
          id="menu"
          aria-labelledby="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuList dense sx={{ cursor: "" }}>
            {getUserName() && (
              <MenuItem
                sx={{ width: 200, fontSize: "0.85 rem", cursor: "auto" }}
                className={navbarStyles.mobileusername}
              >
                {getUserName()}
              </MenuItem>
            )}

            <MenuItem
              sx={{ width: 200, fontSize: "0.85 rem" }}
              onClick={handlePreferences}
            >
              Preferences
            </MenuItem>
            <MenuItem
              sx={{ width: 200, fontSize: "0.85 rem" }}
              onClick={handleTemplates}
            >
              Templates
            </MenuItem>

            {(hasRole("SUPERADMIN") ||
              hasRole("ADMIN") ||
              hasRole("OPERATOR")) && (
              <MenuItem
                sx={{ width: 200, fontSize: "0.85 rem" }}
                onClick={handlePricing}
              >
                Pricing
              </MenuItem>
            )}

            {(hasRole("SUPERADMIN") ||
              hasRole("ADMIN") ||
              hasRole("OPERATOR")) && (
              <MenuItem
                sx={{ width: 200, fontSize: "0.85 rem" }}
                onClick={handlePayment}
              >
                Payment
              </MenuItem>
            )}

            {(hasRole("SUPERADMIN") ||
              hasRole("ADMIN") ||
              hasRole("OPERATOR")) && (
              <MenuItem
                sx={{ width: 200, fontSize: "0.85 rem" }}
                onClick={handleAssignList}
              >
                Assign List
              </MenuItem>
            )}

            <MenuItem
              sx={{ width: 200, fontSize: "0.85 rem" }}
              onClick={handleSignOut}
            >
              Sign Out
            </MenuItem>
            <MenuItem
              sx={{ width: 200, fontSize: "0.85 rem" }}
              onClick={handleSignOutAll}
            >
              Sign Out All Devices
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;
