import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Availability from "./Availability";
import Password from "./Password";
import Signature from "./Signature";

import preferenceSyles from "./Preferences.module.css";

function Preferences() {
  let [signature, setSignature] = React.useState("");
  let [disclaimer, setDisclaimer] = React.useState("");
  let [singatureImage, setSignatureImage] = React.useState("");

  return (
    <div className={preferenceSyles.main}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}
      >
        <Typography variant="h6">Availability</Typography>
        <div className={preferenceSyles.availability}>
          <Availability />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}
      >
        <Typography variant="h6">Password</Typography>
        <div className={preferenceSyles.password}>
          <Password />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "15px",
        }}
      >
        <Typography variant="h6">Signature</Typography>
        <div className={preferenceSyles.signature}>
          <Signature />
        </div>
      </div>
    </div>
  );
}

export default Preferences;
