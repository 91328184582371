import * as React from "react";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";

import { getAvailability } from "../api";

import availabilityStyles from "./Availability.module.css";

interface IAvailability {
  id: string;
  userId: string;
  userName: string;
  email: string;
  available: string;
  availableFrom: string;
  availableTo: string;
  entityName: string;
}

function Availabilitys() {
  let [availabilitys, setAvailabilitys] = React.useState<IAvailability[]>([]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getAvailability();
      if (response.status === 200) {
        let radiologists = response.data.values || [];
        setAvailabilitys(radiologists);
      }
    };

    getData();
  }, []);

  return (
    <div className={availabilityStyles.main}>
      <div className={availabilityStyles.reports}>
        {availabilitys.map((radiologist) => {
          return (
            <div className={availabilityStyles.report} key={radiologist.id}>
              <div className={availabilityStyles.info}>
                <div className={availabilityStyles.header}>
                  {radiologist.userName}
                </div>
                <div className={availabilityStyles.additional}>
                  {radiologist.entityName}
                </div>
                <div className={availabilityStyles.detail}>{`${format(
                  new Date(radiologist.availableFrom),
                  "eee, yyyy-MM-dd HH:mm"
                )}  -  ${format(
                  new Date(radiologist.availableTo),
                  "eee, yyyy-MM-dd HH:mm"
                )}`}</div>
              </div>
            </div>
          );
        })}

        <>
          {availabilitys.length === 0 && (
            <div className={availabilityStyles.noreports}>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                No Radiologists Available
              </Typography>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Availabilitys;
