import * as React from "react";

interface IUserContextProps {
  state: any;
  dispatch: React.Dispatch<any>;
}

export const UserContext = React.createContext<IUserContextProps>({
  state: {},
  dispatch: () => {},
});
