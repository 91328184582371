import * as React from "react";

function Logo() {
  return (
    <svg id="svg3088" version="1.1" viewBox="0 0 58.208332 58.208332">
      <defs id="defs3082" />
      <g transform="translate(-72.949408,-107.72321)" id="layer1">
        <g id="g3671">
          <circle
            // style="fill:#fabb51;fill-opacity:0.988235;stroke-width:0.264583"
            fill="#005F99"
            id="path2225-7-7-1-4-6"
            cx="102.05357"
            cy="136.82738"
            r="29.104166"
          />
          <circle
            // style="fill:#faedc6;fill-opacity:1;stroke-width:0.183834"
            fill="#fff"
            id="path2225-7"
            cx="102.05357"
            cy="136.82738"
            r="20.221725"
          />
          <path
            d="m 125.74177,119.95803 -25.29355,20.0396 -7.243481,-9.6666 -7.05125,5.58623 12.67674,16.91627 7.050731,-5.58621 23.98458,-19.00246 a 29.104167,29.104167 0 0 0 -4.12377,-8.28683 z"
            //style="fill:#00EAD3;fill-opacity:1;stroke-width:0.264583"
            fill="#00EAD3"
            id="path2225-7-7-1-5"
          />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
