import * as React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CSelectField from "../Components/CSelectField";
import DeleteIcon from "@mui/icons-material/Delete";

import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import { UserContext } from "../ui/UserContext";
import assignListStyles from "./AssignList.module.css";
import { getEntity, postEntityAssignList, getModalityStudies } from "../api";
import { IOption } from "../types";

type ASSIGNLIST = {
  type: string;
  modality: string;
  study: string;
  radiologistId: string;
};

function AssignList() {
  const { dispatch } = React.useContext(UserContext);
  let navigate = useNavigate();
  let [toEdit, setToEdit] = React.useState<boolean>(true);
  let [primaryEntities, setPrimaryEntities] = React.useState([]);
  let [studyEntities, setStudyEntities] = React.useState([]);
  let [radiologists, setRadiologists] = React.useState([]);
  let [fetched, setFetched] = React.useState<boolean>(false);

  let [studies, setStudies] = React.useState<{ [key: string]: IOption[] }>({
    CT: [],
    MR: [],
    CR: [],
  });

  const { handleSubmit, control, watch, setValue } = useForm<{
    primaryEntityId: string;
    secondaryEntityId: string;
    assignList: ASSIGNLIST[];
  }>({
    defaultValues: {
      primaryEntityId: "",
      secondaryEntityId: "",
      assignList: [],
    },
  });

  const assignListFieldArray = useFieldArray({
    control,
    name: "assignList",
  });

  const primaryEntityId = watch("primaryEntityId");
  const secondaryEntityId = watch("secondaryEntityId");

  React.useEffect(() => {
    const getData = async () => {
      let users = { type: "ENTITYS_LOV" };
      let response = await getEntity(users);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setPrimaryEntities(values);
        }
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let values = await Promise.all([
        getModalityStudies("CT"),
        getModalityStudies("MR"),
        getModalityStudies("CR"),
      ]);

      let ctStudies = values[0].data.values,
        ctResponse = values[0].status;
      let mrStudies = values[1].data.values,
        mrResponse = values[1].status;
      let crStudies = values[2].data.values,
        crResponse = values[2].status;
      if (ctResponse === 200 && mrResponse === 200 && crResponse === 200) {
        setStudies({ CT: ctStudies, MR: mrStudies, CR: crStudies });
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let entities = {
        type: "ASSIGNLIST_RELATED_ENTITYS_LOV",
        primaryEntityId: primaryEntityId,
      };
      let response = await getEntity(entities);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setStudyEntities(values);
        }
      }
    };
    if (primaryEntityId) {
      getData();
    }
  }, [primaryEntityId]);

  React.useEffect(() => {
    const getData = async () => {
      let entities = {
        type: "ENTITY_RADIOLOGISTS_LOV",
        assignedToEntity: primaryEntityId,
      };
      let response = await getEntity(entities);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setRadiologists(values);
        }
      }
    };
    if (primaryEntityId) {
      getData();
    }
  }, [primaryEntityId]);

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
      };
      let response = await postEntityAssignList(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "AssignList Saved Successfully",
        });
        navigate("/dashboard");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save AssignList",
      });
    }
  };

  return (
    <div className={assignListStyles.main}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={assignListStyles.order}
        noValidate
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          <Grid item xs={4} sm={4} md={5}>
            <CSingleAutoCompleteField
              name={"primaryEntityId"}
              required
              control={control}
              label="Primary Entity"
              options={primaryEntities || []}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={5}>
            <CSingleAutoCompleteField
              name={`secondaryEntityId`}
              required
              control={control}
              label="Secondary Entity"
              options={studyEntities}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={2}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="primary"
                disabled={!toEdit || !(primaryEntityId && secondaryEntityId)}
                onClick={async () => {
                  let entities = {
                    type: "ENTITY_ASSIGNLIST",
                    primaryEntityId: primaryEntityId,
                    secondaryEntityId: secondaryEntityId,
                  };
                  let response = await getEntity(entities);
                  if (response.status === 200) {
                    let values = response.data.values;
                    if (values) {
                      setValue("assignList", values);
                    }
                    setFetched(true);
                  }
                }}
              >
                Fetch
              </Button>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography variant="h6">AssignList</Typography>
          <IconButton
            aria-label="delete"
            disabled={toEdit}
            onClick={() => {
              assignListFieldArray.append({
                modality: "",
                study: "",
                type: "BLACKLIST",
              });
            }}
          >
            <AddIcon />
          </IconButton>
        </div>

        {assignListFieldArray.fields.map((field, index) => {
          const modality = watch(`assignList.${index}.modality`);

          return (
            <Grid
              key={field.id}
              container
              columns={{ xs: 4, sm: 8, md: 15 }}
              spacing={1}
            >
              <Grid item xs={4} sm={4} md={3}>
                <CSelectField
                  name={`assignList.${index}.type`}
                  label="Type"
                  required
                  disabled={toEdit}
                  control={control}
                  options={[
                    { id: "WHITELIST", label: "Whitelist" },
                    { id: "BLACKLIST", label: "Blacklist" },
                    { id: "PREFERLIST", label: "Preferlist" },
                  ]}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <CSelectField
                  name={`assignList.${index}.modality`}
                  label="Modality"
                  required
                  disabled={toEdit}
                  control={control}
                  options={[
                    { id: "CT", label: "CT" },
                    { id: "MR", label: "MR" },
                    { id: "CR", label: "X-Ray" },
                    { id: "ANY", label: "Any" },
                  ]}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <CSingleAutoCompleteField
                  name={`assignList.${index}.study`}
                  required
                  disabled={toEdit}
                  control={control}
                  label="Study"
                  options={
                    modality
                      ? modality === "ANY"
                        ? [{ id: "ANY", label: "Any" }]
                        : [...studies[modality], { id: "ANY", label: "Any" }]
                      : []
                  }
                />
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <CSingleAutoCompleteField
                  name={`assignList.${index}.radiologistId`}
                  required
                  disabled={toEdit}
                  control={control}
                  label="Radiologist"
                  options={radiologists}
                />
              </Grid>

              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  aria-label="delete"
                  disabled={toEdit}
                  onClick={() => {
                    assignListFieldArray.remove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginTop: "20px" }}
          spacing={1}
        >
          <Grid item xs={2} sm={2} md={2}>
            {toEdit ? (
              <Button
                color="primary"
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                disabled={!fetched}
                onClick={(event) => {
                  event.preventDefault();
                  setToEdit(false);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="success"
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{ minWidth: "100px", width: "100%" }}
              variant="contained"
              color="warning"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AssignList;
