import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useVoiceRecorder } from "use-voice-recorder";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";

import reportStyles from "./Report.module.css";
import { IStudy } from "./Study";
import { getStudy, downloadTemplate } from "../api";
import StudyInfo from "./StudyInfo";
import OrderInfo from "./OrderInfo";
import { IOrder } from "./Order";
import FileUpload from "../Components/FileUpload";
import { UserContext } from "../ui/UserContext";
import {
  getSignedUrl,
  putFileSignedUrl,
  postReport,
  deleteReport,
  getRelatedStudies,
} from "../api";
import {
  sanitizePatientName,
  getReworkText,
  OVIYAM_BASE_URL,
  WEASIS_BASE_URL,
  IOVIYAM_BASE_URL,
  WEASIS_OLD_BASE_URL,
} from "../utilities";

interface IOption {
  id: string;
  label: string;
}

function Report() {
  let [study, setStudy] = React.useState<IStudy | undefined>();
  let { studyId } = useParams();
  let [order, setOrder] = React.useState<IOrder | undefined>();
  let [reportFile, setReportFile] = React.useState<File | undefined>();
  let [template, setTemplate] = React.useState<IOption | null>();
  let [templates, setTemplates] = React.useState<IOption[]>([]);
  let [relatedStudies, setRelatedStudies] = React.useState([]);

  let [audioFiles, setAudioFiles] = React.useState<Blob[]>([]);
  const { isRecording, stop, start } = useVoiceRecorder((data) => {
    console.log("audio", data);
    setAudioFiles([...audioFiles, data]);
  });
  const { dispatch } = React.useContext(UserContext);
  let [refetch, setRefetch] = React.useState(false);
  let [reports, setReports] = React.useState<
    {
      id: string;
      reportedDate: Date;
      type: string;
      status: string;
      details: any;
    }[]
  >([]);
  let [reworks, setReworks] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "STUDY", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setStudy(response.data.values);
      }
    };
    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ORDER", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let order = response.data.values;
        setOrder(order);
      }
    };

    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REPORTS", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let reports = response.data.values;
        setReports(reports);
      }
    };

    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REWORK_DETAILS", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let values = response.data.values;
        setReworks(values);
      }
    };

    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "TEMPLATES_LOV", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setTemplates(response.data.values);
      }
    };
    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      if (studyId) {
        let response = await getRelatedStudies(studyId);
        if (response.status === 200) {
          let reports = response.data.values;
          setRelatedStudies(reports);
        }
      }
    };

    getData();
  }, [studyId, refetch]);

  const concatRecordings = () => {
    console.log("audioFiles", audioFiles.length);
    let blob = new Blob(audioFiles),
      url = URL.createObjectURL(blob);

    return url;
  };

  const reorder = (list: Blob[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      audioFiles,
      result.source.index,
      result.destination.index
    );
    setAudioFiles(items);
  }

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "10px 0px",
    margin: `0 0 5px 0`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: "5px 0px",
  });

  if (study) {
    return (
      <div className={reportStyles.main}>
        <div className={reportStyles.study}>
          <StudyInfo study={study} />
        </div>
        <div className={reportStyles.order}>
          <Typography variant="h6" className={reportStyles.header}>
            Order
          </Typography>
          <div className={reportStyles.details}>
            {order && <OrderInfo order={order} />}
          </div>
        </div>

        <section id="related" className={reportStyles.related}>
          <Typography variant="h6" className={reportStyles.header}>
            Related Studies
          </Typography>
          <div className={reportStyles.details}>
            {relatedStudies ? (
              relatedStudies.length > 0 ? (
                <>
                  {relatedStudies.map((relatedStudy: IStudy) => {
                    return (
                      <div
                        key={relatedStudy.pk}
                        className={reportStyles.report}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          {`${relatedStudy.patName} - ${relatedStudy.modsInStudy} - ${relatedStudy.numSeries}/${relatedStudy.numInstances} `}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${OVIYAM_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Oviyam"} placement="top">
                                <IconButton aria-label="Oviyam">
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${IOVIYAM_BASE_URL}patientID=&studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"IOviyam"} placement="top">
                                <IconButton aria-label="IOviyam">
                                  <MobileFriendlyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${WEASIS_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Weasis"} placement="top">
                                <IconButton aria-label="Weasis">
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${WEASIS_OLD_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Weasis Old"} placement="top">
                                <IconButton aria-label="Weasis Old">
                                  <ViewCozyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className={reportStyles.nostudies}>No Related Studies</div>
              )
            ) : (
              <div style={{ margin: "10px 0px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
          </div>
        </section>

        {reworks.length > 0 && (
          <section id="rework" className={reportStyles.related}>
            <Typography variant="h6" className={reportStyles.header}>
              Rework
            </Typography>
            <div className={reportStyles.details}>
              {reworks.map((rework: any) => {
                return (
                  <div key={rework.id} className={reportStyles.rework}>
                    <div
                      className={reportStyles.reworkheader}
                    >{`${getReworkText(rework.details.reason)} [ ${
                      rework.userName
                    } ]`}</div>
                    <div className={reportStyles.reworkdetails}>
                      {rework.details.details}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        <section id="reports" className={reportStyles.reports}>
          <Typography variant="h6" className={reportStyles.header}>
            Document Reports
          </Typography>

          <div className={reportStyles.details}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={4} sm={4} md={6}>
                <Autocomplete
                  value={template}
                  id="template"
                  options={templates}
                  onChange={(event, value) => {
                    setTemplate(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} margin="dense" label="Template" />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <Button
                  color="primary"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  disabled={!template}
                  onClick={async (event) => {
                    event.preventDefault();
                    try {
                      if (template && study) {
                        let data = {
                          id: template.id,
                          studyId: study.pk,
                          fileName: `TRG_TEMP_REP_${sanitizePatientName(
                            study ? study.patName : ""
                          )}.docx`,
                        };

                        const response = await downloadTemplate(data);
                        console.log("response", response);
                        if (response.status !== 200) {
                          console.log("dispatch");

                          dispatch({
                            type: "SET-ERROR",
                            value: "Unable to Download Template",
                          });
                        }
                      }
                    } catch (error) {
                      dispatch({
                        type: "SET-ERROR",
                        value: "Template Download Error",
                      });
                    }
                  }}
                >
                  Download
                </Button>
              </Grid>
            </Grid>

            <p style={{ margin: "20px 0px 0px 0px", fontWeight: "bold" }}>
              Upload
            </p>

            <FileUpload
              id={"reportfiles"}
              publicOrPrivate="private"
              fileFolder="reports"
              filesToAccept="image/pdf,image/doc,image/docx"
              fileName={reportFile ? reportFile.name : ""}
              updateFile={(reportFile: File | undefined) => {
                setReportFile(reportFile);
              }}
            />

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={4} sm={4} md={2}>
                <Button
                  color="primary"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  onClick={async (event) => {
                    event.preventDefault();

                    if (reportFile) {
                      try {
                        let file = {
                          publicOrPrivate: "private",
                          fileFolder: "reports",
                          fileName: reportFile.name,
                          fileType: reportFile.type,
                          newFileName: `TRG_DOC_REP_${sanitizePatientName(
                            study ? study.patName : ""
                          )}`,
                        };

                        const response = await getSignedUrl(file);

                        if (response.status === 200) {
                          if (response.data && response.data.values) {
                            const uploadRespone = await putFileSignedUrl(
                              response.data.values.url,
                              reportFile,
                              reportFile.type
                            );

                            if (uploadRespone.status === 200) {
                              try {
                                let report = {
                                  studyId: studyId,
                                  type: "DOC",
                                  details: {
                                    fileName: response.data.values.fileName,
                                    fileType: reportFile.type,
                                    publicOrPrivate: "private",
                                  },
                                };
                                let reportResponse = await postReport(report);
                                if (reportResponse.status === 200) {
                                  dispatch({
                                    type: "SET-SUCCESS",
                                    value: "Report Saved Successfully",
                                  });
                                  setReportFile(undefined);
                                  setRefetch(!refetch);
                                } else {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value: reportResponse.data.message,
                                  });
                                }
                              } catch (error: any) {
                                dispatch({
                                  type: "SET-ERROR",
                                  value:
                                    error?.response?.data?.message ||
                                    "Failed to Save Report",
                                });
                              }
                            }
                          }
                        }
                      } catch (error) {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Upload Error",
                        });
                      }
                    }
                  }}
                  disabled={!reportFile}
                >
                  Publish
                </Button>
              </Grid>
            </Grid>

            <div style={{ marginTop: "30px" }}>
              {reports
                .filter((report) => report.type === "DOC")
                .map((report, index) => {
                  return (
                    <div
                      key={report.id}
                      className={reportStyles.report}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`Report ${index + 1} [
                          ${format(
                            new Date(report.reportedDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}

                      <>
                        <div>
                          <IconButton
                            aria-label="Download File"
                            onClick={async (e) => {
                              e.preventDefault();

                              let fileToDownload = {
                                publicOrPrivate:
                                  report.details.publicOrPrivate || "private",
                                fileName: report.details.fileName,
                                fileType: report.details.fileType,
                                type: "GET",
                              };
                              try {
                                const response = await getSignedUrl(
                                  fileToDownload
                                );
                                if (response.status === 200) {
                                  if (
                                    response.data &&
                                    response.data.values &&
                                    response.data.values.url
                                  ) {
                                    window.open(
                                      response.data.values.url,
                                      "_blank"
                                    );
                                  } else {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: "File Download Error",
                                    });
                                  }
                                } else {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value: "File Download Error",
                                  });
                                }
                              } catch (error) {
                                dispatch({
                                  type: "SET-ERROR",
                                  value: "File Download Error",
                                });
                              }
                            }}
                            style={{ paddingLeft: "7px", cursor: "pointer" }}
                          >
                            <DownloadIcon />
                          </IconButton>

                          {report.status === "NEW" && (
                            <IconButton
                              aria-label="delete"
                              onClick={async () => {
                                try {
                                  let data = {
                                    studyId: studyId,
                                    id: report.id,
                                  };
                                  let reportResponse = await deleteReport(data);
                                  if (reportResponse.status === 200) {
                                    dispatch({
                                      type: "SET-SUCCESS",
                                      value: "Report Deleted Successfully",
                                    });
                                    setRefetch(!refetch);
                                  } else {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: reportResponse.data.message,
                                    });
                                  }
                                } catch (error: any) {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value:
                                      error?.response?.data?.message ||
                                      "Failed to Deleted Report",
                                  });
                                }
                              }}
                              //disabled={isRecording}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section id="audio" className={reportStyles.reports}>
          <Typography
            variant="h6"
            className={reportStyles.header}
            style={{ display: "flex", alignItems: "center" }}
          >
            Audio Reports
          </Typography>

          <div className={reportStyles.details}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={1}
              sx={{ alignItems: "center" }}
            >
              <Grid item xs={2} sm={2} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: "100px", width: "100%" }}
                  startIcon={<PlayArrowIcon />}
                  onClick={start}
                  disabled={isRecording}
                >
                  Record
                </Button>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ minWidth: "100px", width: "100%" }}
                  startIcon={<StopCircleIcon />}
                  onClick={stop}
                  disabled={!isRecording}
                >
                  Stop
                </Button>
              </Grid>
            </Grid>

            {audioFiles.length > 0 && (
              <div style={{ margin: "20px 0px", maxWidth: "400px" }}>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ margin: "15px 0px" }}
                >
                  <Grid item xs={4} sm={8} md={12}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {audioFiles.map((item, index) => (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <audio
                                      style={{ width: "280px" }}
                                      src={window.URL.createObjectURL(item)}
                                      controls
                                      preload={"metadata"}
                                    />
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        let newAudioFiles = [...audioFiles];
                                        console.log(index);
                                        newAudioFiles.splice(index, 1);
                                        console.log(index, newAudioFiles);
                                        setAudioFiles(newAudioFiles);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                </Grid>
                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  sx={{ margin: "15px 0px" }}
                >
                  <Grid item xs={4} sm={8} md={12}>
                    <audio
                      style={{ width: "280px" }}
                      src={concatRecordings()}
                      controls
                      preload={"metadata"}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            {audioFiles.length !== 0 && (
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ marginTop: "10px" }}
              >
                <Grid item xs={4} sm={4} md={2}>
                  <Button
                    color="primary"
                    sx={{ minWidth: "100px", width: "100%" }}
                    variant="contained"
                    disabled={audioFiles.length === 0}
                    onClick={async (event) => {
                      event.preventDefault();

                      try {
                        let type = audioFiles[0].type;
                        let fileName = type.startsWith("audio/webm")
                          ? `TRG_AUDIO_REP_${sanitizePatientName(
                              study ? study.patName : ""
                            )}.webm`
                          : `TRG_AUDIO_REP_${sanitizePatientName(
                              study ? study.patName : ""
                            )}`;
                        let blob = new Blob(audioFiles);
                        let audiReport = new File([blob], fileName, {
                          type: type,
                          lastModified: new Date().getTime(),
                        });
                        let file = {
                          publicOrPrivate: "private",
                          fileFolder: "reports",
                          fileName: fileName,
                          fileType: type,
                          newFileName: `TRG_AUDIO_REP_${sanitizePatientName(
                            study ? study.patName : ""
                          )}`,
                        };
                        const response = await getSignedUrl(file);

                        if (response.status === 200) {
                          if (response.data && response.data.values) {
                            const uploadRespone = await putFileSignedUrl(
                              response.data.values.url,
                              audiReport,
                              audiReport.type
                            );

                            if (uploadRespone.status === 200) {
                              try {
                                let report = {
                                  studyId: studyId,
                                  type: "AUDIO",
                                  details: {
                                    fileName: response.data.values.fileName,
                                    fileType: type,
                                    publicOrPrivate: "private",
                                  },
                                };
                                let reportResponse = await postReport(report);
                                if (reportResponse.status === 200) {
                                  dispatch({
                                    type: "SET-SUCCESS",
                                    value: "Audio Report Saved Successfully",
                                  });
                                  setRefetch(!refetch);
                                } else {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value: reportResponse.data.message,
                                  });
                                }
                              } catch (error: any) {
                                dispatch({
                                  type: "SET-ERROR",
                                  value:
                                    error?.response?.data?.message ||
                                    "Failed to Save Report",
                                });
                              }
                            }
                          }
                        }
                      } catch (error) {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Upload Error",
                        });
                      }
                    }}
                  >
                    Publish
                  </Button>
                </Grid>
              </Grid>
            )}
            <div style={{ marginTop: "30px" }}>
              {reports
                .filter((report) => report.type === "AUDIO")
                .map((report, index) => {
                  return (
                    <div
                      key={report.id}
                      className={reportStyles.report}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`Report ${index + 1} [
                          ${format(
                            new Date(report.reportedDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}

                      <>
                        <div>
                          <IconButton
                            aria-label="Download File"
                            onClick={async (e) => {
                              e.preventDefault();

                              let fileToDownload = {
                                publicOrPrivate:
                                  report.details.publicOrPrivate || "private",
                                fileName: report.details.fileName,
                                fileType: report.details.fileType,
                                type: "GET",
                              };
                              try {
                                const response = await getSignedUrl(
                                  fileToDownload
                                );
                                if (response.status === 200) {
                                  if (
                                    response.data &&
                                    response.data.values &&
                                    response.data.values.url
                                  ) {
                                    window.open(
                                      response.data.values.url,
                                      "_blank"
                                    );
                                  } else {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: "File Download Error",
                                    });
                                  }
                                } else {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value: "File Download Error",
                                  });
                                }
                              } catch (error) {
                                dispatch({
                                  type: "SET-ERROR",
                                  value: "File Download Error",
                                });
                              }
                            }}
                            style={{ paddingLeft: "7px", cursor: "pointer" }}
                          >
                            <DownloadIcon />
                          </IconButton>

                          {report.status === "NEW" && (
                            <IconButton
                              aria-label="delete"
                              onClick={async () => {
                                try {
                                  let data = {
                                    studyId: studyId,
                                    id: report.id,
                                  };
                                  let reportResponse = await deleteReport(data);
                                  if (reportResponse.status === 200) {
                                    dispatch({
                                      type: "SET-SUCCESS",
                                      value: "Report Deleted Successfully",
                                    });
                                    setRefetch(!refetch);
                                  } else {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: reportResponse.data.message,
                                    });
                                  }
                                } catch (error: any) {
                                  dispatch({
                                    type: "SET-ERROR",
                                    value:
                                      error?.response?.data?.message ||
                                      "Failed to Deleted Report",
                                  });
                                }
                              }}
                              //disabled={isRecording}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className={reportStyles.main}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }
}

export default Report;
