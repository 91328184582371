import * as React from "react";
import { Controller, Control, ErrorOption } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface IOption {
  id: string;
  label: string;
}

interface ICMultiAutoCompleteField {
  name: string;
  control: Control<any>;
  label: string;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: ErrorOption;
  options: IOption[];
  postChange?: (value: string) => void;
}

function CMultiAutoCompleteField({
  name,
  autoFocus,
  label,
  required,
  disabled,
  multiline,
  rows,
  control,
  error,
  options,
  postChange,
}: ICMultiAutoCompleteField) {
  return (
    <Controller
      name={name}
      rules={{ required: required }}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Autocomplete
            {...field}
            value={field.value || null}
            options={options}
            disabled={disabled}
            onChange={(event, value) => {
              if (!value) {
                field.onChange("");
                if (postChange) {
                  postChange("");
                }
                return;
              }
              let idValue = "";
              if (value.id) {
                idValue = value.id;
              } else {
                idValue = value;
              }
              field.onChange(idValue);
              if (postChange) {
                postChange(idValue);
              }
            }}
            getOptionLabel={(option) => {
              if (option.label) {
                return option.label;
              }
              let labelOption = options.find(
                (listOption) => listOption.id === option
              );
              if (labelOption && labelOption.label) {
                return labelOption.label;
              }
              return;
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label={label}
                required={required}
                disabled={disabled}
                autoFocus={autoFocus}
                multiline={multiline}
                rows={rows}
                error={
                  fieldState.error
                    ? fieldState.error.type
                      ? true
                      : false
                    : false
                }
                helperText={
                  fieldState.error
                    ? fieldState.error.type === "required"
                      ? ""
                      : "Invalid"
                    : ""
                }
              />
            )}
          />
        );
      }}
    />
  );
}

export default CMultiAutoCompleteField;
