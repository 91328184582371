import * as React from "react";
import { Link } from "react-router-dom";

import CloudIcon from "@mui/icons-material/Cloud";
import LaptopIcon from "@mui/icons-material/Laptop";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StorageIcon from "@mui/icons-material/Storage";
import BackupIcon from "@mui/icons-material/Backup";
import { Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Logo from "./Logo";
import LoginDialog from "./LoginDialog";
import { isAuthorized } from "../utilities";

import AboutUsStyles from "./AboutUs.module.css";

function AboutUs() {
  const [showLogIn, setShowLogIn] = React.useState(false);

  const handleClose = () => {
    setShowLogIn(false);
  };

  return (
    <>
      <section id="main" className={AboutUsStyles.container}>
        <div className={AboutUsStyles.backgroundimage}></div>

        <nav className={AboutUsStyles.nav}>
          <div className={AboutUsStyles.navlogo}>
            <Logo />
          </div>
          <div className={AboutUsStyles.navbrandname}>
            <h4 className={AboutUsStyles.header}>TeleRAD</h4>
          </div>
        </nav>

        <main className={AboutUsStyles.hero}>
          <div className={AboutUsStyles.main}>
            <div className={AboutUsStyles.brand}>
              <div className={AboutUsStyles.logo}>
                <Logo />
              </div>
              <div className={AboutUsStyles.brandname}>
                <h1 className={AboutUsStyles.header}>TeleRAD</h1>
              </div>
            </div>
          </div>
        </main>
      </section>

      <section id="terms" className={AboutUsStyles.services}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">About Us</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={AboutUsStyles.service}
        >
          <div style={{ marginTop: "10px", textAlign: "justify" }}>
            We, TeleRAD Group, are one of the leading telearadiology solution
            providers in India. We provide complete suite of radiology reporting
            services that caters to all requirements of Radiology centers and
            Radiologists.
            <p>Our services include</p>
            <p>
              <span>
                <Typography variant="h5">Tele Reporting</Typography>
                Best in-class, high quality, on time, NABH compliant reports
                made available by our radiologists. 24/7 coverage for your
                reporting needs.
              </span>
            </p>
            <p>
              <span>
                <Typography variant="h5">Second Opinion</Typography>
                Need expert opinions on a difficult study? Our expert
                radiologists will help arrive at the right and accurate
                diagnosis and of highest quality.
              </span>
            </p>
            <p>
              <span>
                <Typography variant="h5">Cloud PACS</Typography>
                Highly secure, HIPAA complaint, easy to use cloud PACS workflow
                solution with privacy at its core to protect sensitive patient
                information.
              </span>
            </p>
            <p>
              <span>
                <Typography variant="h5">DICOM Server</Typography>
                We provide DICOM software, installation and maintenance services
                for your inhouse requirements at a very affordable cost.
              </span>
            </p>
            <p>
              <span>
                <Typography variant="h5">Long Term Storage</Typography>
                Cloud backed secure and complaint long term storage of DICOM
                studies that is highly and readily available.
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
