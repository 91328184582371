import * as React from "react";
import { useForm, NestedValue } from "react-hook-form";
import Button from "@mui/material/Button";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { format, addHours } from "date-fns";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import CTextField from "../Components/CTextField";
import CSelectField from "../Components/CSelectField";
import CMultiAutoCompleteField from "../Components/CMultiAutoCompleteField";
import CDateTimeField from "../Components/CDateTimeField";
import { UserContext } from "../ui/UserContext";

import { IStudy } from "./Study";
import StudyInfo from "./StudyInfo";
import { getStudy, postOrder, getModalityStudies } from "../api";
import { hasEntityRole, hasRole } from "../utilities";

import orderStyles from "./Order.module.css";
import { getModality } from "../utilities";
import FilesUpload, { IFile } from "../Components/FilesUpload";

type IOrderForm = {
  clinicalInformation: string;
  previousSurgery: string;
  previousInjury: string;
  modality: string;
  priority: string;
  neededBefore: string;
  study: NestedValue<string[]>;
  files: NestedValue<IFile[]>;
  relatedStudies: NestedValue<string[]>;
  referringDoctor?: string;
};

export interface IOrder {
  clinicalInformation: string;
  previousSurgery: string;
  previousInjury: string;
  modality: string;
  priority: string;
  neededBefore: string;
  study: string[];
  files: IFile[];
  relatedStudies: string[];
  studyText?: string;
  referringDoctor?: string;
}

function Order() {
  const { dispatch } = React.useContext(UserContext);
  let [study, setStudy] = React.useState<IStudy | undefined>();
  let { studyId } = useParams();
  let location = useLocation();
  let navigate = useNavigate();
  let [reFetch, setReFetch] = React.useState<boolean>(false);
  let [toEdit, setToEdit] = React.useState<boolean>(false);
  let [relatedStudies, setRelatedStudies] = React.useState<
    { id: string; label: string }[]
  >([]);

  let [modalityStudies, setModalityStudies] = React.useState<
    { id: string; label: string }[]
  >([]);

  const { handleSubmit, setValue, control, watch, reset, getValues } =
    useForm<IOrderForm>({
      defaultValues: {
        clinicalInformation: "",
        previousSurgery: "",
        previousInjury: "",
        modality: "CT",
        priority: "NORMAL",
        neededBefore: format(addHours(new Date(), 2), "dd-MMM-yyyy HH:mm"),
        study: [],
        files: [],
        relatedStudies: [],
        referringDoctor: "",
      },
    });

  let files = watch("files");
  const modality = watch("modality");

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        studyId: studyId,
      };
      let response = await postOrder(payload);
      if (response.status === 200) {
        dispatch({ type: "SET-SUCCESS", value: "Order Saved Successfully" });
        if ((location.state as any).from === "study") {
          navigate(`/study/${studyId}`);
        } else {
          navigate("/dashboard");
        }
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save Order",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "STUDY", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setStudy(response.data.values);
        setValue("modality", getModality(response.data.values.modsInStudy));
        setReFetch(true);
      }
    };

    getData();
  }, [studyId, reFetch]);

  React.useEffect(() => {
    const getData = async () => {
      let response = await getModalityStudies(modality);
      if (response.status === 200) {
        setModalityStudies(response.data.values);
      }
    };
    if (modality) {
      getData();
    }
  }, [modality]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ORDER", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let order = response.data.values;
        if (order) {
          setToEdit(true);
          reset(order);
        }
        setReFetch(true);
      }
    };

    getData();
  }, [studyId, reFetch]);

  React.useEffect(() => {
    const getData = async () => {
      let related = {
        type: "RELATED_STUDIES_LOV",
        studyId: studyId,
      };
      let response = await getStudy(related);
      if (response.status === 200) {
        setRelatedStudies(response.data.values);
      }
    };
    getData();
  }, [studyId]);

  let allowEdit =
    study &&
    (hasEntityRole(study.entityId, "ADMIN") ||
      hasEntityRole(study.entityId, "OPERATOR") ||
      hasEntityRole(study.assignedToEntity, "ADMIN") ||
      hasEntityRole(study.assignedToEntity, "OPERATOR") ||
      hasRole("SUPERADMIN"));

  return (
    <div className={orderStyles.main}>
      <StudyInfo study={study} />
      <div style={{ display: "flex" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={orderStyles.order}
          noValidate
        >
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            spacing={1}
            sx={{ marginTop: "20px" }}
          >
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="clinicalInformation"
                label="Clinical Information"
                required
                disabled={toEdit}
                control={control}
                autoFocus
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="previousSurgery"
                label="Previous Surgery"
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="previousInjury"
                label="Previous Injury"
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="modality"
                label="Modality"
                required
                disabled={toEdit}
                control={control}
                options={[
                  { id: "CT", label: "CT" },
                  { id: "MR", label: "MR" },
                  { id: "CR", label: "X-Ray" },
                ]}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="priority"
                label="Priority"
                disabled={toEdit}
                control={control}
                required
                options={[
                  { id: "NORMAL", label: "Normal" },
                  { id: "EMERGENCY", label: "Emergency" },
                ]}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CDateTimeField
                name="neededBefore"
                label="Needed Before"
                disabled={toEdit}
                control={control}
                required
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={8} md={8}>
              <CMultiAutoCompleteField
                name="study"
                required
                disabled={toEdit}
                control={control}
                label="Study"
                options={study ? modalityStudies : []}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="referringDoctor"
                label="Referring Doctor"
                disabled={toEdit}
                control={control}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <FilesUpload
              id={"orderfiles"}
              publicOrPrivate="private"
              fileFolder="order"
              disabled={toEdit}
              files={files || []}
              includeHeader
              updateFiles={(orderFiles) => {
                setValue("files", orderFiles);
              }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography variant="h6">Related Studies</Typography>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
              <Grid item xs={4} sm={8} md={12}>
                <CMultiAutoCompleteField
                  name="relatedStudies"
                  disabled={toEdit}
                  control={control}
                  options={relatedStudies}
                />
              </Grid>
            </Grid>
          </div>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            {allowEdit && (
              <Grid item xs={2} sm={2} md={2}>
                {toEdit ? (
                  <Button
                    color="primary"
                    sx={{ minWidth: "100px", width: "100%" }}
                    variant="contained"
                    onClick={(event) => {
                      event.preventDefault();
                      setToEdit(false);
                    }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    color="success"
                    sx={{ minWidth: "100px", width: "100%" }}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </Button>
                )}
              </Grid>
            )}

            <Grid item xs={2} sm={2} md={2}>
              <Button
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                color="warning"
                onClick={() => {
                  if ((location.state as any).from === "study") {
                    navigate(`/study/${studyId}`);
                  } else {
                    navigate("/dashboard");
                  }
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Order;
