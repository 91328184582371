import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { useLocation, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";

import studyDetailStyles from "./StudyDetail.module.css";
import { IStudy } from "./Study";
import { getRelatedStudies, getStudy } from "../api";
import StudyInfo from "./StudyInfo";
import OrderInfo from "./OrderInfo";
import { IOrder } from "./Order";
import {
  sanitizePatientName,
  getReworkText,
  hasRole,
  OVIYAM_BASE_URL,
  WEASIS_BASE_URL,
  IOVIYAM_BASE_URL,
  WEASIS_OLD_BASE_URL,
} from "../utilities";
import { UserContext } from "../ui/UserContext";
import {
  getSignedUrl,
  deleteReport,
  putFileSignedUrl,
  postReport,
} from "../api";
import FileUpload from "../Components/FileUpload";

function StudyDetail() {
  let [study, setStudy] = React.useState<IStudy | undefined>();
  let { studyId } = useParams();
  const { pathname, hash, key } = useLocation();
  let [order, setOrder] = React.useState<IOrder | undefined>();
  let [reworks, setReworks] = React.useState([]);
  let [relatedStudies, setRelatedStudies] = React.useState([]);

  let [reports, setReports] = React.useState<
    {
      id: string;
      reportedDate: Date;
      type: string;
      status: string;
      details: any;
    }[]
  >([]);
  const { dispatch } = React.useContext(UserContext);
  let [refetch, setRefetch] = React.useState(false);
  let [reportFile, setReportFile] = React.useState<File | undefined>();

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "STUDY", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setStudy(response.data.values);
      }
    };
    getData();
  }, [studyId]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ORDER", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let order = response.data.values;
        setOrder(order);
      }
    };

    getData();
  }, [studyId]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REWORK_DETAILS", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let values = response.data.values;
        setReworks(values);
      }
    };

    getData();
  }, [studyId]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REPORTS", studyId: studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        let reports = response.data.values;
        setReports(reports);
      }
    };

    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    const getData = async () => {
      if (studyId) {
        let response = await getRelatedStudies(studyId);
        if (response.status === 200) {
          let reports = response.data.values;
          setRelatedStudies(reports);
        }
      }
    };

    getData();
  }, [studyId, refetch]);

  React.useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 200);
    }
  }, [pathname, hash, key]);

  if (study) {
    return (
      <div className={studyDetailStyles.main}>
        <div className={studyDetailStyles.study}>
          <StudyInfo study={study} />
        </div>

        <section id="order" className={studyDetailStyles.order}>
          <Typography variant="h6" className={studyDetailStyles.header}>
            Order
          </Typography>
          <div className={studyDetailStyles.details}>
            {order && <OrderInfo order={order} />}
          </div>
        </section>

        <section id="related" className={studyDetailStyles.related}>
          <Typography variant="h6" className={studyDetailStyles.header}>
            Related Studies
          </Typography>
          <div className={studyDetailStyles.details}>
            {relatedStudies ? (
              relatedStudies.length > 0 ? (
                <>
                  {relatedStudies.map((relatedStudy: IStudy) => {
                    return (
                      <div
                        key={relatedStudy.pk}
                        className={studyDetailStyles.report}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          {`${relatedStudy.patName} - ${relatedStudy.modsInStudy} - ${relatedStudy.numSeries}/${relatedStudy.numInstances} `}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${OVIYAM_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Oviyam"} placement="top">
                                <IconButton aria-label="Oviyam">
                                  <PreviewIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${IOVIYAM_BASE_URL}patientID=&studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"IOviyam"} placement="top">
                                <IconButton aria-label="IOviyam">
                                  <MobileFriendlyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${WEASIS_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Weasis"} placement="top">
                                <IconButton aria-label="Weasis">
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>

                          <div>
                            <a
                              className="ui basic button"
                              target="_blank"
                              rel="noreferrer"
                              href={`${WEASIS_OLD_BASE_URL}studyUID=${relatedStudy.studyIUID}`}
                            >
                              <Tooltip title={"Weasis Old"} placement="top">
                                <IconButton aria-label="Weasis Old">
                                  <ViewCozyIcon />
                                </IconButton>
                              </Tooltip>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className={studyDetailStyles.nostudies}>
                  No Related Studies
                </div>
              )
            ) : (
              <div style={{ margin: "10px 0px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
          </div>
        </section>

        {reworks.length > 0 && (
          <section id="rework" className={studyDetailStyles.related}>
            <Typography variant="h6" className={studyDetailStyles.header}>
              Rework
            </Typography>
            <div className={studyDetailStyles.details}>
              {reworks.map((rework: any) => {
                return (
                  <div key={rework.id} className={studyDetailStyles.rework}>
                    <div
                      className={studyDetailStyles.reworkheader}
                    >{`${getReworkText(rework.details.reason)} [ ${
                      rework.userName
                    } ]`}</div>
                    <div className={studyDetailStyles.reworkdetails}>
                      {rework.details.details}
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}

        <section id="reports" className={studyDetailStyles.reports}>
          <Typography variant="h6" className={studyDetailStyles.header}>
            Reports
          </Typography>

          <div className={studyDetailStyles.details}>
            {reports ? (
              reports.length > 0 ? (
                <>
                  {reports
                    .filter((report) => report.type !== "FINAL")
                    .map((report, index) => {
                      return (
                        <div
                          key={report.id}
                          className={studyDetailStyles.report}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {`${report.type === "DOC" ? "Doc" : "Audio"} Report ${
                            index + 1
                          } [
                          ${format(
                            new Date(report.reportedDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}

                          <>
                            <div>
                              <IconButton
                                aria-label="Download File"
                                onClick={async (e) => {
                                  e.preventDefault();

                                  let fileToDownload = {
                                    publicOrPrivate:
                                      report.details.publicOrPrivate ||
                                      "private",
                                    fileName: report.details.fileName,
                                    fileType: report.details.fileType,
                                    type: "GET",
                                  };
                                  try {
                                    const response = await getSignedUrl(
                                      fileToDownload
                                    );
                                    if (response.status === 200) {
                                      if (
                                        response.data &&
                                        response.data.values &&
                                        response.data.values.url
                                      ) {
                                        window.open(
                                          response.data.values.url,
                                          "_blank"
                                        );
                                      } else {
                                        dispatch({
                                          type: "SET-ERROR",
                                          value: "File Download Error",
                                        });
                                      }
                                    } else {
                                      dispatch({
                                        type: "SET-ERROR",
                                        value: "File Download Error",
                                      });
                                    }
                                  } catch (error) {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: "File Download Error",
                                    });
                                  }
                                }}
                                style={{
                                  paddingLeft: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                onClick={async () => {
                                  try {
                                    let data = {
                                      studyId: studyId,
                                      id: report.id,
                                    };
                                    let reportResponse = await deleteReport(
                                      data
                                    );
                                    if (reportResponse.status === 200) {
                                      dispatch({
                                        type: "SET-SUCCESS",
                                        value: "Report Deleted Successfully",
                                      });
                                      setRefetch(!refetch);
                                    } else {
                                      dispatch({
                                        type: "SET-ERROR",
                                        value: reportResponse.data.message,
                                      });
                                    }
                                  } catch (error: any) {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value:
                                        error?.response?.data?.message ||
                                        "Failed to Deleted Report",
                                    });
                                  }
                                }}
                                //disabled={isRecording}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className={studyDetailStyles.nostudies}>No Reports</div>
              )
            ) : (
              <div style={{ margin: "10px 0px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
          </div>
        </section>
        {(hasRole("SUPERADMIN") || hasRole("ADMIN") || hasRole("OPERATOR")) && (
          <>
            <section id="finalreports" className={studyDetailStyles.reports}>
              <Typography variant="h6" className={studyDetailStyles.header}>
                Final Reports
              </Typography>

              <div
                className={studyDetailStyles.details}
                style={{ paddingTop: "0" }}
              >
                <p style={{ margin: "20px 0px 0px 0px", fontWeight: "bold" }}>
                  Upload
                </p>

                <FileUpload
                  id={"reportfiles"}
                  publicOrPrivate="private"
                  fileFolder="reports"
                  filesToAccept="image/pdf,image/doc,image/docx"
                  fileName={reportFile ? reportFile.name : ""}
                  updateFile={(reportFile: File | undefined) => {
                    setReportFile(reportFile);
                  }}
                />

                <Grid
                  container
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  spacing={1}
                  sx={{ alignItems: "center" }}
                >
                  <Grid item xs={4} sm={4} md={2}>
                    <Button
                      color="primary"
                      sx={{ minWidth: "100px", width: "100%" }}
                      variant="contained"
                      onClick={async (event) => {
                        event.preventDefault();

                        if (reportFile) {
                          try {
                            let file = {
                              publicOrPrivate: "private",
                              fileFolder: "reports",
                              fileName: reportFile.name,
                              fileType: reportFile.type,
                              newFileName: `TRG_DOC_REP_${sanitizePatientName(
                                study ? study.patName : ""
                              )}`,
                            };

                            const response = await getSignedUrl(file);

                            if (response.status === 200) {
                              if (response.data && response.data.values) {
                                const uploadRespone = await putFileSignedUrl(
                                  response.data.values.url,
                                  reportFile,
                                  reportFile.type
                                );

                                if (uploadRespone.status === 200) {
                                  try {
                                    let report = {
                                      studyId: studyId,
                                      type: "FINAL",
                                      details: {
                                        fileName: response.data.values.fileName,
                                        fileType: reportFile.type,
                                        publicOrPrivate: "private",
                                      },
                                    };
                                    let reportResponse = await postReport(
                                      report
                                    );
                                    if (reportResponse.status === 200) {
                                      dispatch({
                                        type: "SET-SUCCESS",
                                        value: "Report Saved Successfully",
                                      });
                                      setReportFile(undefined);
                                      setRefetch(!refetch);
                                    } else {
                                      dispatch({
                                        type: "SET-ERROR",
                                        value: reportResponse.data.message,
                                      });
                                    }
                                  } catch (error: any) {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value:
                                        error?.response?.data?.message ||
                                        "Failed to Save Report",
                                    });
                                  }
                                }
                              }
                            }
                          } catch (error) {
                            dispatch({
                              type: "SET-ERROR",
                              value: "File Upload Error",
                            });
                          }
                        }
                      }}
                      disabled={!reportFile}
                    >
                      Publish
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ marginTop: "10px" }}>
                  {reports
                    .filter((report) => report.type === "FINAL")
                    .map((report, index) => {
                      return (
                        <div
                          key={report.id}
                          className={studyDetailStyles.report}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {`Final Report ${index + 1} [
                          ${format(
                            new Date(report.reportedDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}

                          <>
                            <div>
                              <IconButton
                                aria-label="Download File"
                                onClick={async (e) => {
                                  e.preventDefault();

                                  let fileToDownload = {
                                    publicOrPrivate:
                                      report.details.publicOrPrivate ||
                                      "private",
                                    fileName: report.details.fileName,
                                    fileType: report.details.fileType,
                                    type: "GET",
                                  };
                                  try {
                                    const response = await getSignedUrl(
                                      fileToDownload
                                    );
                                    if (response.status === 200) {
                                      if (
                                        response.data &&
                                        response.data.values &&
                                        response.data.values.url
                                      ) {
                                        window.open(
                                          response.data.values.url,
                                          "_blank"
                                        );
                                      } else {
                                        dispatch({
                                          type: "SET-ERROR",
                                          value: "File Download Error",
                                        });
                                      }
                                    } else {
                                      dispatch({
                                        type: "SET-ERROR",
                                        value: "File Download Error",
                                      });
                                    }
                                  } catch (error) {
                                    dispatch({
                                      type: "SET-ERROR",
                                      value: "File Download Error",
                                    });
                                  }
                                }}
                                style={{
                                  paddingLeft: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>

                              {report.status === "NEW" && (
                                <IconButton
                                  aria-label="delete"
                                  onClick={async () => {
                                    try {
                                      let data = {
                                        studyId: studyId,
                                        id: report.id,
                                      };
                                      let reportResponse = await deleteReport(
                                        data
                                      );
                                      if (reportResponse.status === 200) {
                                        dispatch({
                                          type: "SET-SUCCESS",
                                          value: "Report Deleted Successfully",
                                        });
                                        setRefetch(!refetch);
                                      } else {
                                        dispatch({
                                          type: "SET-ERROR",
                                          value: reportResponse.data.message,
                                        });
                                      }
                                    } catch (error: any) {
                                      dispatch({
                                        type: "SET-ERROR",
                                        value:
                                          error?.response?.data?.message ||
                                          "Failed to Delete Report",
                                      });
                                    }
                                  }}
                                  //disabled={isRecording}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          </>
                        </div>
                      );
                    })}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className={studyDetailStyles.main}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }
}

export default StudyDetail;
