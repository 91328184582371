import * as React from "react";
import { format } from "date-fns";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

import orderInfoStyles from "./OrderInfo.module.css";
import { IFile } from "../Components/FilesUpload";
import Files from "../Components/Files";

interface IOrder {
  clinicalInformation: string;
  previousSurgery: string;
  previousInjury: string;
  modality: string;
  priority: string;
  neededBefore: string;
  study: string[];
  files: IFile[];
  studyText?: string;
  referringDoctor?: string;
}

function Order({ order }: { order: IOrder }) {
  if (order) {
    return (
      <>
        <div className={orderInfoStyles.main}>
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            spacing={1}
            sx={{ marginBottom: "15px" }}
          >
            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>
                  Clinical Information
                </span>
                <span>{order.clinicalInformation}</span>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Previous Injury</span>
                <span>{order.previousInjury}</span>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Previous Surgery</span>
                <span>{order.previousSurgery}</span>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            spacing={1}
            sx={{ marginBottom: "15px" }}
          >
            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Modality</span>
                <span>{order.modality}</span>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Priority</span>
                <span>
                  {(order.priority || "NORMAL").toUpperCase() === "EMERGECY" ? (
                    <span style={{ color: "#F94892", fontWeight: "bold" }}>
                      Emergency
                    </span>
                  ) : (
                    <span>Normal</span>
                  )}
                </span>
              </div>
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Needed Before</span>
                <span>
                  {format(
                    new Date(order.neededBefore),
                    "eee, yyyy-MM-dd HH:mm"
                  )}
                </span>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            spacing={1}
            sx={{ marginBottom: "15px" }}
          >
            <Grid item xs={4} sm={8} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Study</span>
                <span>{order.studyText}</span>
              </div>
            </Grid>

            <Grid item xs={4} sm={8} md={4}>
              <div className={orderInfoStyles.detail}>
                <span className={orderInfoStyles.header}>Referring Doctor</span>
                <span>{order.referringDoctor}</span>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={orderInfoStyles.files}>
          <span className={orderInfoStyles.header}>Files</span>
          {order.files && <Files files={order.files} header="Files" />}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }
}

export default Order;
