import * as React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CSelectField from "../Components/CSelectField";
import DeleteIcon from "@mui/icons-material/Delete";

import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import CTextField from "../Components/CTextField";
import { UserContext } from "../ui/UserContext";
import paymentStyles from "./Payment.module.css";
import { getEntity, postEntityPayment, getModalityStudies } from "../api";
import { IOption } from "../types";

type PAYMENT = {
  modality: string;
  study: string;
  fees: string;
};

function Payment() {
  const { dispatch } = React.useContext(UserContext);
  let navigate = useNavigate();
  let [toEdit, setToEdit] = React.useState<boolean>(true);
  let [primaryEntities, setPrimaryEntities] = React.useState([]);
  let [studyEntities, setStudyEntities] = React.useState([]);
  let [throughEntities, setThroughEntities] = React.useState([]);
  let [radiologists, setRadiologists] = React.useState([]);
  let [fetched, setFetched] = React.useState<boolean>(false);

  let [studies, setStudies] = React.useState<{ [key: string]: IOption[] }>({
    CT: [],
    MR: [],
    CR: [],
  });

  const { handleSubmit, control, watch, setValue } = useForm<{
    primaryEntityId: string;
    secondaryEntityId: string;
    throughEntityId: string;
    radiologistId: string;
    payment: PAYMENT[];
  }>({
    defaultValues: {
      primaryEntityId: "",
      secondaryEntityId: "",
      throughEntityId: "",
      payment: [],
    },
  });

  const paymentFieldArray = useFieldArray({
    control,
    name: "payment",
  });

  const primaryEntityId = watch("primaryEntityId");
  const secondaryEntityId = watch("secondaryEntityId");
  const throughEntityId = watch("throughEntityId");
  const radiologistId = watch("radiologistId");

  React.useEffect(() => {
    const getData = async () => {
      let users = { type: "ENTITYS_LOV" };
      let response = await getEntity(users);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setPrimaryEntities(values);
        }
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let values = await Promise.all([
        getModalityStudies("CT"),
        getModalityStudies("MR"),
        getModalityStudies("CR"),
      ]);

      let ctStudies = values[0].data.values,
        ctResponse = values[0].status;
      let mrStudies = values[1].data.values,
        mrResponse = values[1].status;
      let crStudies = values[2].data.values,
        crResponse = values[2].status;
      if (ctResponse === 200 && mrResponse === 200 && crResponse === 200) {
        setStudies({ CT: ctStudies, MR: mrStudies, CR: crStudies });
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let entities = {
        type: "STUDY_ENTITYS_LOV",
        primaryEntityId: primaryEntityId,
      };
      let response = await getEntity(entities);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setStudyEntities(values);
        }
      }
    };
    if (primaryEntityId) {
      getData();
    }
  }, [primaryEntityId]);

  React.useEffect(() => {
    const getData = async () => {
      let entities = {
        type: "THROUGH_ENTITYS_LOV",
        primaryEntityId: primaryEntityId,
      };
      let response = await getEntity(entities);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setThroughEntities(values);
        }
      }
    };
    if (primaryEntityId) {
      getData();
    }
  }, [primaryEntityId]);

  React.useEffect(() => {
    const getData = async () => {
      let entities = {
        type: "ENTITY_RADIOLOGISTS_LOV",
        assignedToEntity: primaryEntityId,
      };
      let response = await getEntity(entities);
      if (response.status === 200) {
        let values = response.data.values;
        if (values) {
          setRadiologists(values);
        }
      }
    };
    if (primaryEntityId) {
      getData();
    }
  }, [primaryEntityId]);

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
      };
      let response = await postEntityPayment(payload);
      if (response.status === 200) {
        dispatch({ type: "SET-SUCCESS", value: "Payment Saved Successfully" });
        navigate("/dashboard");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save Payment",
      });
    }
  };

  return (
    <div className={paymentStyles.main}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={paymentStyles.order}
        noValidate
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 15 }} spacing={1}>
          <Grid item xs={4} sm={4} md={3}>
            <CSingleAutoCompleteField
              name={"primaryEntityId"}
              required
              control={control}
              label="Primary Entity"
              options={primaryEntities || []}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={3}>
            <CSingleAutoCompleteField
              name={`secondaryEntityId`}
              required
              control={control}
              label="Secondary Entity"
              options={[...studyEntities, { id: "ANY", label: "Any" }]}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={3}>
            <CSingleAutoCompleteField
              name={`throughEntityId`}
              required
              control={control}
              label="Through Entity"
              options={[...throughEntities, { id: "N/A", label: "N/A" }]}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={3}>
            <CSingleAutoCompleteField
              name={`radiologistId`}
              required
              control={control}
              label="Radiologist"
              options={[...radiologists, { id: "ANY", label: "Any" }]}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={3}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="primary"
                disabled={
                  !toEdit ||
                  !(
                    primaryEntityId &&
                    secondaryEntityId &&
                    throughEntityId &&
                    radiologistId
                  )
                }
                onClick={async () => {
                  let entities = {
                    type: "ENTITY_PAYMENT",
                    primaryEntityId: primaryEntityId,
                    secondaryEntityId: secondaryEntityId,
                    throughEntityId: throughEntityId,
                    radiologistId: radiologistId,
                  };
                  let response = await getEntity(entities);
                  if (response.status === 200) {
                    let values = response.data.values;
                    if (values) {
                      setValue("payment", values);
                    }
                    setFetched(true);
                  }
                }}
              >
                Fetch
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginTop: "20px" }}
          spacing={1}
        >
          <Grid item xs={4} sm={8} md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Payment</Typography>
              <IconButton
                aria-label="delete"
                disabled={toEdit}
                onClick={() => {
                  paymentFieldArray.append({
                    modality: "",
                    study: "",
                    fees: "",
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Grid>

          {paymentFieldArray.fields.map((field, index) => {
            const modality = watch(`payment.${index}.modality`);

            return (
              <Grid
                key={field.id}
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                spacing={1}
              >
                <Grid item xs={4} sm={4} md={3}>
                  <CSelectField
                    name={`payment.${index}.modality`}
                    label="Modality"
                    required
                    disabled={toEdit}
                    control={control}
                    options={[
                      { id: "CT", label: "CT" },
                      { id: "MR", label: "MR" },
                      { id: "CR", label: "X-Ray" },
                    ]}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                  <CSingleAutoCompleteField
                    name={`payment.${index}.study`}
                    required
                    disabled={toEdit}
                    control={control}
                    label="Study"
                    options={
                      modality
                        ? [...studies[modality], { id: "ANY", label: "Any" }]
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                  <CTextField
                    name={`payment.${index}.fees`}
                    required
                    type="number"
                    disabled={toEdit}
                    control={control}
                    label="Fees"
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton
                    aria-label="delete"
                    disabled={toEdit}
                    onClick={() => {
                      paymentFieldArray.remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginTop: "20px" }}
          spacing={1}
        >
          <Grid item xs={2} sm={2} md={2}>
            {toEdit ? (
              <Button
                color="primary"
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                disabled={!fetched}
                onClick={(event) => {
                  event.preventDefault();
                  setToEdit(false);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="success"
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            )}
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{ minWidth: "100px", width: "100%" }}
              variant="contained"
              color="warning"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default Payment;
