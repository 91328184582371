import * as React from "react";
import { Link, useMatch } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Tooltip from "@mui/material/Tooltip";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import Logo from "./Logo";
import { hasRole } from "../utilities";

import sidebarStyles from "./Sidebar.module.css";

function Sidebar() {
  let users = useMatch({ path: "/users", end: true });
  let entitys = useMatch({ path: "/entitys", end: true });
  let refEntitys = useMatch({ path: "/refentitys", end: true });
  let transactions = useMatch({ path: "/transactions", end: true });

  return (
    <div className={sidebarStyles.sidebar}>
      <div className={sidebarStyles.logo}>
        <Link to="/dashboard">
          <Logo />
        </Link>
      </div>
      <div className={sidebarStyles.actions}>
        <Tooltip title="Dashboard" placement="right">
          <Link to="/dashboard" className={sidebarStyles.action}>
            <DashboardIcon
              sx={{
                color: useMatch({ path: "/dashboard", end: true })
                  ? "#00EAD3"
                  : "#fff",
              }}
            />
          </Link>
        </Tooltip>

        <Tooltip title="Reports" placement="right">
          <Link to="/reports" className={sidebarStyles.action}>
            <AssignmentTurnedInIcon
              sx={{
                color: useMatch({ path: "/reports", end: true })
                  ? "#00EAD3"
                  : "#fff",
              }}
            />
          </Link>
        </Tooltip>

        <Tooltip title="Availability" placement="right">
          <Link to="/availability" className={sidebarStyles.action}>
            <AccessTimeIcon
              sx={{
                color: useMatch({ path: "/availability", end: true })
                  ? "#00EAD3"
                  : "#fff",
              }}
            />
          </Link>
        </Tooltip>

        {hasRole("SUPERADMIN") && (
          <>
            <Tooltip title="Entities" placement="right">
              <Link to="/entitys" className={sidebarStyles.action}>
                <LocalHospitalIcon
                  sx={{
                    color: entitys ? "#00EAD3" : "#fff",
                  }}
                />
              </Link>
            </Tooltip>

            <Tooltip title="Users" placement="right">
              <Link to="/users" className={sidebarStyles.action}>
                <PersonIcon
                  sx={{
                    color: users ? "#00EAD3" : "#fff",
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )}

        {(hasRole("ADMIN") || hasRole("OPERATOR") || hasRole("SUPERADMIN")) && (
          <>
            <Tooltip title="Referring Entities" placement="right">
              <Link to="/refentitys" className={sidebarStyles.action}>
                <PersonAddIcon
                  sx={{
                    color: refEntitys ? "#00EAD3" : "#fff",
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )}

        {(hasRole("ADMIN") || hasRole("OPERATOR") || hasRole("RADIOLOGIST") || hasRole("SUPERADMIN")) && (
          <>
            <Tooltip title="Transactions" placement="right">
              <Link to="/transactions" className={sidebarStyles.action}>
                <CurrencyRupeeIcon
                  sx={{
                    color: transactions ? "#00EAD3" : "#fff",
                  }}
                />
              </Link>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
