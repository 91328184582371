import * as React from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import { getStudy, postAction } from "../api";
import { UserContext } from "../ui/UserContext";

interface IAssign {
  studyId: string;
  assignedToEntity?: string;
  assignedToUser?: string;
  handleDialogClose: () => void;
  showDialog: boolean;
}

function Assign(props: IAssign) {
  const [entities, setEntities] = React.useState<
    { id: string; label: string }[]
  >([]);
  const [entityUsers, setEntityUsers] = React.useState<
    { id: string; label: string }[]
  >([]);

  const [toEdit, setToEdit] = React.useState(!!props.assignedToEntity);
  const { dispatch } = React.useContext(UserContext);

  const { handleSubmit, control, setValue, watch } = useForm({});

  const assignedToEntity = watch("assignedToEntity");

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "RELATED_ENTITY_LOV", studyId: props.studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setEntities(response.data.values);
        setValue("assignedToEntity", props.assignedToEntity);
        if (props.assignedToEntity) {
          let userResponse = await getStudy({
            type: "ENTITY_RADIOLOGISTS_LOV",
            assignedToEntity: props.assignedToEntity,
            assignedToUser: props.assignedToUser,
            studyId: props.studyId,
          });

          if (userResponse.status === 200) {
            setEntityUsers(userResponse.data.values);
            setValue("assignedToUser", props.assignedToUser);
          }
        }
      }
    };

    getData();
  }, [props.assignedToEntity, props.assignedToUser, setValue, props.studyId]);

  React.useEffect(() => {
    setEntityUsers([]);
    setValue("assignedToUser", "");

    const getData = async () => {
      let study = {
        type: "ENTITY_RADIOLOGISTS_LOV",
        assignedToEntity: assignedToEntity,
        assignedToUser: "",
        studyId: props.studyId,
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        setEntityUsers(response.data.values);
      }
    };

    if (assignedToEntity) {
      getData();
    }
  }, [assignedToEntity, setValue, props.studyId]);

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        action: "ASSIGN",
        studyId: props.studyId,
      };
      let response = await postAction(payload);
      if (response.status === 200) {
        dispatch({ type: "SET-SUCCESS", value: "Study Assigned Successfully" });
        dispatch({
          type: "TOGGLE-PENDING-REFETCH",
        });
        props.handleDialogClose();
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error) {
      dispatch({ type: "SET-ERROR", value: "Failed to Assign Study" });
    }
  };

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={props.handleDialogClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Assign</DialogTitle>
        <DialogContent>
          <CSingleAutoCompleteField
            name="assignedToEntity"
            required
            disabled={toEdit}
            control={control}
            label="Entity"
            autoFocus={false}
            options={entities}
          />

          <CSingleAutoCompleteField
            name="assignedToUser"
            disabled={toEdit}
            control={control}
            label="User"
            autoFocus={false}
            options={entityUsers}
          />
        </DialogContent>

        <DialogActions sx={{ paddingRight: "24px" }}>
          {toEdit ? (
            <Button
              color="warning"
              sx={{ minWidth: "100px" }}
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                setToEdit(false);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="primary"
              sx={{ minWidth: "100px" }}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          )}

          <Button sx={{ minWidth: "100px" }} onClick={props.handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Assign;
