import * as React from "react";
import { useForm, useFieldArray, NestedValue } from "react-hook-form";
import Button from "@mui/material/Button";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import CTextField from "../Components/CTextField";
import CSelectField from "../Components/CSelectField";
import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import { UserContext } from "../ui/UserContext";

import { getStudy, postReferral } from "../api";

import userStyles from "./RefEntityCVM.module.css";

export type IUser = {
  id?: string;
  entityId: string;
  password: string;
  userName: string;
  email: string;
  mobile: string;
  designation?: string;
  status: string;
  ae?: string;
};

function RefEntityCVM() {
  const { dispatch } = React.useContext(UserContext);
  let { id } = useParams();
  let navigate = useNavigate();
  let [entities, setEntities] = React.useState<{ id: string; label: string }[]>(
    []
  );

  const { handleSubmit, control, setValue } = useForm<IUser>({
    defaultValues: {
      entityId: "",
      userName: "",
      password: "",
      email: "",
      mobile: "",
      designation: "",
      status: "ACTIVE",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        userId: data.email,
      };
      let response = await postReferral(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "User Saved Successfully",
        });
        navigate("/refentitys");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save User",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "ENTITYS_LOV", id: id };
      let response = await getStudy(study);
      if (response.status === 200) {
        let values = response.data.values;
        setEntities(values);
        if (values.length > 0) {
          setValue("entityId", values[0].id);
        }
      }
    };
    getData();
  }, []);

  return (
    <div className={userStyles.main}>
      <div style={{ display: "flex" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={userStyles.user}
          noValidate
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CSingleAutoCompleteField
                name="entityId"
                required
                control={control}
                label="Entity"
                options={entities}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="email"
                label="Email"
                required
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="userName"
                label="Name"
                required
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="password"
                label="Password"
                type="password"
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="mobile"
                label="Mobile"
                required
                control={control}
              />
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={2} sm={2} md={2}>
              <Button
                color="success"
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                color="warning"
                onClick={() => {
                  navigate("/refentitys");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default RefEntityCVM;
