import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";

import { IFile } from "./FilesUpload";
import { getSignedUrl } from "../api";
import { formImagePath } from "../utilities";
import { UserContext } from "../ui/UserContext";

interface IFiles {
  files: IFile[];
  includeHeader?: boolean;
  header?: string;
}

function Files(props: IFiles) {
  const { dispatch } = React.useContext(UserContext);

  if (props.files && props.files.length > 0) {
    return (
      <>
        {props.includeHeader && (
          <Typography variant="h6">
            {props.header ? props.header : "Files"}
          </Typography>
        )}

        {props.files.map((file, index) => {
          return (
            <div /* style={{ marginTop: "5px" }} */ key={file.fileName}>
              {file.publicOrPrivate.toUpperCase() === "PUBLIC" ? (
                <a
                  href={formImagePath(file.fileName)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`Attachment ${index + 1} [ ${file.fileType} ]`}
                </a>
              ) : (
                <>
                  <span
                    style={{
                      marginRight: "10px",
                      borderLeft: "2px solid #151b26",
                      paddingLeft: "5px",
                      fontSize: "0.85rem",
                    }}
                  >{`Attachment ${index + 1} [ ${file.fileType} ]`}</span>

                  <IconButton
                    aria-label="Download File"
                    onClick={async (e) => {
                      e.preventDefault();

                      let fileToDownload = {
                        publicOrPrivate: file.publicOrPrivate || "private",
                        fileName: file.fileName,
                        fileType: file.fileType,
                        type: "GET",
                      };
                      try {
                        const response = await getSignedUrl(fileToDownload);
                        if (response.status === 200) {
                          if (
                            response.data &&
                            response.data.values &&
                            response.data.values.url
                          ) {
                            window.open(response.data.values.url, "_blank");
                          } else {
                            dispatch({
                              type: "SET-ERROR",
                              message: "File Download Error",
                            });
                          }
                        } else {
                          dispatch({
                            type: "SET-ERROR",
                            message: "File Download Error",
                          });
                        }
                      } catch (error) {
                        dispatch({
                          type: "SET-ERROR",
                          message: "File Download Error",
                        });
                      }
                    }}
                    style={{ paddingLeft: "7px", cursor: "pointer" }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </>
              )}
            </div>
          );
        })}
      </>
    );
  } else {
    return <> </>;
  }
}

export default Files;
