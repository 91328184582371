import * as React from "react";
import Typography from "@mui/material/Typography";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

import { UserContext } from "./UserContext";
import Study, { IStudy } from "../Study/Study";
import { formatDate, escapeRegExp } from "../utilities";
import { getStudy } from "../api";

import allStudiesStyles from "./AllStudies.module.css";

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function getIsMatch(filter: string) {
  const isMatch = (study: IStudy) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return (
      re.test(study.patName) ||
      re.test(study.patId) ||
      re.test(study.name) ||
      // re.test(getStudyInfo(result.study_status).status) ||
      re.test(study.modsInStudy)
    );
  };
  return isMatch;
}

function AllStudies() {
  const { state, dispatch } = React.useContext(UserContext);
  const debouncedFilter = useDebounce<string>(state.filter, 500);

  React.useEffect(() => {
    const getData = async () => {
      dispatch({
        type: "SET-PAGINATED-STUDIES",
        value: "",
      });

      let study = {
        fromDate: formatDate(state.fromDate),
        toDate: formatDate(state.toDate),
        type: state.type,
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        let studies = response.data.values || [];
        dispatch({
          type: "SET-STUDIES",
          value: studies,
        });

        let filteredStudies = studies.filter(getIsMatch(state.filter));
        dispatch({
          type: "SET-FILTERED-STUDIES",
          value: filteredStudies,
        });

        dispatch({
          type: "SET-TOTAL-PAGES",
          value: Math.ceil(filteredStudies.length / 5),
        });

        let paginatedStudies = filteredStudies.slice(0, 5);

        dispatch({
          type: "SET-PAGINATED-STUDIES",
          value: paginatedStudies,
        });
      }
    };

    getData();
    const allStudiesTimer = setInterval(() => getData(), 300000);
    return () => clearInterval(allStudiesTimer);
  }, [state.fromDate, state.toDate, state.type]);

  React.useEffect(() => {
    let filteredStudies = state.studies.filter(getIsMatch(debouncedFilter));
    dispatch({
      type: "SET-FILTERED-STUDIES",
      value: filteredStudies,
    });

    dispatch({
      type: "SET-TOTAL-PAGES",
      value: Math.ceil(filteredStudies.length / 5),
    });

    let paginatedStudies = filteredStudies.slice(0, 5);

    dispatch({
      type: "SET-PAGINATED-STUDIES",
      value: paginatedStudies,
    });
  }, [state.studies, debouncedFilter]);

  return (
    <div
      className={allStudiesStyles.main}
      style={
        state.showAll
          ? {
              padding: "10px 0px",
            }
          : {
              padding: "10px 0px",
              backgroundColor: "#fff",
              boxShadow:
                "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px",
            }
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          dispatch({ type: "SET-SHOW-ALL", value: !state.showAll });
        }}
      >
        <IconButton aria-label={"All Studies"}>
          {state.showAll ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Typography variant="h5">All</Typography>
      </div>

      {state.showAll ? (
        <>
          <div className={allStudiesStyles.filter}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
                <Grid item xs={4} sm={2} md={3}>
                  <DatePicker
                    label="From"
                    value={state.fromDate}
                    mask="____-__-__"
                    inputFormat="yyyy-MM-dd"
                    onChange={(newValue) => {
                      if (newValue) {
                        dispatch({ type: "SET-FROM-DATE", value: newValue });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        margin="dense"
                        size="small"
                        {...params}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={3}>
                  <DatePicker
                    label="To"
                    value={state.toDate}
                    mask="____-__-__"
                    inputFormat="yyyy-MM-dd"
                    onChange={(newValue) => {
                      if (newValue) {
                        dispatch({ type: "SET-TO-DATE", value: newValue });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        margin="dense"
                        size="small"
                        {...params}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={3}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <InputLabel id="study-type">Type</InputLabel>
                    <Select
                      labelId="study-type"
                      id="type"
                      value={state.type}
                      label="Studies"
                      onChange={(event) => {
                        dispatch({
                          type: "SET-TYPE",
                          value: event.target.value,
                        });
                      }}
                    >
                      <MenuItem value={"ALL"}>All</MenuItem>
                      <MenuItem value={"PENDING"}>Pending</MenuItem>
                      <MenuItem value={"DRAFT"}>Draft</MenuItem>
                      <MenuItem value={"NOT_NEEDED"}>Not Needed</MenuItem>
                      <MenuItem value={"COMPLEMENTARY"}>Complementary</MenuItem>
                      <MenuItem value={"REWORK"}>Rework</MenuItem>
                      <MenuItem value={"REPORTED"}>Reported</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2} md={3}>
                  <TextField
                    label="Filter"
                    margin="dense"
                    size="small"
                    value={state.filter}
                    onChange={(event) => {
                      dispatch({
                        type: "SET-FILTER",
                        value: event.target.value,
                      });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>

          {state.paginatedStudies ? (
            state.paginatedStudies.length > 0 ? (
              <>
                {state.paginatedStudies.map((study: IStudy) => {
                  return <Study key={study.pk} study={study} />;
                })}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Pagination
                    count={state.totalPages}
                    onChange={(event, page) => {
                      dispatch({
                        type: "SET-CURRENT-PAGE",
                        value: page,
                      });

                      let paginatedStudies = state.filteredStudies.slice(
                        (page - 1) * 5,
                        page * 5
                      );

                      dispatch({
                        type: "SET-PAGINATED-STUDIES",
                        value: paginatedStudies,
                      });
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={allStudiesStyles.nostudies}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  No Studies
                </Typography>
              </div>
            )
          ) : (
            <div style={{ margin: "10px 0px" }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          )}
          {/* 
          {state.paginatedStudies && state.paginatedStudies.length > 0 ? (
            <>
              {state.paginatedStudies.map((study: IStudy) => {
                return <Study key={study.pk} study={study} />;
              })}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  count={state.totalPages}
                  onChange={(event, page) => {
                    dispatch({
                      type: "SET-CURRENT-PAGE",
                      value: page,
                    });

                    let paginatedStudies = state.filteredStudies.slice(
                      (page - 1) * 5,
                      page * 5
                    );

                    dispatch({
                      type: "SET-PAGINATED-STUDIES",
                      value: paginatedStudies,
                    });
                  }}
                />
              </div>
            </>
          ) : (
            <div className={allStudiesStyles.nostudies}>
              <Typography variant="h6">No Studies</Typography>
            </div>
          )}

           */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AllStudies;
