import * as React from "react";
import { useForm, useFieldArray, NestedValue } from "react-hook-form";
import Button from "@mui/material/Button";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import CTextField from "../Components/CTextField";
import CSelectField from "../Components/CSelectField";
import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import CDateTimeField from "../Components/CDateTimeField";
import { UserContext } from "../ui/UserContext";

import { getUser, postUser } from "../api";

import userStyles from "./UserCVM.module.css";

export type IUser = {
  id?: string;
  userId: string;
  password: string;
  userName: string;
  email: string;
  mobile: string;
  designation?: string;
  status: string;
  ae?: string;
};

function UserCVM() {
  const { dispatch } = React.useContext(UserContext);
  let { id } = useParams();
  let navigate = useNavigate();
  let [reFetch, setReFetch] = React.useState<boolean>(false);
  let [toEdit, setToEdit] = React.useState<boolean>(false);

  const { handleSubmit, control, reset } = useForm<IUser>({
    defaultValues: {
      userId: "",
      userName: "",
      password: "",
      email: "",
      mobile: "",
      designation: "",
      status: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
      };
      let response = await postUser(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "User Saved Successfully",
        });
        navigate("/users");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save User",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "USER", id: id };
      let response = await getUser(study);
      if (response.status === 200) {
        let user = response.data.values;
        if (user) {
          setToEdit(true);
          reset(user);
        }
        setReFetch(true);
      }
    };
    if (id) {
      getData();
    }
  }, [id, reFetch, reset]);

  return (
    <div className={userStyles.main}>
      <div style={{ display: "flex" }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={userStyles.user}
          noValidate
        >
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="userId"
                label="Id"
                required
                disabled={toEdit}
                control={control}
                autoFocus
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="userName"
                label="Name"
                required
                disabled={toEdit}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="password"
                label="Password"
                type="password"
                disabled={toEdit}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="email"
                label="Email"
                required
                disabled={toEdit}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="mobile"
                label="Mobile"
                required
                disabled={toEdit}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <CSelectField
                name="status"
                label="Status"
                required
                disabled={toEdit}
                control={control}
                options={[
                  { id: "ACTIVE", label: "Active" },
                  { id: "CLOSED", label: "Closed" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="ae"
                label="AE"
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <CTextField
                name="designation"
                label="Designation"
                disabled={toEdit}
                control={control}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ marginTop: "20px" }}
            spacing={1}
          >
            <Grid item xs={2} sm={2} md={2}>
              {toEdit ? (
                <Button
                  color="primary"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  onClick={(event) => {
                    event.preventDefault();
                    setToEdit(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  color="success"
                  sx={{ minWidth: "100px", width: "100%" }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              )}
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{ minWidth: "100px", width: "100%" }}
                variant="contained"
                color="warning"
                onClick={() => {
                  navigate("/users");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default UserCVM;
