import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AlarmAddOutlinedIcon from "@mui/icons-material/AlarmAddOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import Badge from "@mui/material/Badge";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import KeyIcon from "@mui/icons-material/Key";

import { postAction } from "../api";
import Assign from "./Assign";
import { IStudy } from "./Study";
import { UserContext } from "../ui/UserContext";
import Message from "./Message";
import Rework from "./Rework";
import Download from "./Download";
import Publish from "./Publish";
import Misc from "./Misc";
import Access from "./Access";

interface IActions {
  actions: {
    name: string;
    title: string;
    link?: string;
  }[];
  study: IStudy;
  from: string;
}

function ActionDialog(props: {
  title: string;
  text: string;
  action: string;
  studyId: string;
  showDialog: boolean;
  handleDialogClose: any;
}) {
  const { dispatch } = React.useContext(UserContext);

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={props.handleDialogClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          color="primary"
          sx={{ minWidth: "75px" }}
          variant="contained"
          onClick={async () => {
            try {
              let payload = {
                action: props.action,
                studyId: props.studyId,
              };
              let response = await postAction(payload);
              if (response.status === 200) {
                dispatch({
                  type: "SET-SUCCESS",
                  value: "Action updated Successfully",
                });
                dispatch({
                  type: "TOGGLE-PENDING-REFETCH",
                });
                props.handleDialogClose();
              } else {
                dispatch({ type: "SET-ERROR", value: response.data.message });
              }
            } catch (error) {
              dispatch({ type: "SET-ERROR", value: "Failed to Save Action" });
            }
          }}
        >
          Yes
        </Button>
        <Button sx={{ minWidth: "75px" }} onClick={props.handleDialogClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Actions({ actions, study, from }: IActions) {
  let navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState("");

  const handleDialogClose = () => {
    setShowDialog("");
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {actions.map((action) => {
        if (action.name === "order") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    if (action.link) {
                      navigate(action.link, { state: { from: from } });
                    }
                  }}
                >
                  <ShoppingCartOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "assign") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("ASSIGN");
                  }}
                >
                  <AssignmentIndOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "escalate") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    // navigate(action.link);
                    setShowDialog("ESCALATE");
                  }}
                >
                  <AlarmAddOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "acknowledge") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    // navigate(action.link);
                    setShowDialog("ACKNOWLEDGE");
                  }}
                >
                  <ThumbUpAltOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "message") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    // navigate(action.link);
                    setShowDialog("MESSAGE");
                  }}
                >
                  <Badge badgeContent={study.newMessageCount} color="primary">
                    <MessageOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "report") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    if (action.link) {
                      navigate(action.link, { state: { from: from } });
                    }
                  }}
                >
                  <AssignmentTurnedInIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "rework") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("REWORK");
                  }}
                >
                  <AssignmentLateOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "misc") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("MISC");
                  }}
                >
                  <MiscellaneousServicesOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "download") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("DOWNLOAD");
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "publish") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("PUBLISH");
                  }}
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "access") {
          return (
            <div key={action.name}>
              <Tooltip key={action.name} title={action.title} placement="top">
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    setShowDialog("ACCESS");
                  }}
                >
                  <KeyIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (action.name === "oviyam") {
          return (
            <div key={action.name}>
              <a
                className="ui basic button"
                target="_blank"
                rel="noreferrer"
                href={action.link}
              >
                <Tooltip key={action.name} title={action.title} placement="top">
                  <IconButton aria-label={action.title}>
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          );
        } else if (action.name === "ioviyam") {
          return (
            <div key={action.name}>
              <a
                className="ui basic button"
                target="_blank"
                rel="noreferrer"
                href={action.link}
              >
                <Tooltip key={action.name} title={action.title} placement="top">
                  <IconButton aria-label={action.title}>
                    <MobileFriendlyIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          );
        } else if (action.name === "weasis") {
          return (
            <div key={action.name}>
              <a
                className="ui basic button"
                target="_blank"
                rel="noreferrer"
                href={action.link}
              >
                <Tooltip key={action.name} title={action.title} placement="top">
                  <IconButton aria-label={action.title}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          );
        } else if (action.name === "weasisold") {
          return (
            <div key={action.name}>
              <a
                className="ui basic button"
                target="_blank"
                rel="noreferrer"
                href={action.link}
              >
                <Tooltip key={action.name} title={action.title} placement="top">
                  <IconButton aria-label={action.title}>
                    <ViewCozyIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          );
        } else if (action.name === "share") {
          return (
            <div key={action.name}>
              <a
                className="ui basic button"
                target="_blank"
                rel="noreferrer"
                href={`whatsapp://send?text=${window.location.origin}${action.link}`}
                data-action="share/whatsapp/share"
              >
                <Tooltip key={action.name} title={action.title} placement="top">
                  <IconButton aria-label={action.title}>
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>
              </a>
            </div>
          );
        } else {
          console.log("here", action);
          return <></>;
        }
      })}

      <ActionDialog
        showDialog={showDialog === "ESCALATE" || showDialog === "ACKNOWLEDGE"}
        title={showDialog === "ESCALATE" ? "Escalate" : "Acknowledge"}
        text={
          showDialog === "ESCALATE"
            ? "Escalate for immediate attention?"
            : "Acknowledge study"
        }
        action={showDialog}
        studyId={study.pk}
        handleDialogClose={handleDialogClose}
      />
      {showDialog === "ASSIGN" && (
        <Assign
          showDialog={showDialog === "ASSIGN"}
          studyId={study.pk}
          assignedToEntity={study.assignedToEntity}
          assignedToUser={study.assignedToUser}
          handleDialogClose={handleDialogClose}
        />
      )}
      {showDialog === "MESSAGE" && (
        <Message
          showDialog={showDialog === "MESSAGE"}
          studyId={study.pk}
          handleDialogClose={handleDialogClose}
        />
      )}

      {showDialog === "REWORK" && (
        <Rework
          showDialog={showDialog === "REWORK"}
          studyId={study.pk}
          handleDialogClose={handleDialogClose}
        />
      )}

      {showDialog === "DOWNLOAD" && (
        <Download
          showDialog={showDialog === "DOWNLOAD"}
          studyId={study.pk}
          entityId={study.entityId}
          handleDialogClose={handleDialogClose}
        />
      )}

      {showDialog === "PUBLISH" && (
        <Publish
          showDialog={showDialog === "PUBLISH"}
          studyId={study.pk}
          entityId={study.entityId}
          handleDialogClose={handleDialogClose}
        />
      )}
      {showDialog === "MISC" && (
        <Misc
          showDialog={showDialog === "MISC"}
          studyId={study.pk}
          handleDialogClose={handleDialogClose}
        />
      )}
      {showDialog === "ACCESS" && (
        <Access
          showDialog={showDialog === "ACCESS"}
          studyId={study.pk}
          handleDialogClose={handleDialogClose}
        />
      )}
    </div>
  );
}

export default Actions;
