import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

import { escapeRegExp } from "../utilities";
import { getStudy } from "../api";
import templatesStyles from "./Templates.module.css";

interface ITemplate {
  id: number;
  name: string;
}

function getIsMatch(filter: string) {
  const isMatch = (template: ITemplate) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return re.test(template.name);
  };
  return isMatch;
}

function Templates() {
  let navigate = useNavigate();
  let [filter, setFilter] = React.useState("");
  let [templates, setTemplates] = React.useState<ITemplate[]>([]);
  let [filteredTemplates, setFilteredTemplates] = React.useState<ITemplate[]>(
    []
  );

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "TEMPLATES" };
      let response = await getStudy(study);
      if (response.status === 200) {
        let values = response.data.values;
        setTemplates(values);
        let filteredValues = values.filter(getIsMatch(filter));
        setFilteredTemplates(filteredValues);
      }
    };
    getData();
  }, []);

  return (
    <div className={templatesStyles.main}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter"
          margin="dense"
          size="small"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            let filteredValues = templates.filter(
              getIsMatch(event.target.value)
            );
            setFilteredTemplates(filteredValues);
          }}
          sx={{ width: 200, marginRight: 1 }}
        />

        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          onClick={() => {
            navigate(`/template/new`);
          }}
        >
          New
        </Button>
      </div>

      <div className={templatesStyles.templates}>
        {filteredTemplates.map((template) => {
          return (
            <Link
              to={`/template/${template.id}/edit`}
              className={templatesStyles.template}
              key={template.id}
            >
              {template.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default Templates;
