import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

import { escapeRegExp } from "../utilities";
import { getUser } from "../api";
import usersStyles from "./Users.module.css";

interface IUser {
  id: number;
  userName: string;
  email: string;
  ae?: string;
}

function getIsMatch(filter: string) {
  const isMatch = (user: IUser) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return re.test(user.userName) || re.test(user.email);
  };
  return isMatch;
}

function Users() {
  let navigate = useNavigate();
  let [filter, setFilter] = React.useState("");
  let [users, setUsers] = React.useState<IUser[]>([]);
  let [filteredUsers, setFilteredUsers] = React.useState<IUser[]>([]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "USERS" };
      let response = await getUser(study);
      if (response.status === 200) {
        let values = response.data.values;
        setUsers(values);
        let filteredValues = values.filter(getIsMatch(filter));
        setFilteredUsers(filteredValues);
      }
    };
    getData();
  }, []);

  return (
    <div className={usersStyles.main}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TextField
          label="Filter"
          margin="dense"
          size="small"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            let filteredValues = users.filter(getIsMatch(event.target.value));
            setFilteredUsers(filteredValues);
          }}
          sx={{ width: 200, marginRight: 1 }}
        />

        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          onClick={() => {
            navigate(`/user/new`);
          }}
        >
          New
        </Button>
      </div>

      <div className={usersStyles.users}>
        {filteredUsers.map((user) => {
          return (
            <div className={usersStyles.user} key={user.id}>
              <Link to={`/user/${user.id}/edit`} className={usersStyles.link}>
                <span className={usersStyles.header}>{user.userName}</span>
              </Link>
              <div className={usersStyles.detail}>{`${user.email}${
                user.ae ? ` / ${user.ae}` : ""
              }`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Users;
