import * as React from "react";
import { useForm, NestedValue } from "react-hook-form";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { UserContext } from "../ui/UserContext";
import CTextField from "../Components/CTextField";
import { postTemplate, getStudy, getSignedUrl, putFileSignedUrl } from "../api";
import FileUpload from "../Components/FileUpload";
import Files from "../Components/Files";
import { IFile } from "../Components/FileUpload";
import templateCMStyles from "./TemplateCM.module.css";

function TemplateCM() {
  const { dispatch } = React.useContext(UserContext);
  let [templateFile, setTemplateFile] = React.useState<File | undefined>();
  let [toEdit, setToEdit] = React.useState<boolean>(false);
  let { id } = useParams();
  let navigate = useNavigate();

  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      name: "",
      details: {
        file: {
          fileName: "",
        },
      },
    },
  });

  const onSubmit = async (data: any) => {
    try {
      if (templateFile) {
        try {
          let file = {
            publicOrPrivate: "public",
            fileFolder: "templates",
            fileName: templateFile.name,
            fileType: templateFile.type,
          };

          const response = await getSignedUrl(file);

          if (response.status === 200) {
            if (response.data && response.data.values) {
              const uploadRespone = await putFileSignedUrl(
                response.data.values.url,
                templateFile,
                templateFile.type
              );

              if (uploadRespone.status === 200) {
                try {
                  let payload = {
                    ...data,
                    details: {
                      file: {
                        fileName: response.data.values.fileName,
                        fileType: templateFile.type,
                        publicOrPrivate: "public",
                      },
                    },
                  };
                  let postResponse = await postTemplate(payload);
                  if (postResponse.status === 200) {
                    dispatch({
                      type: "SET-SUCCESS",
                      value: "Template Saved Successfully",
                    });
                    navigate(`/templates`);
                  } else {
                    dispatch({
                      type: "SET-ERROR",
                      value: postResponse.data.message,
                    });
                  }
                } catch (error: any) {
                  dispatch({
                    type: "SET-ERROR",
                    value:
                      error?.response?.data?.message ||
                      "Failed to Save Template",
                  });
                }
              }
            }
          }
        } catch (error) {
          dispatch({
            type: "SET-ERROR",
            message: "Template Upload Error",
          });
        }
      } else {
        dispatch({
          type: "SET-ERROR",
          value: "File is Mandatory",
        });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Save Template",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let template = { type: "TEMPLATE", id: id };
      let response = await getStudy(template);
      if (response.status === 200) {
        let value = response.data.values;
        if (value) {
          setToEdit(true);
          reset(value);
        }
      }
    };
    if (id) {
      getData();
    }
  }, [id, reset]);

  let details = watch("details");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={templateCMStyles.main}
      noValidate
    >
      <div>
        <CTextField
          name="name"
          label="Name"
          required
          disabled={toEdit}
          control={control}
          autoFocus
          fullWidth
        />
        {!toEdit && (
          <FileUpload
            id={"templatefile"}
            publicOrPrivate="public"
            fileFolder="templates"
            filesToAccept="image/doc,image/docx"
            fileName={templateFile ? templateFile.name : ""}
            updateFile={(file: File | undefined) => {
              setTemplateFile(file);
            }}
          />
        )}

        {details && details.file && details.file.fileName && (
          <div style={{ marginTop: "20px" }}>
            <Files
              files={[
                {
                  ...details.file,
                } as IFile,
              ]}
              header="File"
              includeHeader
            />
          </div>
        )}
      </div>
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ marginTop: "20px" }}
        spacing={1}
      >
        <Grid item xs={2} sm={2} md={2}>
          {toEdit ? (
            <Button
              color="primary"
              sx={{ minWidth: "100px", width: "100%" }}
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                setToEdit(false);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="success"
              sx={{ minWidth: "100px", width: "100%" }}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{ minWidth: "100px", width: "100%" }}
            variant="contained"
            color="warning"
            onClick={() => {
              navigate("/templates");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default TemplateCM;
