import * as React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./ui/LandingPage";
import Dashboard from "./ui/Dashboard";
import AppContainer from "./ui/AppContainer";
import Reports from "./ui/Reports";
import StudyDetail from "./Study/StudyDetail";
import Order from "./Study/Order";
import { UserContext } from "./ui/UserContext";
import Report from "./Study/Report";

import "./App.css";
import Templates from "./Templates/Templates";
import TemplateCM from "./Templates/TemplateCM";
import Preferences from "./Preferences/Preferences";
import EntityCVM from "./Entity/EntityCVM";
import Entitys from "./Entity/Entitys";
import UserCVM from "./User/UserCVM";
import Users from "./User/Users";
import RefEntityCVM from "./RefEntity/RefEntityCVM";
import RefEntitys from "./RefEntity/RefEntitys";
import Pricing from "./Pricing/PricingCVM";
import Payment from "./Payment/PaymentCVM";
import AssignList from "./AssignList/AssignListCVM";
import Transactions from "./Account/Transactions";
import Availability from "./Availability/Availability";
import Signout from "./ui/Signout";
import Share from "./Share/Share";
import PaymentTerms from "./ui/PaymentTerms";
import AboutUs from "./ui/AboutUs";

const initialState = {
  successMessage: "",
  errorMessage: "",
  fromDate: new Date(),
  toDate: new Date(),
  type: "DRAFT",
  filter: "",
  studies: [],
  pendingStudies: "",
  showAll: false,
  filteredStudies: "",
  paginatedStudies: [],
  totalPages: 1,
  currentPage: 1,
  pendingRefetch: false,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, errorMessage: action.value };
    case "SET-FROM-DATE":
      return { ...state, fromDate: action.value };
    case "SET-TO-DATE":
      return { ...state, toDate: action.value };
    case "SET-TYPE":
      return { ...state, type: action.value };
    case "SET-FILTER":
      return { ...state, filter: action.value };
    case "SET-STUDIES":
      return { ...state, studies: action.value };
    case "SET-PENDING-STUDIES":
      return { ...state, pendingStudies: action.value };
    case "SET-SHOW-ALL":
      return { ...state, showAll: action.value };
    case "SET-FILTERED-STUDIES":
      return { ...state, filteredStudies: action.value };
    case "SET-PAGINATED-STUDIES":
      return { ...state, paginatedStudies: action.value };
    case "SET-TOTAL-PAGES":
      return { ...state, totalPages: action.value || 1 };
    case "SET-CURRENT-PAGE":
      return { ...state, currentPage: action.value };
    case "TOGGLE-PENDING-REFETCH":
      return { ...state, pendingRefetch: !state.pendingRefetch };
    default:
      return state;
  }
}

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state: state, dispatch: dispatch }}>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/paymentterms" element={<PaymentTerms />} />
        <Route path="/aboutus" element={<AboutUs />} />

        <Route
          path="/dashboard"
          element={<AppContainer component={Dashboard} header="Dashboard" />}
        />
        <Route
          path="/signout"
          element={<AppContainer component={Signout} header="Dashboard" />}
        />
        <Route
          path="/reports"
          element={<AppContainer component={Reports} header="Reports" />}
        />
        <Route
          path="/preferences"
          element={
            <AppContainer component={Preferences} header="Preferences" />
          }
        />
        <Route
          path="/availability"
          element={
            <AppContainer component={Availability} header="Availability" />
          }
        />
        <Route
          path="/templates"
          element={<AppContainer component={Templates} header="Templates" />}
        />
        <Route
          path="/template/new"
          element={<AppContainer component={TemplateCM} header="Template" />}
        />
        <Route
          path="/template/:id/edit"
          element={<AppContainer component={TemplateCM} header="Template" />}
        />

        <Route
          path="/payment"
          element={<AppContainer component={Payment} header="Payment" />}
        />

        <Route
          path="/pricing"
          element={<AppContainer component={Pricing} header="Pricing" />}
        />

        <Route
          path="/assignlist"
          element={<AppContainer component={AssignList} header="Assign List" />}
        />

        <Route
          path="/entitys"
          element={<AppContainer component={Entitys} header="Entities" />}
        />
        <Route
          path="/entity/new"
          element={<AppContainer component={EntityCVM} header="Entity" />}
        />
        <Route
          path="/entity/:id/edit"
          element={<AppContainer component={EntityCVM} header="Entity" />}
        />
        <Route
          path="/users"
          element={<AppContainer component={Users} header="Users" />}
        />
        <Route
          path="/user/new"
          element={<AppContainer component={UserCVM} header="User" />}
        />
        <Route
          path="/user/:id/edit"
          element={<AppContainer component={UserCVM} header="User" />}
        />

        <Route
          path="/refentitys"
          element={
            <AppContainer component={RefEntitys} header="Referral Entities" />
          }
        />
        <Route
          path="/refentity/new"
          element={
            <AppContainer component={RefEntityCVM} header="Referral Entity" />
          }
        />

        <Route
          path="/transactions"
          element={
            <AppContainer component={Transactions} header="Transactions" />
          }
        />
        <Route path="study">
          <Route
            path=":studyId"
            element={<AppContainer component={StudyDetail} header="Study" />}
          />
          <Route
            path="order/:studyId"
            element={<AppContainer component={Order} header="Order" />}
          />
          <Route
            path="report/:studyId"
            element={<AppContainer component={Report} header="Report" />}
          />
        </Route>

        <Route path="/share" element={<Share />} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
