import * as React from "react";

import { userSignOut } from "../api";

function Signout() {
  React.useEffect(() => {
    const signOut = async () => {
      await userSignOut();
    };

    signOut();
  }, []);

  return <div></div>;
}

export default Signout;
