import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import Skeleton from "@mui/material/Skeleton";

import { UserContext } from "../ui/UserContext";
import messageStyles from "./Message.module.css";
import { postMessage, getStudy, updateMessage } from "../api";

interface IMessage {
  studyId: string;
  handleDialogClose: () => void;
  showDialog: boolean;
}

function Message(props: IMessage) {
  let [message, setMessage] = React.useState("");
  const { dispatch } = React.useContext(UserContext);
  let [messages, setMessages] = React.useState<
    | { message: string; fromUserName: string; messageDate: Date; id: string }[]
    | undefined
  >();
  let [fetch, setFetch] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "MESSAGE", studyId: props.studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setMessages(response.data.values);
      }
    };

    getData();
  }, [fetch, props.studyId]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { studyId: props.studyId };
      await updateMessage(study);
    };

    getData();
  }, [fetch, props.studyId]);

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={() => {
        dispatch({
          type: "TOGGLE-PENDING-REFETCH",
        });
        props.handleDialogClose();
      }}
    >
      <DialogTitle>Message</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          rows={2}
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />

        <DialogActions sx={{ paddingRight: "0px" }}>
          <Button
            color="primary"
            sx={{ minWidth: "100px" }}
            variant="contained"
            disabled={!message}
            onClick={async (event) => {
              event.preventDefault();
              try {
                let response = await postMessage({
                  studyId: props.studyId,
                  message: message,
                });
                if (response.status === 200) {
                  setMessage("");
                  dispatch({
                    type: "SET-SUCCESS",
                    value: "Message Added Successfully",
                  });
                  setFetch(!fetch);
                } else {
                  dispatch({ type: "SET-ERROR", value: response.data.message });
                }
              } catch (error) {
                dispatch({
                  type: "SET-ERROR",
                  value: "Failed to Save Message",
                });
              }
            }}
          >
            Send
          </Button>
        </DialogActions>
      </DialogContent>

      <DialogContent sx={{ marginTop: "0px", paddingTop: "0px" }}>
        {messages ? (
          messages.length > 0 ? (
            <>
              {messages.map((message) => {
                return (
                  <div className={messageStyles.message} key={message.id}>
                    <div>{message.message}</div>
                    <div className={messageStyles.footer}>
                      <span>
                        {"->"} {message.fromUserName}
                      </span>
                      <span style={{ paddingLeft: "10px" }}>
                        {` [
                          ${format(
                            new Date(message.messageDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className={messageStyles.message}>
              <div>No Messages</div>
            </div>
          )
        ) : (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default Message;
