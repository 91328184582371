import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format, addHours } from "date-fns";
import CSelectField from "../Components/CSelectField";
import CSingleAutoCompleteField from "../Components/CSingleAutoCompleteField";
import CDateTimeField from "../Components/CDateTimeField";

import { updateUser, getStudy } from "../api";
import { UserContext } from "../ui/UserContext";
import availabilityStyles from "./Availability.module.css";

function Availability() {
  const { dispatch } = React.useContext(UserContext);
  let navigate = useNavigate();
  const matches = useMediaQuery("(min-width:641px)");
  let [radiologists, setRadiologists] = React.useState([]);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      availability: "YES",
      availableFrom: format(new Date(), "dd-MMM-yyyy HH:mm"),
      availableTo: format(addHours(new Date(), 6), "dd-MMM-yyyy HH:mm"),
      radiologist: "",
    },
  });
  const availability = watch("availability");
  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        availableFrom: new Date(data.availableFrom),
        availableTo: new Date(data.availableTo),
        action: "AVAILABILITY",
      };
      let response = await updateUser(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Availability Updated Successfully",
        });
        navigate("/dashboard");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value:
          error?.response?.data?.message || "Failed to Update Availability",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "RADIOLOGISTS_LOV" };
      let response = await getStudy(study);
      if (response.status === 200) {
        setRadiologists(response.data.values);
      }
    };
    getData();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={availabilityStyles.availability}
      noValidate
    >
      <Box
        sx={
          matches
            ? {
                marginTop: 1,
                marginBottom: 1,
                display: "flex",
                "& .MuiFormControl-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "25%",
                },
                "& .MuiAutocomplete-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "25%",
                },
                "& .MuiAutocomplete-root .MuiFormControl-root": {
                  marginRight: 0,
                  marginTop: 0,
                  width: "100%",
                },
              }
            : {
                "& .MuiFormControl-root": { width: "100%", marginTop: 2 },
              }
        }
      >
        <CSelectField
          name="availability"
          label="Availability"
          required
          control={control}
          error={errors.availability}
          options={[
            { id: "YES", label: "Available" },
            { id: "NO", label: "Not Available" },
          ]}
        />

        <CSingleAutoCompleteField
          name="radiologist"
          required
          control={control}
          label="Radiologist"
          options={radiologists}
        />
        {availability === "YES" ? (
          <>
            <CDateTimeField
              name="availableFrom"
              label="From Time"
              control={control}
              required
            />

            <CDateTimeField
              name="availableTo"
              label="To Time"
              control={control}
              required
            />
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box
        sx={
          matches
            ? {
                marginBottom: 1,
              }
            : {
                marginTop: 1,
                marginBottom: 1,
              }
        }
      >
        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          type="submit"
        >
          Update Availability
        </Button>
      </Box>
    </form>
  );
}

export default Availability;
