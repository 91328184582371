import * as React from "react";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";

import { getStudy } from "../api";
import { formatDate, escapeRegExp } from "../utilities";
import { UserContext } from "../ui/UserContext";
import { getAcounts, getTransactions } from "../api";
import { hasEntityRole } from "../utilities";

import transactionsStyles from "./Transactions.module.css";
import { Button } from "@mui/material";

// @ts-ignore
import { load } from "@cashfreepayments/cashfree-js";

interface ITransaction {
  id: string;
  description: string;
  transactionDate: Date;
  debitCredit: string;
  amount: number;
  type: string;
}

function Transactions() {
  let [fromDate, setFromDate] = React.useState<string>(formatDate(new Date()));
  let [toDate, setToDate] = React.useState<string>(formatDate(new Date()));
  let [accountId, setAccountId] = React.useState<{
    id: string;
    label: string;
  }>();

  let [accounts, setAccounts] = React.useState([]);

  let [allTransactions, setAllTransactions] = React.useState<ITransaction[]>(
    []
  );

  React.useEffect(() => {
    const getData = async () => {
      let account = {
        type: "LOV",
      };
      let response = await getAcounts(account);
      if (response.status === 200) {
        let accounts = response.data.values || [];
        setAccounts(accounts);
      }
    };

    getData();
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      if (accountId) {
        let response = await getTransactions(accountId.id, fromDate, toDate);
        if (response.status === 200) {
          let reports = response.data.values || [];
          setAllTransactions(reports);
        }
      }
    };

    getData();
  }, [accountId, fromDate, toDate]);

  const { dispatch } = React.useContext(UserContext);

  return (
    <div className={transactionsStyles.main}>
      <div className={transactionsStyles.filter}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
            <Grid item xs={4} sm={2} md={4}>
              <Autocomplete
                value={accountId}
                options={accounts}
                onChange={(event, value) => {
                  if (value) {
                    setAccountId(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    size="small"
                    label={"Account"}
                    required={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={2} md={4}>
              <DatePicker
                label="From"
                value={fromDate}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  if (newValue) {
                    setFromDate(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={2} md={4}>
              <DatePicker
                label="To"
                value={toDate}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  if (newValue) {
                    setToDate(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    size="small"
                    {...params}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </div>

      <div className={transactionsStyles.reports}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="Transaction Table"
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Debit/Credit</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTransactions.map((transaction) => {
                return (
                  <TableRow
                    key={transaction.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {transaction.id}
                    </TableCell>
                    <TableCell sx={{ width: "125px" }}>
                      {format(
                        new Date(transaction.transactionDate),
                        "eee, yyyy-MM-dd"
                      )}
                    </TableCell>
                    <TableCell>{transaction.description}</TableCell>
                    <TableCell>{transaction.debitCredit}</TableCell>
                    <TableCell align="right">{transaction.amount}</TableCell>
                  </TableRow>
                );
              })}

              {allTransactions.length !== 0 && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell sx={{ width: "125px" }}></TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {allTransactions.reduce(
                      (sum, transaction) =>
                        sum +
                        (transaction.debitCredit.toUpperCase() === "CREDIT"
                          ? Number(transaction.amount)
                          : -Number(transaction.amount)),
                      0
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <>
          {allTransactions.length === 0 && (
            <div className={transactionsStyles.noreports}>
              <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                No Transactions
              </Typography>
            </div>
          )}
        </>
        <>
          {allTransactions.length !== 0 && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                sx={{ marginTop: "20px", minWidth: "100px", width: "200px" }}
                variant="contained"
                onClick={async () => {
                  const cashfree = await load({
                    mode: "sandbox", //or production
                  });

                  let checkoutOptions = {
                    paymentSessionId:
                      "session_40hBZszJclZuJOAdi_kLTeB7QGw0_KqZLeeyWsWHEsbX1BfTi24_9OQQ7Iy0-5LnQy8O2zsiB7SVe8XWh0Uq6_upTijL_xfDK-6fs1lDvFRC",
                    redirectTarget: "_self", //optional (_self or _blank)
                  };

                  cashfree.checkout(checkoutOptions);
                }}
              >
                Pay
              </Button>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Transactions;
