import * as React from "react";
import { Link } from "react-router-dom";

import CloudIcon from "@mui/icons-material/Cloud";
import LaptopIcon from "@mui/icons-material/Laptop";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StorageIcon from "@mui/icons-material/Storage";
import BackupIcon from "@mui/icons-material/Backup";
import { Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Logo from "./Logo";
import LoginDialog from "./LoginDialog";
import { isAuthorized } from "../utilities";

import landingPageStyles from "./LandingPage.module.css";

const Prem = () => {
  let [showMore, setShowMore] = React.useState(false);
  return (
    <div
      className={showMore ? landingPageStyles.prem : landingPageStyles.premless}
    >
      <img
        className={landingPageStyles.premimage}
        src="./Prem.png"
        alt="Prem"
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontSize: "1.2rem",
          marginTop: "10px",
        }}
      >
        Dr. Prem Kumar C, MD FRCR
      </Typography>
      <div className={landingPageStyles.radiologistreg}>
        Director & Chief Radiologist (Reg No. 79064)
      </div>
      <div
        className={
          showMore ? landingPageStyles.prembio : landingPageStyles.prembioless
        }
      >
        <div className={landingPageStyles.prembiocontent}>
          Dr. Prem Kumar C completed his M.B.B.S from Dr. MGR Tamil Nadu Medical
          University, Chennai, India. He went on to successfully pursue his M.D
          in Radio Diagnosis from Gujarat University, Ahmedabad and FRCR from
          Royal college of Radiology, London.
          <br />
          <br />
          With over 12 years experience as a radiologist, his previous
          experience includes work as Senior Associate professor of Radiology at
          SRM Medical College and Research Institute, Chennai and as Assistant
          Professor at Chettinad Hospital and Research Institute, Chennai. He
          specializes in Musculoskeletal Imaging, Cardio Vascular imaging,
          Imaging in Stroke and Emergency Radiology.
        </div>
      </div>
      <button
        className={landingPageStyles.prembutton}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? "...Less" : "...More"}
      </button>
    </div>
  );
};

const VijayAnand = () => {
  let [showMore, setShowMore] = React.useState(false);
  return (
    <div
      className={
        showMore
          ? landingPageStyles.vijayanand
          : landingPageStyles.vijayanandless
      }
    >
      <img
        className={landingPageStyles.vijayanandimage}
        src="./VijayAnand.jpg"
        alt="VijayAnand"
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontSize: "1.2rem",
          marginTop: "10px",
        }}
      >
        Dr. A Vijay Anand
      </Typography>
      <div className={landingPageStyles.radiologistreg}>
        DMRD (BIR), EDIR (EBR), Diplomate in ICRI (IRIA) (Reg No. 78646)
      </div>
      <div
        className={
          showMore
            ? landingPageStyles.vijayanandbio
            : landingPageStyles.vijayanandbioless
        }
      >
        <div className={landingPageStyles.vijayanandbiocontent}>
          Diagnostic Radiologist with 10 years of versatile multimodality
          experience and sound technical knowledge about the imaging machinery,
          study protocols, modality selection and optimisation with
          customization to patients clinical need.
          <br />
          <br />
          Expertise in Body imaging (CT & MRI), with thorough knowledge of
          anatomy. Special sub-specialty interest towards MSK and
          neuroradiology.
        </div>
      </div>
      <button
        className={landingPageStyles.vijayanandbutton}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? "...Less" : "...More"}
      </button>
    </div>
  );
};

const Kiran = () => {
  let [showMore, setShowMore] = React.useState(false);
  return (
    <div
      className={
        showMore ? landingPageStyles.kiran : landingPageStyles.kiranless
      }
    >
      <img
        className={landingPageStyles.kiranimage}
        src="./Kiran.jpg"
        alt="Kiran"
      />
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontSize: "1.2rem",
          marginTop: "10px",
        }}
      >
        Dr. Kiran M
      </Typography>
      <div className={landingPageStyles.radiologistreg}>
        DNB(RD), MNAMS (Reg No. KMC 95660)
      </div>
      <div
        className={
          showMore ? landingPageStyles.kiranbio : landingPageStyles.kiranbioless
        }
      >
        <div className={landingPageStyles.kiranbiocontent}>
          Distinguished Radiologist with 9 years experience and with special
          interest in Musculoskeletal Radiology, Fetal and Pediatric Radiology
          and Interventional Radiology
          <br />
          <br />
          Life member of Indian Radiological and Imaging Association (IRIA),
          Fetal Medical Foundation (FMF), Corresponding Member, European Society
          of Radiology (ESR) and Life member of Association of DNB Doctors (ADD)
          and Member of Indian Society of Gastrointestinal and Abdominal
          Radiology (ISGAR)
        </div>
      </div>
      <button
        className={landingPageStyles.kiranbutton}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {showMore ? "...Less" : "...More"}
      </button>
    </div>
  );
};

function LandingPage() {
  const [showLogIn, setShowLogIn] = React.useState(false);

  const handleClose = () => {
    setShowLogIn(false);
  };

  return (
    <>
      <section id="main" className={landingPageStyles.container}>
        <div className={landingPageStyles.backgroundimage}></div>

        <nav className={landingPageStyles.nav}>
          <div className={landingPageStyles.navlogo}>
            <Logo />
          </div>
          <div className={landingPageStyles.navbrandname}>
            <h4 className={landingPageStyles.header}>TeleRAD</h4>
          </div>
        </nav>

        <main className={landingPageStyles.hero}>
          <div className={landingPageStyles.main}>
            <div className={landingPageStyles.brand}>
              <div className={landingPageStyles.logo}>
                <Logo />
              </div>
              <div className={landingPageStyles.brandname}>
                <h1 className={landingPageStyles.header}>TeleRAD</h1>
              </div>
            </div>

            <div className={landingPageStyles.dashboardservices}>
              <div className={landingPageStyles.dashboardservice}>
                <div className={landingPageStyles.dashboardserviceicon}>
                  <LaptopIcon />
                </div>
                <h3 className={landingPageStyles.dashboardserviceheader}>
                  Tele Reporting
                </h3>
              </div>
              <div className={landingPageStyles.dashboardservice}>
                <div className={landingPageStyles.dashboardserviceicon}>
                  <AssignmentTurnedInIcon />
                </div>
                <h3 className={landingPageStyles.dashboardserviceheader}>
                  Second Opinion
                </h3>
              </div>
              <div className={landingPageStyles.dashboardservice}>
                <div className={landingPageStyles.dashboardserviceicon}>
                  <CloudIcon />
                </div>
                <h3 className={landingPageStyles.dashboardserviceheader}>
                  Cloud PACS
                </h3>
              </div>
              <div className={landingPageStyles.dashboardservice}>
                <div className={landingPageStyles.dashboardserviceicon}>
                  <StorageIcon />
                </div>
                <h3 className={landingPageStyles.dashboardserviceheader}>
                  DICOM Server
                </h3>
              </div>

              <div className={landingPageStyles.dashboardservice}>
                <div className={landingPageStyles.dashboardserviceicon}>
                  <BackupIcon />
                </div>
                <h3 className={landingPageStyles.dashboardserviceheader}>
                  Long Term Storage
                </h3>
              </div>

              <div style={{ display: "flex" }}>
                {isAuthorized() && (
                  <Link
                    to={"/dashboard"}
                    className={`${landingPageStyles.learn}`}
                    style={{ marginRight: "15px" }}
                  >
                    Dashboard
                  </Link>
                )}
                {!isAuthorized() && (
                  <button
                    onClick={() => {
                      setShowLogIn(true);
                    }}
                    className={`${landingPageStyles.learn} ${landingPageStyles.dashboard}`}
                    style={{ marginRight: "15px" }}
                  >
                    Login
                  </button>
                )}

                <a
                  href="#services"
                  className={`${landingPageStyles.learn} ${landingPageStyles.explore}`}
                >
                  Explore
                </a>
              </div>
            </div>
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", fontFamily: "Open Sans Condensed" }}
              className={landingPageStyles.dashboardservicesmobile}
            >
              Tele Reporting, Second Opinion, Cloud PACS, DICOM Server, Long
              Term Storage
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isAuthorized() && (
                <Link
                  to={"/dashboard"}
                  className={`${landingPageStyles.learnmobile}`}
                  style={{ marginRight: "15px" }}
                >
                  Dashboard
                </Link>
              )}
              {!isAuthorized() && (
                <button
                  onClick={() => {
                    setShowLogIn(true);
                  }}
                  className={`${landingPageStyles.learnmobile} ${landingPageStyles.dashboard}`}
                  style={{ marginRight: "15px" }}
                >
                  Login
                </button>
              )}

              <a
                href="#explore"
                className={`${landingPageStyles.learnmobile} ${landingPageStyles.explore}`}
              >
                Explore
              </a>
            </div>
          </div>

          <LoginDialog showLogIn={showLogIn} handleClose={handleClose} />
        </main>
      </section>

      <section id="services" className={landingPageStyles.services}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Services</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={landingPageStyles.service}
        >
          <div className={landingPageStyles.servicedetail}>
            <div className={landingPageStyles.serviceicon}>
              <LaptopIcon />
            </div>
            <Typography variant="h5">Tele Reporting</Typography>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              Best in-class, high quality, on time, NABH compliant reports made
              available by our radiologists. 24/7 coverage for your reporting
              needs.
            </div>
          </div>
          <div className={landingPageStyles.servicedetail}>
            <div className={landingPageStyles.serviceicon}>
              <AssignmentTurnedInIcon />
            </div>
            <Typography variant="h5">Second Opinion</Typography>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              Need expert opinions on a difficult study? Our expert radiologists
              will help arrive at the right and accurate diagnosis and of
              highest quality.
            </div>
          </div>
          <div className={landingPageStyles.servicedetail}>
            <div className={landingPageStyles.serviceicon}>
              <CloudIcon />
            </div>
            <Typography variant="h5">Cloud PACS</Typography>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              Highly secure, HIPAA complaint, easy to use cloud PACS workflow
              solution with privacy at its core to protect sensitive patient
              information.
            </div>
          </div>
          <div className={landingPageStyles.servicedetail}>
            <div className={landingPageStyles.serviceicon}>
              <StorageIcon />
            </div>
            <Typography variant="h5">DICOM Server</Typography>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              We provide DICOM software, installation and maintenance services
              for your inhouse requirements at a very affordable cost.
            </div>
          </div>

          <div className={landingPageStyles.servicedetail}>
            <div className={landingPageStyles.serviceicon}>
              <BackupIcon />
            </div>
            <Typography variant="h5">Long Term Storage</Typography>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              Cloud backed secure and complaint long term storage of DICOM
              studies that is highly and readily available.
            </div>
          </div>
        </div>
      </section>

      <section id="radiologists" className={landingPageStyles.radiologists}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Our Radiologists</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={landingPageStyles.radiologistdetails}
        >
          <Prem />
          <VijayAnand />
          <Kiran />
        </div>

        <div className={landingPageStyles.otherradiologistdetails}>
          <div className={landingPageStyles.otherradiologist}>
            <img
              className={landingPageStyles.otherradiologistimagesmall}
              src="./Vinay.jpeg"
              alt="Vinay"
            />
            <div>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  marginTop: "10px",
                }}
              >
                Dr. Vinay Kumar K M
              </Typography>
              <div className={landingPageStyles.otherradiologistreg}>
                MD ( Radiology), FAGE, FVIR (Reg No. KMC 112164)
              </div>
              <div className={landingPageStyles.otherradiologistbio}>
                <div className={landingPageStyles.otherradiologistbiocontent}>
                  <span style={{ fontWeight: "bold" }}>Experience:</span> 5
                  years
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Specialization:
                  </span>{" "}
                  Neuro, Body and MSK
                </div>
              </div>
            </div>
          </div>
          <div className={landingPageStyles.otherradiologist}>
            <img
              className={landingPageStyles.otherradiologistimagesmall}
              src="./VijayaKarthikeyan.jpg"
              alt="Vijaya Karthikeyan"
            />
            <div>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  marginTop: "10px",
                }}
              >
                Dr. M Vijaya Karthikeyan
              </Typography>
              <div className={landingPageStyles.otherradiologistreg}>
                MD FRCR (Reg No. 80901)
              </div>
              <div className={landingPageStyles.otherradiologistbio}>
                <div className={landingPageStyles.otherradiologistbiocontent}>
                  <span style={{ fontWeight: "bold" }}>Experience:</span> 9
                  years
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Specialization:
                  </span>{" "}
                  MSK and Neuro imaging
                </div>
              </div>
            </div>
          </div>
          <div className={landingPageStyles.otherradiologist}>
            <img
              className={landingPageStyles.otherradiologistimagesmall}
              src="./Vinoth.jpg"
              alt="Vinoth"
            />
            <div>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  marginTop: "10px",
                }}
              >
                Dr. Vinoth A
              </Typography>
              <div className={landingPageStyles.otherradiologistreg}>
                MDRD (Reg No. 94361)
              </div>
              <div className={landingPageStyles.otherradiologistbio}>
                <div className={landingPageStyles.otherradiologistbiocontent}>
                  <span style={{ fontWeight: "bold" }}>Experience:</span> 6
                  years
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Specialization:
                  </span>{" "}
                  Abdominal Imaging and MSK
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="partners" className={landingPageStyles.partners}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Our Partners</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={landingPageStyles.partnerdetails}
        >
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Ebisu Diagnostics, Bangalore
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Sri Sai Hospitals, Bangalore
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              FIMS Hospital, Coimbatore
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Rex Ortho Hospital, Coimbatore
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              KVS Hospital, Ariyalur
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Venkateswara Diagnostic Center, Chennai
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Yazhi Scans, Chennai
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Vani Scans, Ariyalur
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Bharat Diagnostic Center, Chennai
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Best Diagno Lab, Chennai
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Vinodha Scans, Bangalore
            </Typography>
          </div>
          <div className={landingPageStyles.partner}>
            <Typography sx={{ margin: "10px" }} variant="h5">
              Prem Scans, Chennai
            </Typography>
          </div>
        </div>
      </section>

      <section id="pricing" className={landingPageStyles.pricing}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Pricing</Typography>
        </div>
        <div
          style={{ display: "flex" }}
          className={landingPageStyles.pricingdetails}
        >
          <Typography sx={{ margin: "10px", textAlign: "center" }} variant="h5">
            Contact us at the address below for exclusive pricing on Tele
            Reporting, Cloud PACS and Long Term Storage
          </Typography>
        </div>
      </section>

      <section id="contact" className={landingPageStyles.contact}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Contact</Typography>
        </div>
        <div className={landingPageStyles.contactdetails}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div className={landingPageStyles.address}>
              <Typography variant="h5">
                TeleRAD Reporting Services Private Limited
              </Typography>
              <div>
                31, First Cross Street, K.K Nagar, Guduvancherry,
                <br />
                Chennai, Tamil Nadu - 603 202
              </div>
            </div>
            <div className={landingPageStyles.email}>
              <Typography variant="h5">
                <EmailIcon />
                <span style={{ paddingLeft: "10px" }}>
                  info@teleradgroup.com
                </span>
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Venkatesh M</Typography>
              <div
                style={{
                  letterSpacing: "1px",
                }}
              >
                Relationship Manager
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <WhatsAppIcon />
                <a
                  href="tel:+917708629235"
                  style={{
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  className={landingPageStyles.phone}
                >
                  +91 77086 29235
                </a>
              </div>
            </div>

            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Sivaraman S J</Typography>
              <div
                style={{
                  letterSpacing: "1px",
                }}
              >
                Director
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <WhatsAppIcon />
                <a
                  href="tel:+917760577669"
                  style={{
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  className={landingPageStyles.phone}
                >
                  +91 77605 77669
                </a>
              </div>
            </div>

            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Dr. Premkumar C</Typography>
              <div
                style={{
                  letterSpacing: "1px",
                }}
              >
                Director & Chief Radiologist
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <WhatsAppIcon />
                <a
                  href="tel:+919841656529"
                  style={{
                    textDecoration: "none",
                    marginLeft: "5px",
                  }}
                  className={landingPageStyles.phone}
                >
                  +91 98416 56529
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footer" className={landingPageStyles.footer}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "1.2rem",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          © TeleRAD Group
        </Typography>

        <Link to={"/aboutus"} style={{ marginBottom: "10px" }}>
          About Us
        </Link>

        <Link to={"/paymentterms"} style={{ marginBottom: "10px" }}>
          Payment Terms & Cancellation Policy
        </Link>

        <p style={{ marginBottom: "10px", marginTop: "0" }}>
          31, First Cross Street, K.K Nagar, Guduvancherry, Chennai, Tamil Nadu
          - 603 202
        </p>
      </section>
    </>
  );
}

export default LandingPage;
