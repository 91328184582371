import * as React from "react";
import { Controller, Control, ErrorOption } from "react-hook-form";
import { TextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

interface ICCheckBoxField {
  name: string;
  control: Control<any>;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: ErrorOption;
  alignment?: "horizontal" | "vertical";
  options: { id: string; label: string }[];
}

function CCheckBoxField({
  name,
  autoFocus,
  label,
  required,
  control,
  disabled,
  error,
  options,
  alignment,
  ...rest
}: ICCheckBoxField & Partial<TextFieldProps>) {
  return (
    <Controller
      name={name}
      rules={{ required: required }}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <FormControl component="fieldset" variant="standard">
            {label && <FormLabel component="legend">{label}</FormLabel>}

            <FormGroup
              sx={{
                display: "flex",
                flexDirection: alignment === "horizontal" ? "row" : "column",
              }}
            >
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option.id}
                    control={
                      <Checkbox
                        checked={(field.value || []).indexOf(option.id) !== -1}
                        disabled={disabled}
                        name={option.label}
                      />
                    }
                    label={option.label}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        let newValues = [...field.value];
                        newValues.push(option.id);
                        field.onChange(newValues);
                      } else {
                        let newValues = [...field.value];
                        newValues.splice(
                          (field.value || []).indexOf(option.id),
                          1
                        );
                        field.onChange(newValues);
                      }
                    }}
                  />
                );
              })}
            </FormGroup>
            {fieldState.error &&
              fieldState.error.type &&
              fieldState.error.type !== "required" && (
                <FormHelperText>Required</FormHelperText>
              )}
          </FormControl>
        );
      }}
    />
  );
}

export default CCheckBoxField;
