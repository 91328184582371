import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";

import { UserContext } from "../ui/UserContext";
import { getSignedUrl, getStudy, postAction } from "../api";
import { hasEntityRole } from "../utilities";
import downloadStyles from "./Download.module.css";

interface IReports {
  studyId: string;
  entityId: string;
  handleDialogClose: () => void;
  showDialog: boolean;
}

function Download(props: IReports) {
  let [reports, setReports] = React.useState<
    {
      id: string;
      reportedDate: Date;
      type: string;
      status: string;
      details: any;
    }[]
  >([]);
  const { dispatch } = React.useContext(UserContext);

  React.useEffect(() => {
    const getData = async () => {
      let study = {
        type: "REPORTS",
        studyId: props.studyId,
      };
      let response = await getStudy(study);
      if (response.status === 200) {
        setReports(response.data.values);
      }
    };

    if (props.studyId) {
      getData();
    }
  }, [props.studyId]);

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={props.handleDialogClose}
    >
      <DialogTitle>Reports</DialogTitle>
      <DialogContent>
        {reports.map((report, index) => {
          return (
            <div
              key={report.id}
              className={downloadStyles.report}
              style={{ display: "flex", alignItems: "center" }}
            >
              {`${
                report.type === "AUDIO"
                  ? "Audio"
                  : report.type === "FINAL"
                  ? "Final"
                  : "Doc"
              } Report ${index + 1} [
                          ${format(
                            new Date(report.reportedDate),
                            "eee, yyyy-MM-dd HH:mm"
                          )} ]`}

              <div>
                <IconButton
                  aria-label="Download File"
                  onClick={async (e) => {
                    e.preventDefault();

                    let fileToDownload = {
                      publicOrPrivate:
                        report.details.publicOrPrivate || "private",
                      fileName: report.details.fileName,
                      fileType: report.details.fileType,
                      type: "GET",
                    };
                    try {
                      const response = await getSignedUrl(fileToDownload);
                      if (response.status === 200) {
                        if (
                          response.data &&
                          response.data.values &&
                          response.data.values.url
                        ) {
                          window.open(response.data.values.url, "_blank");
                        } else {
                          dispatch({
                            type: "SET-ERROR",
                            value: "File Download Error",
                          });
                        }
                      } else {
                        dispatch({
                          type: "SET-ERROR",
                          value: "File Download Error",
                        });
                      }
                    } catch (error) {
                      dispatch({
                        type: "SET-ERROR",
                        value: "File Download Error",
                      });
                    }

                    if (
                      hasEntityRole(props.entityId, "ADMIN") ||
                      hasEntityRole(props.entityId, "OPERATOR")
                    ) {
                      try {
                        let payload = {
                          action: "DOWNLOAD",
                          studyId: props.studyId,
                          reportId: report.id,
                        };
                        let response = await postAction(payload);
                      } catch (error) {
                        //No need to raise error
                      }
                    }
                  }}
                  style={{ paddingLeft: "7px", cursor: "pointer" }}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
      </DialogContent>

      <DialogActions sx={{ paddingRight: "24px" }}>
        <Button
          sx={{ minWidth: "100px" }}
          color="primary"
          variant="contained"
          onClick={props.handleDialogClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Download;
