import * as React from "react";
import { Link } from "react-router-dom";

import CloudIcon from "@mui/icons-material/Cloud";
import LaptopIcon from "@mui/icons-material/Laptop";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import StorageIcon from "@mui/icons-material/Storage";
import BackupIcon from "@mui/icons-material/Backup";
import { Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Logo from "./Logo";
import LoginDialog from "./LoginDialog";
import { isAuthorized } from "../utilities";

import PaymentTermsStyles from "./PaymentTerms.module.css";

function PaymentTerms() {
  const [showLogIn, setShowLogIn] = React.useState(false);

  const handleClose = () => {
    setShowLogIn(false);
  };

  return (
    <>
      <section id="main" className={PaymentTermsStyles.container}>
        <div className={PaymentTermsStyles.backgroundimage}></div>

        <nav className={PaymentTermsStyles.nav}>
          <div className={PaymentTermsStyles.navlogo}>
            <Logo />
          </div>
          <div className={PaymentTermsStyles.navbrandname}>
            <h4 className={PaymentTermsStyles.header}>TeleRAD</h4>
          </div>
        </nav>

        <main className={PaymentTermsStyles.hero}>
          <div className={PaymentTermsStyles.main}>
            <div className={PaymentTermsStyles.brand}>
              <div className={PaymentTermsStyles.logo}>
                <Logo />
              </div>
              <div className={PaymentTermsStyles.brandname}>
                <h1 className={PaymentTermsStyles.header}>TeleRAD</h1>
              </div>
            </div>
          </div>
        </main>
      </section>

      <section id="terms" className={PaymentTermsStyles.services}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Terms & Conditions</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={PaymentTermsStyles.service}
        >
          <div style={{ marginTop: "10px", textAlign: "justify" }}>
            These Terms and Conditions, along with privacy policy or other terms
            (“Terms”) constitute a binding agreement by and between TELERAD
            REPORTING SERVICES PRIVATE LIMITED, ( “Website Owner” or “we” or
            “us” or “our”) and you (“you” or “your”) and relate to your use of
            our website, goods (as applicable) or services (as applicable)
            (collectively, “Services”). By using our website and availing the
            Services, you agree that you have read and accepted these Terms
            (including the Privacy Policy). We reserve the right to modify these
            Terms at any time and without assigning any reason. It is your
            responsibility to periodically review these Terms to stay informed
            of updates.
            <p>
              The use of this website or availing of our Services is subject to
              the following terms of use:
            </p>
            <ul>
              <li>
                To access and use the Services, you agree to provide true,
                accurate and complete information to us during and after
                registration, and you shall be responsible for all acts done
                through the use of your registered account.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                offered on this website or through the Services, for any
                specific purpose.
              </li>
              <li>
                You acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law. Your use of our Services and the websiteis solely at
                your own risk and discretion.
              </li>
              <li>
                You are required to independently assess and ensure that the
                Services meet your requirements. The contents of the Website and
                the Services are proprietary to Us and you will not have any
                authority to claim any intellectual property rights, title, or
                interest in its contents.
              </li>
              <li>
                You acknowledge that unauthorized use of the Website or the
                Services may lead to action against you as per these Terms or
                applicable laws. You agree to pay us the charges associated with
                availing the Services. You agree not to use the website and/ or
                Services for any purpose that is unlawful, illegal or forbidden
                by these Terms, or Indian or local laws that might apply to you.
              </li>
              <li>
                You agree and acknowledge that website and the Services may
                contain links to other third party websites. On accessing these
                links, you will be governed by the terms of use, privacy policy
                and such other policies of such third party websites. You
                understand that upon initiating a transaction for availing the
                Services you are entering into a legally binding and enforceable
                contract with the us for the Services.
              </li>
              <li>
                You shall be entitled to claim a refund of the payment made by
                you in case we are not able to provide the Service. The
                timelines for such return and refund will be according to the
                specific Service you have availed or within the time period
                provided in our policies (as applicable). In case you do not
                raise a refund claim within the stipulated time, than this would
                make you ineligible for a refund. Notwithstanding anything
                contained in these Terms, the parties shall not be liable for
                any failure to perform an obligation under these Terms if
                performance is prevented or delayed by a force majeure event.
              </li>
              <li>
                hese Terms and any dispute or claim relating to it, or its
                enforceability, shall be governed by and construed in accordance
                with the laws of India. All disputes arising out of or in
                connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts in Tamil Nadu
              </li>
              <li>
                All concerns or communications relating to these Terms must be
                communicated to us using the contact information provided on
                this website
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="privacy" className={PaymentTermsStyles.services}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Privacy Policy</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={PaymentTermsStyles.service}
        >
          <div style={{ marginTop: "10px", textAlign: "justify" }}>
            This privacy policy (“Policy”) relates to the manner TELERAD
            REPORTING SERVICES PRIVATE LIMITED (“we”, “us”, “our”) in which we
            use, handle and process the data that you provide us in connection
            with using the products or services we offer. By using this website
            or by availing goods or services offered by us, you agree to the
            terms and conditions of this Policy, and consent to our use,
            storage, disclosure, and transfer of your information or data in the
            manner described in this Policy.
            <p>
              We are committed to ensuring that your privacy is protected in
              accordance with applicable laws and regulations. We urge you to
              acquaint yourself with this Policy to familiarize yourself with
              the manner in which your data is being handled by us.
            </p>
            <p>
              TELERAD REPORTING SERVICES PRIVATE LIMITED may change this Policy
              periodically and we urge you to check this page for the latest
              version of the Policy in order to keep yourself updated.
            </p>
            <Typography variant="h5">What data is being collected</Typography>
            <ul>
              <li>Name</li>
              <li>Contact information including address and email address</li>
              <li>
                The meaning of Personal Data will be as defined under relevant
                Indian laws
              </li>
              <li>
                Note: Notwithstanding anything under this Policy as required
                under applicable Indian laws, we will not be storing any credit
                card, debit card or any other similar card data of yours. Please
                also note that all data or information collected from you will
                be strictly in accordance with applicable laws and guidelines.
              </li>
            </ul>
            <Typography variant="h5">
              What we do with the data we gather
            </Typography>
            <ul>
              <li>Internal record keeping</li>
              <li>
                For providing updates to you regarding our products or services
                including any special offers
              </li>
              <li>To communicate information to you</li>
              <li>For internal training and quality assurance purposes</li>
            </ul>
            <Typography variant="h5">Who do we share your data with</Typography>
            <ul>
              <li>We do not share information with any Third Parties</li>
            </ul>
            <Typography variant="h5">
              How long will we retain your information or data?
            </Typography>
            <p>
              We may retain your information or data (i) for as long as we are
              providing goods and services to you; and (ii) as permitted under
              applicable law, we may also retain your data or information even
              after you terminate the business relationship with us. However, we
              will process such information or data in accordance with
              applicable laws and this Policy
            </p>
            <Typography variant="h5">Queries/ Grievances</Typography>
            <p>
              For any queries, questions or grievances about this Policy, please
              contact us using the contact information provided on this website
            </p>
          </div>
        </div>
      </section>

      <section id="cancellation" className={PaymentTermsStyles.services}>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h3">Cancellation & Refund Policy</Typography>
        </div>
        <div
          style={{ flexGrow: "2", display: "flex" }}
          className={PaymentTermsStyles.service}
        >
          <div style={{ marginTop: "10px", textAlign: "justify" }}>
            <p>
              Cancellations will be considered only if the request is made
              immediately after placing the order. However, the cancellation
              request may not be entertained if the orders have been
              communicated to the doctors and the doctors have started reporting
              the study.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PaymentTerms;
