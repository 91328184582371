import * as React from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import CMultiAutoCompleteField from "../Components/CMultiAutoCompleteField";
import CCheckBoxField from "../Components/CCheckBoxField";
import CTextField from "../Components/CTextField";

import { getStudy, postAction } from "../api";
import { UserContext } from "../ui/UserContext";

interface IPublish {
  studyId: string;
  entityId: string;
  handleDialogClose: () => void;
  showDialog: boolean;
}

function Publish(props: IPublish) {
  const [refEntities, setRefEntities] = React.useState<
    { id: string; label: string }[]
  >([]);

  const [toEdit, setToEdit] = React.useState(false);
  const { dispatch } = React.useContext(UserContext);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      entities: [],
      publishToEntities: [],
      emails: "",
      publishToEmails: [],
    },
  });

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "REFENTITYS_LOV", studyId: props.studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        setRefEntities(response.data.values);
      }
    };

    getData();
  }, [props.studyId]);

  React.useEffect(() => {
    const getData = async () => {
      let study = { type: "PUBLISH", studyId: props.studyId };
      let response = await getStudy(study);
      if (response.status === 200) {
        reset(response.data.values);
        setToEdit(true);
      }
    };

    getData();
  }, [props.studyId, reset]);

  const onSubmit = async (data: any) => {
    try {
      let payload = {
        ...data,
        action: "PUBLISH",
        studyId: props.studyId,
      };
      let response = await postAction(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Study Published Successfully",
        });
        dispatch({
          type: "TOGGLE-PENDING-REFETCH",
        });
        props.handleDialogClose();
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error.response.data.message ?? "Failed to Publish Study",
      });
    }
  };

  return (
    <Dialog
      open={props.showDialog}
      fullWidth
      maxWidth={"xs"}
      onClose={props.handleDialogClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>Publish</DialogTitle>
        <DialogContent>
          <CMultiAutoCompleteField
            name="entities"
            disabled={toEdit}
            control={control}
            label="Entities"
            options={refEntities}
          />

          <CCheckBoxField
            name="publishToEntities"
            disabled={toEdit}
            control={control}
            // label="To Publish"
            alignment="horizontal"
            options={[
              { id: "study", label: "Study" },
              { id: "finalreport", label: "Final Report" },
            ]}
          />
          <div style={{ marginTop: "25px" }}></div>
          <CTextField
            name="emails"
            label="Emails (Comma Seperated)"
            disabled={toEdit}
            control={control}
            multiline
            rows={2}
          />

          <CCheckBoxField
            name="publishToEmails"
            disabled={toEdit}
            control={control}
            // label="To Publish"
            alignment="horizontal"
            options={[
              { id: "study", label: "Study" },
              { id: "finalreport", label: "Final Report" },
            ]}
          />
        </DialogContent>

        <DialogActions sx={{ paddingRight: "24px" }}>
          {toEdit ? (
            <Button
              color="warning"
              sx={{ minWidth: "100px" }}
              variant="contained"
              onClick={(event) => {
                event.preventDefault();
                setToEdit(false);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="primary"
              sx={{ minWidth: "100px" }}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          )}

          <Button sx={{ minWidth: "100px" }} onClick={props.handleDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Publish;
