import * as React from "react";
import { Controller, Control, ErrorOption } from "react-hook-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface ICTextField {
  name: string;
  control: Control<any>;
  label: string;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: ErrorOption;
  fullWidth?: boolean;
}

function CTextField({
  name,
  autoFocus,
  label,
  required,
  multiline,
  rows,
  control,
  disabled,
  error,
  fullWidth,
  type,
  ...rest
}: ICTextField & Partial<TextFieldProps>) {
  return (
    <Controller
      name={name}
      rules={{ required: required }}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <TextField
            {...field}
            autoFocus={autoFocus}
            variant="outlined"
            margin="dense"
            id={name}
            label={label}
            required={required}
            multiline={multiline}
            disabled={disabled}
            rows={rows}
            type={type}
            sx={{ width: "100%" }}
            fullWidth={fullWidth}
            {...rest}
            error={
              fieldState.error ? (fieldState.error.type ? true : false) : false
            }
            helperText={
              fieldState.error
                ? fieldState.error.type === "required"
                  ? ""
                  : "Invalid"
                : ""
            }
          />
        );
      }}
    />
  );
}

export default CTextField;
