import * as React from "react";
import { Controller, Control, ErrorOption } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

interface ICSelectField {
  name: string;
  control: Control<any>;
  label: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  error?: ErrorOption;
  options: { id: string; label: string }[];
}

function CSelectField({
  name,
  autoFocus,
  label,
  required,
  disabled,
  control,
  fullWidth,
  multiple,
  error,
  options,
}: ICSelectField) {
  return (
    <Controller
      name={name}
      rules={{ required: required }}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          required={required}
          fullWidth={fullWidth}
          sx={{ width: "100%", marginTop: "8px" }}
          error={error ? (error.type ? true : false) : false}
        >
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            {...field}
            labelId={name}
            id={name}
            label={label}
            autoFocus={autoFocus}
            required={required}
            disabled={disabled}
            fullWidth={fullWidth}
            multiple={multiple}
            error={
              fieldState.error ? (fieldState.error.type ? true : false) : false
            }
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {fieldState.error &&
            fieldState.error.type &&
            fieldState.error.type !== "required" && (
              <FormHelperText>Required</FormHelperText>
            )}
        </FormControl>
      )}
    />
  );
}

export default CSelectField;
