import { format } from "date-fns";

import { getLocalStorage } from "./api";

import { IStudy } from "./Study/Study";
const publicS3BaseURL = process.env.REACT_APP_PUBLIC_S3_BASE_URL;

export const OVIYAM_BASE_URL = process.env.REACT_APP_OVIYAM_BASE_URL;
export const WEASIS_BASE_URL = process.env.REACT_APP_WEASIS_BASE_URL;
export const IOVIYAM_BASE_URL = process.env.REACT_APP_IOVIYAM_BASE_URL;
export const WEASIS_OLD_BASE_URL = process.env.REACT_APP_WEASIS_OLD_BASE_URL;

interface IRole {
  entityId: string;
  name: string;
  type: string;
  roles: string[];
}

export function isAuthorized() {
  return getLocalStorage("authToken") ? true : false;
}

export const formatDate = (date: Date | null | undefined): string => {
  if (date) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  return "";
};

function isSuperAdmin(roles: IRole[]) {
  let isSuperAdmin = false;

  roles.forEach((entityRoles) => {
    entityRoles.roles.forEach((entityRole) => {
      if (!isSuperAdmin) {
        isSuperAdmin = entityRole === "SUPERADMIN";
      }
    });
  });

  return isSuperAdmin;
}

export function getMaxRole() {
  let roles = getLocalStorage("roles");
  let superAdmin = isSuperAdmin(roles);

  if (superAdmin) {
    return "SUPERADMIN";
  }
}

export function hasRole(roleId: string) {
  let roles = getLocalStorage("roles");

  if (roleId === "SUPERADMIN") {
    let superAdmin = isSuperAdmin(roles);

    if (superAdmin) {
      return true;
    } else {
      return false;
    }
  } else {
    let hasRole = false;
    roles.forEach((entityRoles: any) => {
      hasRole = entityRoles.roles.some((entityRole: any) => {
        return entityRole === roleId;
      });
    });

    return hasRole;
  }
}

export function hasOnlyRole(roleId: string) {
  let roles = getLocalStorage("roles");
  let hasOnlyRole = true;

  roles.forEach((entityRoles: any) => {
    entityRoles.roles.forEach((entityRole: any) => {
      // console.log(entityRole, roleId, hasOnlyRole);

      hasOnlyRole = !(entityRole !== roleId);
    });
  });
  // console.log(hasOnlyRole);
  return hasOnlyRole;
}

export function getUserName() {
  let roles = getLocalStorage("roles");
  let userName = "";

  if (roles && roles.length > 0) {
    userName = roles[0].userName || "";
  }
  return userName;
}

export function hasEntityRole(entityId: string, roleId: string) {
  let roles = getLocalStorage("roles");
  let hasRole = false;
  // console.log("hasEntityRole", entityId, roleId);

  roles.forEach((entityRoles: any) => {
    // console.log(entityRoles);
    if (entityId === entityRoles.entityId)
      hasRole = entityRoles.roles.some((entityRole: any) => {
        // console.log("hasEntityRole", entityRole);
        // console.log(entityRole);
        return entityRole === roleId;
      });
  });
  // console.log("hasEntityRole", hasRole);

  return hasRole;
}

export function getStudyActions(study: IStudy) {
  let userRoles = getLocalStorage("roles");
  // console.log("getStudyActions", study);
  let superAdmin = isSuperAdmin(userRoles);
  let admin = hasEntityRole(study.entityId, "ADMIN");
  let operator = hasEntityRole(study.entityId, "OPERATOR");
  // let radiologist = hasEntityRole(study.entityId, "RADIOLOGIST");
  let reportingEntityAdmin = hasEntityRole(study.assignedToEntity, "ADMIN");
  let reportingEntityOperator = hasEntityRole(
    study.assignedToEntity,
    "OPERATOR"
  );
  let reportingRadiologist = hasEntityRole(
    study.assignedToEntity,
    "RADIOLOGIST"
  );

  let actions = [];
  /* console.log(
    "getStudyActions",
    study.entityId,
    superAdmin,
    admin,
    operator,
    radiologist
  ); */

  if (study.studyStatus !== 0 && study.studyStatus !== 2) {
    actions.push({
      name: "oviyam",
      title: "Oviyam",
      link: `${OVIYAM_BASE_URL}studyUID=${study.studyIUID}`,
    });
    actions.push({
      name: "ioviyam",
      title: "IOviyam",
      link: `${IOVIYAM_BASE_URL}patientID=&studyUID=${
        study.studyIUID
      }&patName=${sanitizePatientName(study.patName)}&patientGender=${
        study.patSex
      }&studyDesc=${study.studyDesc}&studyDate=${format(
        new Date(study.studyDateTime),
        "yyyy-MM-dd"
      )}`,
    });
    actions.push({
      name: "weasis",
      title: "Weasis",
      link: `${WEASIS_BASE_URL}studyUID=${study.studyIUID}`,
    });
    actions.push({
      name: "weasisold",
      title: "Weasis Old",
      link: `${WEASIS_OLD_BASE_URL}studyUID=${study.studyIUID}`,
    });
  }

  if (superAdmin) {
    if (study.studyStatus !== 0 && study.studyStatus !== 2) {
      actions.push({
        name: "share",
        title: "Whatsapp",
        link: `/share?patName=${sanitizePatientName(
          study.patName
        )}&studyUID=${study.studyIUID}`,
      });
    }

    if (study.studyStatus === 0) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
    } else if (study.studyStatus === 2) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
    } else if (study.studyStatus === 10) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
      actions.push({
        name: "misc",
        title: "Misc",
      });
    } else if (study.studyStatus === 20) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
        link: "assign",
      });
      actions.push({
        name: "acknowledge",
        title: "Acknowledge",
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "misc",
        title: "Misc",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
    } else if (study.studyStatus === 25) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
    } else if (study.studyStatus === 30) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "rework",
        title: "Rework",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
    }

    actions.push({
      name: "access",
      title: "Access",
    });
  } else if (admin || operator) {
    // console.log("getStudyActions", admin);

    if (study.studyStatus !== 0 && study.studyStatus !== 2) {
      actions.push({
        name: "share",
        title: "Whatsapp",
        link: `/share?patName=${sanitizePatientName(
          study.patName
        )}&studyUID=${study.studyIUID}`,
      });
    }

    if (study.studyStatus === 0) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
    } else if (study.studyStatus === 2) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
    } else if (study.studyStatus === 10) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
      actions.push({
        name: "misc",
        title: "Misc",
      });
    } else if (study.studyStatus === 20) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
        link: "assign",
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "misc",
        title: "Misc",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });

      if (reportingRadiologist) {
        actions.push({
          name: "report",
          title: "Report",
          link: `/study/report/${study.pk}`,
        });
      }
    } else if (study.studyStatus === 25) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
      if (reportingRadiologist) {
        actions.push({
          name: "report",
          title: "Report",
          link: `/study/report/${study.pk}`,
        });
      }
    } else if (study.studyStatus === 30) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "publish",
        title: "Publish",
      });
      actions.push({
        name: "rework",
        title: "Rework",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      if (reportingRadiologist) {
        actions.push({
          name: "report",
          title: "Report",
          link: `/study/report/${study.pk}`,
        });
      }
    }
  } else if (reportingEntityAdmin || reportingEntityOperator) {
    if (study.studyStatus === 0) {
    } else if (study.studyStatus === 10) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
    } else if (study.studyStatus === 20) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "assign",
        title: "Assign",
        link: "assign",
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "escalate",
        title: "Escalate",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
    } else if (study.studyStatus === 25) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "download",
        title: "Download",
      });
    } else if (study.studyStatus === 30) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "rework",
        title: "Rework",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
    }
  } else if (reportingRadiologist) {
    if (study.studyStatus === 0) {
    } else if (study.studyStatus === 10) {
    } else if (study.studyStatus === 20) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "acknowledge",
        title: "Acknowledge",
      });
    } else if (study.studyStatus === 25) {
      actions.push({
        name: "message",
        title: "Message",
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });
      actions.push({
        name: "download",
        title: "Download",
      });
    } else if (study.studyStatus === 30) {
      actions.push({
        name: "order",
        title: "Order",
        link: `/study/order/${study.pk}`,
      });
      actions.push({
        name: "report",
        title: "Report",
        link: `/study/report/${study.pk}`,
      });

      actions.push({
        name: "download",
        title: "Download",
      });
      actions.push({
        name: "rework",
        title: "Rework",
      });
      actions.push({
        name: "message",
        title: "Message",
      });
    }
  }
  return actions;
}

export function getStudyStatusText(studyStatus: number) {
  let studyStatusText;
  switch (studyStatus) {
    case 0: {
      studyStatusText = "Draft";
      break;
    }
    case 2: {
      studyStatusText = "Not Needed";
      break;
    }
    case 5: {
      studyStatusText = "Complementary";
      break;
    }
    case 10: {
      studyStatusText = "Ordered";
      break;
    }
    case 20: {
      studyStatusText = "Assigned";
      break;
    }
    case 25: {
      studyStatusText = "Rework";
      break;
    }
    case 30: {
      studyStatusText = "Reported";
      break;
    }
    default: {
      studyStatusText = "Unknown";
      break;
    }
  }
  return studyStatusText;
}

export function getStudyColor(studyStatus: number, orderStatus: string) {
  let color;

  if (studyStatus === 0) {
    color = "#acc2ba";
  }

  if (studyStatus === 2) {
    color = "#acc2ba";
  }

  if (studyStatus === 5) {
    color = "#6FB2D2";
  }

  if (studyStatus === 10) {
    color = "#FFBD35";
  }

  if (studyStatus === 20) {
    if (orderStatus && orderStatus.toUpperCase() === "ESCALATED") {
      color = "#FFBED8";
    } else {
      color = "#B4FE98";
    }
  }

  if (studyStatus === 25) {
    color = "#FF6B6B";
  }

  if (studyStatus === 30) {
    color = "#00ead3";
  }

  return color;
}

export function getModality(modsInStudy: string) {
  let modality;
  switch (modsInStudy) {
    case "CR": {
      modality = "CR";
      break;
    }
    case "DX": {
      modality = "CR";
      break;
    }
    case "CT": {
      modality = "CT";
      break;
    }
    case "MR": {
      modality = "MR";
      break;
    }
    case "CT\\MR": {
      modality = "MR";
      break;
    }
    case "MR\\SR": {
      modality = "MR";
      break;
    }
    default: {
      modality = "MR";
      break;
    }
  }
  return modality;
}

const modalityStudy: {
  [key: string]: { label: string; id: string }[];
} = {
  CT: [
    { label: "Brain (Contrast)", id: "1" },
    { label: "Brain (Plain)", id: "2" },
    { label: "Facial (Contrast)", id: "3" },
    { label: "Facial(Plain)", id: "4" },
    { label: "KUB ( Plain)", id: "5" },
    { label: "Pelvis (Plain)", id: "6" },
    { label: "PNS (Plain)", id: "7" },
    { label: "Temporal bone ", id: "8" },
    { label: "Extremities (MSK)- Joints", id: "9" },
    { label: "KUB (Contrast)/Urography", id: "10" },
    { label: "Neck (Contrast)", id: "11" },
    { label: "Neck (Plain)", id: "12" },
    { label: "Pelvis (Cystogram)", id: "13" },
    { label: "Peripheries (MSK) ", id: "14" },
    { label: "Spine - Cervical", id: "15" },
    { label: "Spine - Lumbar", id: "16" },
    { label: "Spine - Thoracic", id: "17" },
    { label: "Chest (Plain)", id: "18" },
    { label: "Abdomen & Pelvis (Plain)", id: "19" },
    { label: "Abdomen & Pelvis (Contrast)", id: "20" },
    { label: "Chest (Contrast)", id: "21" },
    { label: "Special studies", id: "22" },
    { label: "Spine - Whole", id: "23" },
    { label: "Aorta Renal Angiography ", id: "24" },
    { label: "Cerebral Angiography", id: "25" },
    { label: "Coronary Angiography", id: "26" },
    { label: "Peripheral Angiography", id: "27" },
    { label: "Pulmonary Angiography", id: "28" },
    { label: "Neck Angiography", id: "29" },
    { label: "Fistulogram", id: "30" },
    { label: "Aortagram", id: "31" },
    { label: "KUB Screening", id: "32" },
    { label: "Abdomen Oral+IV+Rectal", id: "33" },
    { label: "Abdomen Screening", id: "34" },
    { label: "Urogram", id: "35" },
    { label: "Pelvis Bony", id: "36" },
    { label: "Inner Ear", id: "37" },
    { label: "Lower Limb Angio", id: "38" },
    { label: "Upper Limb Angio", id: "39" },
    { label: "Abdomen Triple Phase", id: "40" },
    { label: "HRCT Chest", id: "41" },
    { label: "Knee Joint", id: "42" },
    { label: "Shouler Joint", id: "43" },
    { label: "Ankle Joint", id: "44" },
    { label: "Foot", id: "45" },
    { label: "Hip Joint", id: "46" },
    { label: "Screening - Two", id: "98" },
    { label: "Screening - One", id: "99" },
  ],
  CR: [
    { label: "One Region", id: "1" },
    { label: "Two Region", id: "2" },
    { label: "Three Region", id: "3" },
    { label: "Four Region", id: "4" },
    { label: "Five Plus Region", id: "5" },
    { label: "Skul AP/Lat", id: "6" },
    { label: "Nasal Bone AP/Lat", id: "7" },
    { label: "Temporal Joint Lat", id: "8" },
    { label: "Mastoid Lat", id: "9" },
    { label: "Adenoid Lat", id: "10" },
    { label: "Nasopharynx Lat", id: "11" },
    { label: "PNS", id: "12" },
    { label: "Neck AP/Lat", id: "13" },
    { label: "Clavicle AP", id: "14" },
    { label: "Chest PA", id: "15" },
    { label: "Chest AP", id: "16" },
    { label: "Cervicle Spine AP/Lat", id: "17" },
    { label: "Dorsal Spine AP/Lat", id: "18" },
    { label: "Lumbar Spine AP/Lat", id: "19" },
    { label: "Sacral AP/Lat", id: "20" },
    { label: "Coccyx Lat", id: "21" },
    { label: "Abdomen AP", id: "22" },
    { label: "Kub AP", id: "23" },
    { label: "Pelvis AP", id: "24" },
    { label: "Right Hip AP/Lat", id: "25" },
    { label: "Left Hip AP/Lat", id: "26" },
    { label: "Both Hip ", id: "27" },
    { label: "Right Hip AP", id: "28" },
    { label: "Left Hip AP", id: "29" },
    { label: "Right Shoulder AP", id: "30" },
    { label: "Left Shoulder AP", id: "31" },
    { label: "Right Shoulder Lat", id: "32" },
    { label: "Left Shoulder Lat", id: "33" },
    { label: "Left Humerus AP", id: "34" },
    { label: "Right Humerus AP", id: "35" },
    { label: "Left Humerus Lat", id: "36" },
    { label: "Right Humerus Lat", id: "37" },
    { label: "Right Elbow AP", id: "38" },
    { label: "Left Elbow AP", id: "39" },
    { label: "Right Elbow Lat", id: "40" },
    { label: "Left Elbow Lat", id: "41" },
    { label: "Right Wrist AP", id: "42" },
    { label: "Left Wrist AP", id: "43" },
    { label: "Right Hand AP", id: "44" },
    { label: "Right Femur AP", id: "45" },
    { label: "Right Femur Lat", id: "46" },
    { label: "Left Femur AP", id: "47" },
    { label: "Left Femur Lat", id: "48" },
    { label: "Left Knee AP", id: "49" },
    { label: "Left Knee Lat", id: "50" },
    { label: "Right Knee AP", id: "51" },
    { label: "Right Knee Lat", id: "52" },
    { label: "Right Leg AP", id: "53" },
    { label: "Right Leg Lat", id: "54" },
    { label: "Left Leg AP", id: "55" },
    { label: "Left Leg Lat", id: "56" },
    { label: "Left Ankle AP", id: "57" },
    { label: "Left Ankle Lat", id: "58" },
    { label: "Right Ankle AP", id: "59" },
    { label: "Right Ankle Lat", id: "60" },
    { label: "Left Foot AP", id: "61" },
    { label: "Left Foot Lat", id: "62" },
    { label: "Right Foot AP", id: "63" },
    { label: "Right Foot Lat", id: "64" },
    { label: "Left Heel Lat", id: "65" },
    { label: "Right Heel Lat", id: "66" },
    { label: "HSG", id: "67" },
    { label: "IVP", id: "68" },
    { label: "AUG", id: "69" },
    { label: "Barium Swallow", id: "70" },
    { label: "Barium Meal", id: "71" },
    { label: "MUC", id: "72" },
    { label: "Special Studies", id: "98" },
    { label: "Mamogram", id: "99" },
  ],
  MR: [
    { label: "Abdomen", id: "1" },
    { label: "Aortic Angiography", id: "2" },
    { label: "Brachial Plexus", id: "3" },
    { label: "Brain (Contrast)", id: "4" },
    { label: "Brain (Functional)", id: "5" },
    { label: "Brain (Plain/MRA/MRV)", id: "6" },
    { label: "Brain (Spectro)", id: "7" },
    { label: "Breast", id: "8" },
    { label: "Spine - Cervical ", id: "9" },
    { label: "Spine - Cervical With WS Screening", id: "10" },
    { label: "Cardiac MRI", id: "11" },
    { label: "Chest", id: "12" },
    { label: "Spine - Dorsal", id: "13" },
    { label: "Spine - Dorsal With WS Screening", id: "14" },
    { label: "Dynamic studies", id: "15" },
    { label: "Extremities (MSK)", id: "16" },
    { label: "Fetal MRI", id: "17" },
    { label: "Pelvis / HipJoint", id: "18" },
    { label: "KUB (Urogram)", id: "19" },
    { label: "Spine - Lumbar ", id: "20" },
    { label: "Spine - Lumbar With WS Screening", id: "21" },
    { label: "MRCP", id: "22" },
    { label: "Neck", id: "23" },
    { label: "Neck (Contrast)", id: "24" },
    { label: "Pelvis(Female)", id: "25" },
    { label: "Peripheral Angiography", id: "26" },
    { label: "Fistulogram", id: "27" },
    { label: "PNS", id: "28" },
    { label: "Prostate", id: "29" },
    { label: "Renal Angiography", id: "30" },
    { label: "Shoulder Joint", id: "31" },
    { label: "Special Studies", id: "32" },
    { label: "TM Joint", id: "33" },
    { label: "Spine - Whole", id: "34" },
    { label: "Neck Angiography", id: "35" },
    { label: "Fistulogram", id: "36" },
    { label: "Aortagram", id: "37" },
    { label: "Lumbar Spine", id: "38" },
    { label: "Dorsal Spine", id: "39" },
    { label: "Cervical Spine", id: "40" },
    { label: "Whole Spine", id: "41" },
    { label: "MR Angiography Brain", id: "42" },
    { label: "MR Angiography Peripheral", id: "43" },
    { label: "MR Venography Brain", id: "44" },
    { label: "MR Angiography Neck", id: "45" },
    { label: "MRI Upper Abdomen", id: "46" },
    { label: "MRI pelvis", id: "47" },
    { label: "MRI contrast", id: "48" },
    { label: "Screening - One", id: "98" },
    { label: "Screening - Two", id: "99" },
    { label: "Screening - Three", id: "100" },
  ],
};

export function getModalityStudies(modsInStudy: string) {
  let studies = modalityStudy[modsInStudy];
  return studies;
}

export function getStudyText(
  modsInStudy: string,
  studiesToTranslate: string[]
) {
  let modalityStudies = modalityStudy[modsInStudy];
  let studyText = "";

  for (const studyToTranslate of studiesToTranslate || []) {
    for (const modalityStudy of modalityStudies) {
      if (modalityStudy.id === studyToTranslate) {
        studyText = `${studyText}${modalityStudy.label} / `;
      }
    }
  }
  return studyText.slice(0, -3);
}

export const formImagePath = (fileName: string): string => {
  if (publicS3BaseURL) {
    return publicS3BaseURL + fileName;
  } else {
    return fileName;
  }
};

export function sanitizePatientName(patientName: string) {
  return patientName.replace(/[^a-zA-Z0-9]/g, "_");
}

export const escapeRegExp = (
  str: string // or better use 'escape-string-regexp' package
) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export function getReworkText(reason: string) {
  let reasonText = "";
  switch (reason) {
    case "MISSED_FINDING": {
      reasonText = "Missed Finding";
      break;
    }
    case "SIGNATURE_MISING": {
      reasonText = "Signature Missing";
      break;
    }
    case "WRONG_REPORT_UPLOADED": {
      reasonText = "Wrong Report Uploaded";
      break;
    }
    case "TYPED_REPORT_NEEDED": {
      reasonText = "Typed Report Needed";
      break;
    }
    case "OTHERS": {
      reasonText = "Others";
      break;
    }
    default: {
      reasonText = "";
      break;
    }
  }

  return reasonText;
}
