import axios, { AxiosResponse } from "axios";
import FileDownload from "js-file-download";

import history from "./history";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log("error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      signOut();
      console.error("error", error.response);
    }
    return Promise.reject(error);
  }
);

export interface IResponse<T = any> {
  message: string;
  type: string;
  hint?: string;
  values?: T;
}

/********* Auth *********/

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function setLocalStorage(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error("setLocalStorage Error", err);
  }
}

export function getLocalStorage(key: string) {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return "";
    }
  } catch (error) {
    console.error("getLocalStorage Error", key, error);
  }
}

function getAuthHeader() {
  return `Bearer ${getLocalStorage("authToken")}`;
}

export async function signOut() {
  removeLocalStorage("authToken");
  removeLocalStorage("roles");
  removeLocalStorage("subscriptionId");
  history.push("/");
}

async function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  if (process.env.NODE_ENV !== "development") {
    if ("serviceWorker" in navigator) {
      return navigator.serviceWorker.ready
        .then(function (serviceWorker) {
          return serviceWorker.pushManager.getSubscription();
        })
        .then(function (pushSubscription) {
          return pushSubscription;
        });
    }
  } else {
    return "";
  }
}

export async function authUser(payload: {
  userName: string;
  password: string;
}) {
  let subscription;

  try {
    subscription = await getUserSubscription();
  } catch (error) {
    console.error("auth subscription error =>", error);
  }

  return axios.post("/api/auth", {
    ...payload,
    subscriptionId: getLocalStorage("subscriptionId"),
    subscription: subscription,
  });
}

export async function userSignOut() {
  await axios.post(
    "/api/signout",
    {
      subscriptionId: getLocalStorage("subscriptionId"),
    },
    {
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
  removeLocalStorage("authToken");
  removeLocalStorage("roles");
  removeLocalStorage("subscriptionId");
  history.push("/");
}

export async function userSignOutAll() {
  await axios.post(
    "/api/signoutall",
    {},
    {
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
  removeLocalStorage("authToken");
  removeLocalStorage("roles");
  removeLocalStorage("subscriptionId");
  history.push("/");
}

export async function getStudy(study: any) {
  let response = await axios.get("/api/study", {
    params: {
      ...study,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function getRelatedStudies(studyId: string) {
  let response = await axios.get(`/api/study/${studyId}/relatedstudies`, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function getStudyByUID(studyUID: string) {
  let response = await axios.get(`/api/studybyuid/${studyUID}`, {});

  return response;
}

export async function getOrderByStudyUID(studyUID: string) {
  let response = await axios.get(`/api/studybyuid/${studyUID}/order`, {});

  return response;
}

export async function getRelatedStudiesByStudyUID(studyUID: string) {
  let response = await axios.get(
    `/api/studybyuid/${studyUID}/relatedstudies`,
    {}
  );

  return response;
}

export async function getReportsByStudyUID(studyUID: string) {
  let response = await axios.get(`/api/studybyuid/${studyUID}/reports`, {});

  return response;
}

/************* Files ************/

export async function getSignedUrl(
  file: any
): Promise<AxiosResponse<IResponse<{ url: string; fileName: string }>>> {
  let response = await axios.get("/api/file/signedUrl", {
    params: {
      fileName: file.fileName,
      fileFolder: file.fileFolder,
      newFileName: file.newFileName,
      publicOrPrivate: file.publicOrPrivate,
      fileType: file.fileType,
      type: file.type,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function putFileSignedUrl(
  url: string,
  file: File,
  contentType: string
): Promise<AxiosResponse<IResponse<{ fileName: string }>>> {
  let response = await axios.put(url, file, {
    headers: {
      "Content-Type": contentType,
    },
  });
  return response;
}

export async function postOrder(order: any) {
  return await axios.post("/api/order", order, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postAction(action: any) {
  return await axios.post("/api/action", action, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postMessage(message: any) {
  return await axios.post("/api/message", message, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function updateMessage(message: any) {
  return await axios.post("/api/message/update", message, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postReport(report: any) {
  return await axios.post("/api/report", report, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function deleteReport(report: any) {
  return await axios.delete("/api/report", {
    data: report,
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postTemplate(report: any) {
  return await axios.post("/api/template", report, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}
export function createAndDownloadBlobFile(
  body: any,
  filename: string,
  extension = "docx"
) {
  const blob = new Blob([body]);
  const fileName = `${filename}.${extension}`;
  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const saveDataToFile = (data: any, fileName: string, mimeType: string) => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  downloadURL(url, fileName);
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 1000);
};

const downloadURL = (data: any, fileName: string) => {
  const a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  // a.style = "display: none";
  a.click();
  a.remove();
};

export async function downloadTemplate(template: any) {
  let response = await axios
    .get("/api/template/gen", {
      params: {
        ...template,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      FileDownload(response.data, template.fileName);
      return {
        status: response.status,
      };
    })
    .catch((error) => {
      return {
        status: 500,
      };
    });
  return response;
}
/************* Entity ************/

export async function postEntity(entity: any) {
  return await axios.post("/api/entity", entity, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postEntityPayment(payment: any) {
  return await axios.post("/api/entity/payment", payment, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postEntityPricing(pricing: any) {
  return await axios.post("/api/entity/pricing", pricing, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postEntityAssignList(assignList: any) {
  return await axios.post("/api/entity/assignlist", assignList, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function getEntity(entity: any) {
  let response = await axios.get("/api/entity", {
    params: {
      ...entity,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function getUser(user: any) {
  let response = await axios.get("/api/user", {
    params: {
      ...user,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function postUser(user: any) {
  return await axios.post("/api/user", user, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postReferral(user: any) {
  return await axios.post("/api/referral", user, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function deleteReferral(report: any) {
  return await axios.delete("/api/referral", {
    data: report,
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function updateUser(user: any) {
  return await axios.post("/api/user/update", user, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postSubscription(subscription: any) {
  let response = await axios.post("/api/user/subscription", subscription, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });

  return response;
}

export async function deleteSubscription(subscription: any) {
  return await axios.delete("/api/user/subscription", {
    data: subscription,
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

/**************** Account ****************/

export async function getAcounts(account: any) {
  let response = await axios.get("/api/accounts", {
    params: {
      ...account,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export async function getTransactions(
  accountId: string,
  fromDate: string,
  toDate: string
) {
  let response = await axios.get(`/api/account/${accountId}/transactions`, {
    params: {
      fromDate: fromDate,
      toDate: toDate,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

/******************Availability*******************/

export async function getAvailability() {
  let response = await axios.get(`/api/availability`, {
    params: {},
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

/******************Modality Studies*******************/

export async function getModalityStudies(modality: string) {
  let response = await axios.get(`/api/modality/${modality}/studies`, {
    params: {},
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}
