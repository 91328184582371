import * as React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import CTextField from "../Components/CTextField";

import { updateUser, getStudy } from "../api";
import { UserContext } from "../ui/UserContext";
import signatureStyles from "./Signature.module.css";

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Signature() {
  const { dispatch } = React.useContext(UserContext);
  let navigate = useNavigate();
  const matches = useMediaQuery("(min-width:641px)");
  let [selectedFile, setSelectedFile] = React.useState<string>("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      signature: "",
      disclaimer: "",
      signatureImage: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      if (!data.signature && !data.disclaimer && !data.signatureImage) {
        dispatch({
          type: "SET-ERROR",
          value: "Nothing to Upload",
        });
      }
      let payload = {
        ...data,
        signatureImage: selectedFile,
        action: "SIGNATURE",
      };
      let response = await updateUser(payload);
      if (response.status === 200) {
        dispatch({
          type: "SET-SUCCESS",
          value: "Signature Updated Successfully",
        });
        navigate("/dashboard");
      } else {
        dispatch({ type: "SET-ERROR", value: response.data.message });
      }
    } catch (error: any) {
      dispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Failed to Update Signature",
      });
    }
  };

  React.useEffect(() => {
    const getData = async () => {
      let signature = { type: "SIGNATURE" };
      let response = await getStudy(signature);
      if (response.status === 200) {
        let value = response.data.values;
        if (value) {
          reset(value);
          setSelectedFile(value.signatureImage);
        }
      }
    };
    getData();
  }, [reset]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={signatureStyles.availability}
      noValidate
    >
      <Box
        sx={
          matches
            ? {
                marginTop: 1,
                marginBottom: 1,
                display: "flex",
                "& .MuiFormControl-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "100%",
                },
                "& .MuiAutocomplete-root": {
                  marginRight: 2,
                  marginTop: 0,
                  width: "100%",
                },
                "& .MuiAutocomplete-root .MuiFormControl-root": {
                  marginRight: 0,
                  marginTop: 0,
                  width: "100%",
                },
              }
            : {
                "& .MuiFormControl-root": { width: "100%", marginTop: 2 },
              }
        }
      >
        <CTextField
          name="signature"
          label="Signature"
          control={control}
          multiline
          rows={4}
        />
        <CTextField
          name="disclaimer"
          label="Disclaimer"
          control={control}
          multiline
          rows={4}
        />
      </Box>

      <div className="form-field-block">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          {selectedFile ? (
            <img
              style={{ width: "150px", height: "100px", border: "1px dotted" }}
              alt="Signature"
              src={selectedFile}
            />
          ) : (
            <div
              style={{
                width: "150px",
                height: "100px",
                border: "1px dotted",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Signature
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label htmlFor={"signatureFile"}>
              <p
                style={{
                  fontSize: "0.85rem",
                }}
              >
                {"Click Here to Select File"}
              </p>
            </label>
            <input
              id={"signatureFile"}
              type="file"
              name="file"
              accept={"image/x-png,image/gif,image/jpeg"}
              onChange={async (event) => {
                if (
                  event &&
                  event.target &&
                  event.target.files &&
                  event.target.files.length > 0
                ) {
                  let file = event.target.files[0];

                  if (file.size <= 5000000) {
                    let fileBase64 = await toBase64(event.target.files[0]);
                    setSelectedFile(fileBase64 as string);
                  } else {
                    dispatch({
                      type: "SET-ERROR",
                      message: "File Size Cannot be More than 5 MB",
                    });
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <Box
        sx={{
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <Button
          color="primary"
          sx={{ minWidth: "100px" }}
          variant="contained"
          type="submit"
        >
          Update Signature
        </Button>
      </Box>
    </form>
  );
}

export default Signature;
