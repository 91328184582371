import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import history from "./history";
import type {} from "@mui/lab/themeAugmentation";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  /*   components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
  }, */
  typography: {
    h3: {
      fontFamily: "Open Sans Condensed",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Open Sans Condensed",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Open Sans Condensed",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Open Sans Condensed",
      fontWeight: "bold",
    },
    /*     fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","), */
    button: {
      textTransform: "none",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
