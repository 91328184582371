import * as React from "react";
import { Controller, Control, ErrorOption } from "react-hook-form";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

interface ICDateTimeField {
  name: string;
  control: Control<any>;
  label: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: ErrorOption;
}

function CDateTimeField({
  name,
  autoFocus,
  label,
  required,
  disabled,
  control,
  error,
}: ICDateTimeField) {
  return (
    <Controller
      name={name}
      rules={{ required: true }}
      control={control}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...field}
            label={label}
            disabled={disabled}
            mask="____-__-__ __:__"
            inputFormat="yyyy-MM-dd HH:mm"
            renderInput={(params) => (
              <TextField
                margin="dense"
                {...params}
                required={required}
                disabled={disabled}
                sx={{ width: "100%" }}
                error={
                  fieldState.error
                    ? fieldState.error.type
                      ? true
                      : false
                    : false
                }
                helperText={
                  fieldState.error
                    ? fieldState.error.type === "required"
                      ? ""
                      : "Invalid"
                    : ""
                }
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default CDateTimeField;
